<template>
  <!--  课程推荐-->
  <div class="recommend" v-if="newsList && newsList.length > 0">
    <!--标题  新闻资讯  -->
    <div class="home-three-title">
      <div class="text">
        <!-- <img class="item-img" src="@/assets/img/homeThree/live.png" /> -->
        <slot>资讯</slot>
      </div>
      <span class="more" @click="allnews">查看更多&nbsp;></span>
    </div>
    <!--新闻资讯  内容  -->
    <div class="Journalism">
      <!-- 模块 1 -->
      <div class="Journalisms" v-for="(item, index) in newsList" :key="index" @click="allnewxq(item)">
        <!-- 图片 -->
        <img class="JournalismsImg" :src="item.img" />
        <div class="Journalismsa">
          <div class="title elp" v-html="item.title"></div>
          <div class="Journalismsb twoEllipsis" v-html="ImgSizeLimit(item.introduction)"></div>
          <div class="time">{{ item.addTime | format("yyyy-MM-dd") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/common.css";
import { queryAllArticle } from "@/api/home";
export default {
  components: {},
  data() {
    return {
      newsList: "", //新闻
    };
  },
  created() {
    this.loadqueryAllArticle(); // 调用新闻资讯接口方法
  },
  methods: {
    // 调用新闻资讯接口方法
    loadqueryAllArticle() {
      queryAllArticle("", 6, 1).then((res) => {
        this.newsList = res.rows;
      });
    },
    // 查看更多新闻资讯按钮
    allnews() {
      /* this.$router.push({ path: "/allnews" }); */
      let routeUrl = this.$router.resolve({
        path: "/allnews",
      });
      window.open(routeUrl.href, "_blank");
    },
    // 查看单个新闻资讯详情按钮
    allnewxq(index) {
      /* this.$router.push({ name: "新闻资讯详情", params: { id: index.id } }); */
      let routeUrl = this.$router.resolve({
        name: "新闻资讯详情",
        params: { id: index.id },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
// 课程推荐
.recommend {
  width: 1220px;
  margin: 30px auto;
  // height: 1450px;
  height: auto;
  overflow: hidden;

  // 新闻资讯  内容
  .Journalism {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // 模块
    .Journalisms {
      width: calc(50% - 12px);
      margin-bottom: 24px;
      cursor: pointer;
      height: 160px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      display: flex;
      padding: 20px;
      // 图片
      .JournalismsImg {
        width: 180px;
        height: 120px;
        background: #c4c4c4;
        border-radius: 6px 6px 6px 6px;
        flex-shrink: 0;
        margin-right: 12px;
      }
      .Journalismsa {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        .title {
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-top: 4px;
          line-height: 20px;
          width: 100%;
          height: 20px;
        }
        .Journalismsb {
          height: 44px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          margin-top: 12px;
        }
        .time {
          font-size: 14px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          position: absolute;
          bottom: 4px;
        }
      }
    }
  }
}
</style>
