<template>
  <div class="bannerImgBox">
    <div class="bannerImg">
      <div class="content">
        <!-- 轮播 -->
        <el-carousel ref="carousel" class="swImg" indicator-position="none">
          <el-carousel-item v-for="(item, index) in imgList" :key="index" class="pointer" :autoplay="false">
            <el-image ref="bannerimg" :src="item.img" @click="nav(item)" />
          </el-carousel-item>
        </el-carousel>

      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/common.css";
import {
  getSurfaceList,
  getMYTenantKindZyList,
  selectKindTenantIdProducts, getMYTenantKindZyListnew,
  selectNewPageListToIndex, selectPageListToIndexnew
} from "@/api/home";
import { queryAllArticle } from "@/api/home";
import { getDomain } from "@/api/cookies";
import { Know } from "@/api/know";
const know = new Know();
export default {
  data() {
    return {
      imgList: [],
      newImgList: [],
      kindIndex: null,
      collageList: [],
      newsList: [],
      domain: null,
      active: null,
      search: {},
      index: null,
      gradient: null,
      stageList: [],
      pStageList: [],
      courseClass: [],
      RecommendClassList: [],
    };
  },
  created() {
    this.domain = getDomain();
    /* 获取轮播图 */
    getSurfaceList(1, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = res.msg;
      }
    });

  },
  methods: {

    nav(item) {
      window.open(item.skipImg, "_blank");
    },

  },
};
</script>
<style lang="less" scoped>
.bannerImgBox {
  position: relative;
  width: 100%;
  margin: 0 auto;


  .bannerImg {
    position: absolute;
    z-index: 2;
    align-self: center;
    height: 310px;
    position: relative;
    width: 100%;
    margin: 20px auto 0;
    display: flex;
    // background-color: #333333;
    justify-content: space-between;
  }
}

/* ===========项目专业============= */
// 缩宽
.wrapBottom {
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-left: 217px;
  // backdrop-filter: blur(20px);
  width: 658px;
  z-index: 99;
  padding: 0px 0px 30px 30px;
  overflow-y: scroll;
  scrollbar-width: none;
  // box-shadow: 0px 2px 4px 2px rgba(153, 153, 153, 0.20000000298023224);
  border-radius: 0px 8px 8px 0px;
}

.wrap {
  cursor: pointer;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin-left: 172px;
  width: 640px;
  height: 310px;
  background: #ffffff;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  z-index: 99999;
  padding: 0px 0px 30px 30px;
  overflow-y: scroll;
  scrollbar-width: none;

  // box-shadow: 0px 2px 4px 2px rgba(153, 153, 153, 0.20000000298023224);
  .colleges_title {
    .text {
      width: 64px;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    margin-top: 20px;
    margin-bottom: 12px;
  }

  .contentWrapLeft {
    width: 100%;
    display: flex;
    justify-content: start;

    .contentItem {
      width: 25%;

      .imgClass {
        width: 130px;
        height: 74px;
        border-radius: 4px;
      }

      &:hover {
        color: #3d6cc8;
      }

      .item_content {
        width: 125px;
        margin-top: 6px;
        height: 35px;
        font-size: 12px;
        line-height: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
      }
    }
  }

  .title {
    font-size: 15px;
    font-family: Microsoft YaHei-Bold;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    padding-top: 17px;
  }

  .wraps {
    width: 100%;
    height: auto;
    display: flex;
    font-size: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .wrapss {
      // width: calc(100% / 5);
      width: 120px;
      margin-top: 18px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      text-align: left;

      &:hover {
        color: #3d6cc8;
        // border-bottom: 1px solid #ff5d51;
      }
    }
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

/* ========================== */
.content {
  width: 100%;
  border-radius: 10px !important;

  .swipersLefts {
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    flex-shrink: 0;
    margin-right: 16px;
    width: 172px;
    height: 310px;

    border-radius: 10px 0px 0px 0px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 3px 6px 1px rgba(204, 204, 204, 0.16);
    // border-radius: 10px 0px 0px 0px;
    opacity: 1;

    .list {
      width: 100% !important;
      height: 310px;

      // background-color: rgba(116, 116, 116, 0.4);
      // backdrop-filter: blur(20px);
      // box-shadow: 0 0 5px 0px rgba(39, 39, 39, 0.4);
      // height: 270px;
      // display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      // background: black;
      overflow-y: scroll;
      border-radius: 10px 0px 0 10px;

      // 自学考试
      .item {
        width: 100%;
        height: 51.6px;
        padding: 0 15px 0 20px;
        border-radius: 0px 0px 0px 0px;
        display: flex;
        align-items: center;
        opacity: 1;
        display: flex;
        justify-content: space-between;

        &:hover {
          .item-title {
            color: #3d6cc8 !important;
          }
        }

        // margin: 4px 0;
        .item-img {
          flex-shrink: 0;
          margin-right: 4px;
          width: 16px;
          height: 16px;
        }

        .item-title {
          width: 100%;
          height: 16px;
          font-size: 15px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 16px;

          &:hover {
            color: #3d6cc8;
          }
        }

        // 内容
        .item-content {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
          scrollbar-width: none;
          font-size: 12px;
          font-family: Source Han Sans CN-Light, Source Han Sans CN;
          font-weight: 300;
          color: #666666;
          line-height: 16px;

          .pname {
            &:hover {
              color: #3d6cc8;
            }
          }

          .item-content-name {
            // min-width: calc(40% - 8px);
            max-width: calc(50% - 8px);
            margin-right: 5px;
            flex-shrink: 0;
            white-space: nowrap; //强制不换行
            opacity: 0.7;

            &:last-child {
              margin-right: 0px !important;
            }
          }
        }
      }

      // .on {
      //   border-left: 4px solid #ff5e51;
      //   // background: rgba(255, 255, 255, 0.2);
      // }
    }
  }

  // tab按钮
  .distrbute_btns {
    position: absolute;
    display: flex;
    z-index: 1000;
    height: 168px;
    width: 172px;
    background: black;
    top: 46px;
    border-radius: 8px;
    padding: 18px 8px;
    background-color: rgba(78, 78, 78, 0.4);
    right: 39px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .tabBanner {
      font-size: 14px;
      width: 140px !important;
      height: 30px;
      display: flex;
      align-items: center;
      line-height: 100%;
      color: #cccccc;
      background: none;
      border: none;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    }

    .active {
      color: #ffffff;
      opacity: 1 !important;
    }
  }

  /* 轮播图 */
  /deep/.swImg {
    width: 100%;
    height: 400px;

    opacity: 1;

    img {
      // max-width: 100%;
      width: 100%;
      margin: auto;
    }

    .el-carousel__item {
      width: 100%;
      height: 100%;
    }

    .el-carousel__container {
      height: 100%;
    }

    .el-carousel__arrow {
      width: 32px;
      height: 32px;
      background: rgba(0, 0, 0, 0.2);
    }

    i {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  /deep/.el-carousel__arrow--left {
    position: absolute;
    left: 26px;
  }

  /deep/.el-carousel__arrow--right {
    position: absolute;
    right: 26px;
  }

  .menu-news {
    display: flex;
    margin-top: 17px;
    /* 新闻咨询 */

    .news {
      width: 811px;
      height: 227px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 24px;
      background: url("~@/assets/img/homeSeventh/newBg.png") no-repeat;
      background-size: 100% 100%;

      .news-content {
        display: flex;
        margin-top: 18px;

        .news-img {
          width: 248px;
          height: 143px;
          margin-right: 22px;
          cursor: pointer;

          &:nth-child(1) {
            margin-right: 24px;
          }
        }

        .news-text {
          padding: 14px 0px 0px;

          .news-item {
            margin-bottom: 20px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d636d;
            line-height: 14px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0px;
            }

            .icon {
              width: 6px;
              height: 6px;
              background: #dddddd;
              opacity: 1;
              border-radius: 50%;
              flex-shrink: 0;
              margin-right: 8px;
            }

            .title {
              width: 207px;
            }
          }
        }
      }
    }

    /* 金刚区 */
    .menuList {
      width: 257px;
      height: 227px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;

      margin-left: 16px;

      .menu-box {
        border-bottom: 1px solid #edeff4;
        width: 100%;
        height: 50%;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;

        .menu-item {
          width: 50%;
          border-bottom: 1px solid #fff;
          height: 100%;
          padding-top: 28px;

          .menu-img {
            width: 32px;
            height: 32px;
            margin: 0px auto;
          }

          .text {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            text-align: center;
            margin-top: 12px;
          }

          &:nth-child(1) {
            border-right: 1px solid #edeff4;
          }
        }
      }
    }
  }
}
</style>