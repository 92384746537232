<template>
  <div class="navs-box">
    <div class="navs">
      <div v-for="(item, index) in list" :key="index" @click="gopage(item.url)" class="item">
        <img class="item-img" :src="item.image" />
        <div class="item-title">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [
        {
          text: "直播课",
          image: require("@/assets/img/Home/menu3.png"),
          url: "/zhiboclass",
        },
        {
          text: "知识套餐",
          image: require("@/assets/img/Home/menu1.png"),
          url: "/allcurriculum/typeclass",
        },
        {
          text: "精品课程",
          image: require("@/assets/img/Home/menu6.png"),
          url: "/jingpin/courer",
        },
        {
          text: "优惠券",
          image: require("@/assets/img/Home/menu2.png"),
          url: "/my/mineCoupon",
        },
        {
          text: "学习资料",
          image: require("@/assets/img/Home/menu5.png"),
          url: "/data/dataPackage",
        },
        {
          text: "题库",
          image: require("@/assets/img/Home/menu4.png"),
          url: "/quesTion/quesTionList",
        },
      ],
    };
  },
  created() {
    this.userInfo = getInfo();
  },
  methods: {
    gopage(path) {      let userInfo = getInfo();
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false);
        return;
      }
      /* this.$router.push(path); */
      window.open(path, '_blank')
    },
  },
};
</script>
<style lang="less" scoped>
.navs-box {
  background: #fff;
  width: 100%;
  padding: 20px 0px;
}
// 五图片
.navs {
  // width: 65%;
  width: 1220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto;
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    .item-img {
      width: 44px;
      height: 44px;
      margin-right: 16px;
    }
    .item-title {
      font-size: 20px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
}
</style>
