<template>
  <div class="two-box">
    <div v-if="teacherList && teacherList.length > 0" class="teacher-view">
      <!--标题 师资力量 -->
      <div class="til">
        <div>师资力量</div>
        <div class="tips-line" />
        <div>优质的讲师团队保证高质量的课程</div>
      </div>

      <div class="terbox">
        <div class="gradient" />
        <el-image class="teapics" :src="require('@/assets/img/homeSeventh/teacherBackgorund.png')" fit="cover" />
        <div class="terf">
          <div />
          <el-image class="xiangkuang" :src="require('@/assets/img/homeSeventh/xiangkuang.png')" />
          <transition name="teacher">
            <el-image v-if="teachIndex" class="terImg" :src="teamsg.terImg" fit="cover" />
          </transition>
        </div>
        <div class="reamsg">
          <div>{{ teamsg.isRecommend==1?teamsg.userName: teamsg.nickname }}</div>
          <div>{{ teamsg.kindName }}<span style="margin-left:15px">{{ teamsg.kinds }}</span> </div>
          <div />
          <div v-html="teamsg.profile" />
          <div class="look" @click="goDetails()">
            查看详情
            <i style="font-size: 12px" class="iconfont el-icon-arrow-right" />
          </div>
        </div>
        <div class="teapic">
          <div v-for="(item, index) in teacherList" :key="index" :class="item.isClick ? 'bor' : ''"
            @mouseenter="goDet(item)">
            <img :src="item.terImg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { TeacherClient } from '@/api/teacherClient'
const teacherClient = new TeacherClient()
export default {
  components: {},
  data () {
    return {
      total: '',
      teacherList: [],
      pageNum: 1,
      teamsg: {},
      uid: '',
      teachIndex: true
    }
  },
  async created () {
    // 教师
    await teacherClient
      .teacherPageList(12, 1, undefined, 1)
      .then(async (res) => {
        if (res.rows) {
          for (const items of res.rows) {
            items.isClick = false
          }
          const params = {
            lectureId: res.rows[0].id
          }
          const ress = await teacherClient.getPcById(params)
          res.rows[0].isClick = true
          this.teamsg = res.rows[0]
          this.teacherList = res.rows
          res.rows[0].kindName = ress.data?.kindName[0]
          res.rows[0].kinds = ress.data?.kindName[1]
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    // this.say();
  },
  methods: {
    gos () {
      this.$router.push(`/facultysixth`)
    },
    async getlist () {
      await teacherClient
        .teacherPageList(12, 1, undefined, 1)
        .then(async (res) => {
          if (res.rows) {
            for (const items of res.rows) {
              const params = {
                lectureId: items.id
              }
              const res = await teacherClient.getPcById(params)
              items.kindName = res.data?.kindName[0]
              items.kinds = res.data?.kindName[1]
              if (items.id == this.uid) {
                items.isClick = true
              } else {
                items.isClick = false
              }
              this.$forceUpdate
            }
            this.teacherList = res.rows
            this.total = res.total
          } else {
            this.teacherList = []
          }
        })
    },
    goDet (item, index) {
      this.teachIndex = false
      this.teamsg = item
      this.uid = item.id
      this.getlist()
      const timer = setTimeout(() => {
        this.teachIndex = true
        clearTimeout(timer)
      }, 200)
    },
    goDetails () {
      const routeUrl = this.$router.resolve({
        path: `/faculty/teacherDetail`,
        query: {
          id: this.teamsg.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    tonext (page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page, undefined, 1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    },
    toprev (page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page, undefined, 1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    }
    // say() {
    //   this.$nextTick(() => {
    //     var last = document.getElementById(
    //       `viesww${this.teacherList.length - 1}`
    //     );
    //     if (last) {
    //       let star = last.style.transform.indexOf("translateX(");
    //       let end = last.style.transform.indexOf("px)");
    //       let val = last.style.transform.substring(
    //         star + "translateX(".length,
    //         end
    //       );
    //       if (Number(val) < 1200 * 3) {
    //         this.$refs.nop.prev();
    //       }
    //       for (let i in this.teacherList) {
    //         var box = document.getElementById(`viesww${i}`);
    //         let starIndex = box.style.transform.indexOf("translateX(");
    //         let endIndex = box.style.transform.indexOf("px)");
    //         let nuw = box.style.transform.substring(
    //           starIndex + "translateX(".length,
    //           endIndex
    //         );
    //         let data = nuw ? Number(nuw) / 4 : null;
    //         if (data) {
    //           box.style.transform = `translateX(${data}px) scale(1) `;
    //           box.classList.add("is-animating");
    //         }
    //       }
    //     }
    //   });
    // },
  }
}
</script>
<style>
.el-popover {
  overflow: hidden;
  padding: 0px;
}
@keyframes slidein {
  from {
    /* transform: translateX(-100%); */
    opacity: 0;
  }
  to {
    /* transform: translateX(0px); */
    opacity: 1;
  }
}
.teacher-enter-active {
  animation: slidein 600ms;
}

.teacher-leave-active {
  animation: slidein 100ms reverse;
}
</style>
<style lang="less" scoped>
.two-box {
  width: 100%;
  background: #f5f5f5;
}
.active {
  animation: slidein 1s;
}
.teacher-view {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f5f5;
  .til {
    position: relative;
    width: 100%;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .tips-line {
      position: absolute;
      right: 216px;
      bottom: 11px;
      width: 32px;
      height: 1px;
      background: #00ab8c;
      margin-right: 8px;
    }
    > div:nth-child(3) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #aaaaaa;
    }
  }
  .terbox {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    .gradient {
      position: absolute;
      // background-color: #fff;
      width: 100%;
      height: 100%;
      // background:linear-gradient(to right top , rgba(253, 253, 253, 0.1),rgba(192, 253, 234, 0.1),rgba(48, 233, 128, 0.1) ),
      background: linear-gradient(89deg, #ffffff 0%, #f2fffa 100%);
    }
    .teapics {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    width: 1200px;
    height: 378px;
    //  background: linear-gradient(to top right, #FFFFFF 0%, #dafff1 100%);
    background-size: cover;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-top: 22px;
    display: flex;
    justify-content: start;
    .terf {
      position: relative;
      width: 229px;
      z-index: 3;
      height: 321px;
      border-radius: 8px;
      background-color: #44465b;
      border: 4px solid #00ab8c;
      // background: url('~@/assets/img/homesixth/Group 4041.png');
      opacity: 1;
      overflow: hidden;
      margin-top: 34px;
      margin-left: 44px;
      .xiangkuang {
        z-index: 4;
      }
      div {
        position: absolute;
        width: 229px;
        height: 321px;
        // background: url('~@/assets/img/homesixth/Group 4041.png');
        // top: -2px;
        // left: -3px;
      }
      .terImg {
        width: 229px;
        height: 321px;
        z-index: 3;
        // border-radius: 8px;
      }
    }
    .reamsg {
      z-index: 2;
      div {
        margin-left: 44px;
      }
      > div:nth-child(1) {
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #555555;
        margin-top: 55px;
      }
      > div:nth-child(2) {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        margin-top: 11px;
        color: #555555;
        line-height: 24px;
        margin-bottom: 30px;
      }
      > div:nth-child(3) {
        width: 32px;
        height: 4px;
        background: #00ab8c;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      > div:nth-child(4) {
        width: 396px;
        height: 130px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #555555;
        line-height: 26px;
        overflow: hidden;
        margin-top: 10px;
      }
      > div:nth-child(5) {
        position: absolute;
        z-index: 66;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #00ab8c;
        margin-top: 8px;
        cursor: pointer;
      }
    }
    .teapic {
      z-index: 2;
      width: 400px;
      // height: 280px;
      margin-top: 60px;
      height: fit-content;
      margin-left: 86px;
      display: flex;
      justify-content: flex-start;
      align-items: start;
      flex-wrap: wrap;
      position: relative;
      .modals {
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #ffffff;
        opacity: 0.8;
      }
      div {
        box-sizing: border-box;
        border: 2px solid #fff;
        position: relative;
        width: 80px;
        border-radius: 8px 8px 8px 8px;
        height: 80px;
        margin-bottom: 11px;
        margin-right: 10px;
        cursor: pointer;
        overflow: hidden;
        &:hover {
          border: 2px solid #01d6b0;
        }
        img {
          width: 78px;
          // height: 76px;
          // border-radius: 8px 8px 8px 8px;
        }
      }
      .bor {
        border: 2px solid #00ab8c;
      }
    }
  }
}
</style>
