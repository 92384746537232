<template>
  <div class="navs">
    <div v-for="(item, index) in list" :key="index" class="item" @click="gopage(item.url)">
      <!-- <img class="bg-img" src="@/assets/img/homeFourth/bgImg.png" /> -->
      <img class="item-img" :src="item.image" />
      <div class="item-content">
        <div class="item-title">{{ item.text }}</div>
        <div class="item-more">
          {{ item.content }}
        </div>
      </div>
      <div v-show="index < list.length - 1" class="item-line" />
    </div>
  </div>
</template>

<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [
        {
          text: '精品课程',
          image: require('@/assets/img/partner/menu1.png'),
          url: '/partnerPage/courer',
          content: '优质的辅导课程'
        },
        {
          text: '辅导班型',
          image: require('@/assets/img/partner/menu2.png'),
          url: '/partnerPage/VIPClass',
          content: '五大辅导类型课'
        },
        {
          text: '学习资料',
          image: require('@/assets/img/partner/menu3.png'),
          url: '/partnerPage/dataPackage',
          content: '各讲师精心编制'
        },
        {
          text: '试卷题库',
          image: require('@/assets/img/partner/menu4.png'),
          url: '/partnerPage/quesTionList',
          content: '历年考试真题考题'
        }
        // {
        //   text: "直播课",
        //   image: require("@/assets/img/partner/menu5.png"),
        //   url: "/partnerPage/zhiboclass",
        //   content: "自主研发专属教材",
        // },
      ]
    }
  },
  created() {
    this.userInfo = getInfo()
  },
  methods: {
    gopage(path) {
      const userInfo = getInfo()
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false)
        return
      }
      /* this.$router.push(path); */
      window.open(path, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
// 五图片
.navs {
  // width: 65%;
  position: absolute;
  transform: translatex(-50%);
  left: 50%;
  width: 1200px;
  z-index: 3;
  display: flex;
  border-radius: 0 0 8px 8px;
  background-color: #fff;
  justify-content: space-between;
  padding: 0 1px 0 9px;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto 24px;

  .item {
    width: 24%;
    height: 90px;
    margin-left: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.10000000149011612);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    background: #fff;

    .bg-img {
      position: absolute;
      left: 0px;
      width: 100%;
    }

    .item-content {
      margin-left: 12px;

      // margin-top:8px;
      // width: 100%;
      .item-title {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }

      .item-more {
        font-size: 15px;
        // font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        margin-top: 4px;
      }
    }

    .item-img {
      // margin-top: 10px;
      margin-left: 7px;
      width: 66.5px;
      // height: 58.9px;
      flex-shrink: 0;
    }

    .item-line {
      position: absolute;
      right: 14%;
      width: 0px;
      height: 43px;
      opacity: 1;
      border: 1px solid #f2f2f2;
    }
  }

  .item:nth-child(1) {
    .item-img {
      margin-left: 20px;
    }

  }
}
</style>
