<template>
  <div class="tuijian">
    <div class="home-three-title">
      <div class="text fourth-text">
        <img class="fourth-img" src="@/assets/img/homeFourth/class.png" />
        <div class="text-slot">
          <slot>热门知识套餐</slot>
        </div>
      </div>
      <!-- <span class="more" @click="goNext(`/allcurriculum/typeclass`)">查看更多&nbsp;></span> -->
    </div>
    <!--  课程推荐-->
    <div v-loading="nav.loading" :element-loading-text="'加载中'" ref="recommendBox" class="recommend"
      v-for="(nav, index) in collegeList" :key="index">
      <!--标题 课程推荐 -->
      <div class="title" :class="index == 0 ? 'm-t-0' : ''">
        <p class="schoole">{{ nav.pName }}</p>
        <div class="kind-list">
          <div class="kind-item" v-for="(nav2, index2) in nav.kindList" :key="index2"
            @click="handleNav(nav, nav2, index)" :class="nav.kind == nav2.pKind ? 'kind-on' : ''" v-if="index2 < 5">
            {{ nav2.pName }}
          </div>
        </div>
        <span class="more" @click="banxingkes(nav.pKind, nav.kind)">查看更多&nbsp;></span>
      </div>
      <!-- 课程推荐  内容 -->
      <div class="class-list">
        <div class="item" v-for="(item, index) in nav.projectList" :key="index" @click="banxingke(item)">
          <!-- 图片 -->
          <img class="item-img" :src="item.img" alt="" />
          <!-- 内容 -->
          <div class="content">
            <div class="item-title twoEllipsis">{{ item.tiitle }}</div>
            <div class="item-tips">
              <div class="contetn" v-for="(curForms, curindex) in item.curForms" :key="curindex">
                <span class="data-icon" v-if="curindex != 0"> / </span>
                {{ curForms }}
              </div>
            </div>
            <div class="item-price">
              <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
              <div class="price-tips">人气值{{ item.clicks ? item.clicks : 0 }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { selectPageListToIndex, selectPageListToIndexnew, selectAcademyKindzyList, selectAcademyKindzyListnew, queryAllArticle } from '@/api/home'
import { selectCoursePages } from '@/api/shaixuan'
export default {
  components: {},
  data() {
    return {
      kindList: [],
      tuijianList: '', //课程推荐
      collegeList: [],
    }
  },
  created() {
    this.loadselectCurriculumPages() // 调用课程推荐接口方法
    /* this.selectAcademyKindzyList(); // 调用所有学院分类 */
    selectAcademyKindzyListnew().then((res) => {
      this.collegeList = res.data
      this.collegeList.forEach((item) => {
        this.$set(item, 'loading', false)
        item.kind = item.kindList[0].pKind
      })
    })
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollToTop)
  },

  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll', this.scrollToTop)
    next()
  },
  methods: {
    async handleNav(item, nav2, index) {
      this.collegeList[index].loading = true
      item.kind = nav2.pKind
      await selectPageListToIndexnew(8, 1, '', nav2.pKind, '', '', 4, '').then((res) => {
        item.projectList = res.rows
        for (let cur of item.projectList) {
          if (cur.curForms) {
            cur.curForms = cur.curForms.split(',')
          } else {
            cur.curForms = []
          }
        }
      })
      this.collegeList[index].loading = false
      this.$forceUpdate()
    },
    // 调用课程推荐接口方法
    loadselectCurriculumPages() {
      selectPageListToIndexnew(10, 1).then((res) => {
        this.tuijianList = res.rows
      })
    },
    // 调用所有学院分类
    async selectAcademyKindzyList(item, index) {
      await selectPageListToIndexnew(8, 1, '', item.kind, '', '', 4, '').then((res) => {
        item.projectList = res.rows
        for (let cur of item.projectList) {
          if (cur.curForms) {
            cur.curForms = cur.curForms.split(',')
          } else {
            cur.curForms = []
          }
        }
      })
      this.collegeList[index].loading = false
      this.$forceUpdate()
    },
    viewInfomation() {
      this.$router.push(`/allnews`)
    },

    // 查看更多知识套餐课按钮
    banxingkes(schoole, kind) {
      // this.$router.push(`/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`);
      let routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`,
      })
      window.open(routeUrl.href, '_blank')
    },
    toInformationDetails(item) {
      // this.$router.push({ name: "新闻资讯详情", params: { id: item.id } });
      let routeUrl = this.$router.resolve({
        name: '新闻资讯详情',
        params: { id: item.id },
      })
      window.open(routeUrl.href, '_blank')
    },
    // 知识套餐课详情
    banxingke(item) {
      /* this.$router.push({
        path: "/typeclassxq",
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      }); */
      let routeUrl = this.$router.resolve({
        path: '/typeclassxq',
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.price,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
    scrollToTop() {
      this.isElementNotInViewport(this.$refs.recommendBox)
    },

    isElementNotInViewport(el) {
      if (el) {
        let arr = []
        el.forEach((item, index) => {
          let rect = el[index].getBoundingClientRect()
          if (
            !(rect.top >= (window.innerHeight || document.documentElement.clientHeight) || rect.bottom <= 0)
          ) {
            if (!this.collegeList[index].isShow) {
              this.collegeList[index].loading = true
              this.selectAcademyKindzyList(this.collegeList[index], index)
              this.collegeList[index].isShow = true
            }
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.home-three-title {
  width: 1220px;
  padding-bottom: 30px;
  margin: auto !important;
}

// 标题 最新公开课
.title {
  width: 1220px;
  margin: 20px auto 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .schoole {
    font-size: 20px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    flex-shrink: 0;
    margin-right: 40px;
  }

  .more {
    height: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    flex-shrink: 0;
  }

  .kind-list {
    width: 100%;
    height: auto;
    display: flex;
    height: 100%;
    align-items: center;

    // 密码登录
    .kind-item {
      height: 34px;
      line-height: 34px;
      padding: 0px 16px;
      background: #f1f1f1;
      border-radius: 17px 17px 17px 17px;
      margin-right: 24px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }

    .kind-on {
      color: #ffffff;
      background: linear-gradient(134deg, #ff9a51 0%, #ff5e51 100%);
    }
  }
}

.tuijian {
  width: 1220px;
  margin: 0px 0px 15px calc(50% - 610px);
}

// 课程推荐
.recommend {
  width: calc(1220px + 24px);
  height: auto;
  // min-height: 668px;
  overflow: hidden;
  margin-bottom: 20px;

  .m-t-0 {
    margin-top: 0px !important;
  }

  .class-list {
    // width: 66.2%;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    // 单个模块
    .item {
      width: 287px;
      // height: 271px;
      padding-bottom: 10px;
      border-radius: 8px 8px 8px 8px;
      position: relative;
      opacity: 1;
      margin-right: 24px;
      margin-bottom: 24px;
      cursor: pointer;

      .item-img {
        position: absolute;
        width: 252px;
        height: 141px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        left: 18px;
        right: 18px;
        top: 0px;
      }

      .content {
        margin-top: 40px;
        width: 287px;
        height: 231px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        padding: 112px 20px 0px;

        .item-title {
          height: 48px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
        }

        .item-tips {
          margin-top: 8px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #9098ab;
          height: 12px;
          line-height: 12px;
          display: flex;
        }

        .item-price {
          margin-top: 16px;
          display: flex;
          height: 18px;
          justify-content: space-between;

          .price {
            font-family: Microsoft YaHei-Regular;
            color: #ff5d51;
            font-size: 18px;
            line-height: 18px;
            font-weight: Bold;

            .price-icon {
              font-size: 14px;
              line-height: 14px;
              font-weight: 400;
            }
          }

          .price-tips {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
          }
        }
      }
    }
  }
}

.cacelPrice {
  font-size: 16px !important;
  text-decoration: line-through;
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
