<template>
  <div ref="scroll" class="home">
    <!-- <banner-img-seventh />
    <open-seventh> </open-seventh>
    <teacher-seventh> </teacher-seventh>
    <juxue-seventh />  -->
    <div v-if="infoDomain.entrantsType != 3" class="back-tops">
      <div v-show="isShow" class="right-box">
        <div class="qrcode-box">
          <div class="title">{{ appType }} APP</div>
          <div class="qrcode">
            <qriously :value="apkUrl" class="img" :size="92" />
          </div>
          <div class="text"><i class="iconfont icon-anzhuo" /> 安卓手机客户端</div>
        </div>
        <div class="triangle" />

      </div>
      <div :class="qrCode == 1 ? 'left-box' : 'left-boxs'">
        <div class="iconfont icon-a-lujing7 arrow" @click="toTop" />
        <div v-if="qrCode == 1" class="iconfont icon-erweima qr-code" @mouseenter="isShow = true"
          @mouseleave="isShow = false" />
      </div>

    </div>
    <div v-if="infoDomain.entrantsType == 3" class="back-top">
      <div v-if="infoDomain.entrantsType == 3" :class="qrCode == 1 ? 'left-box' : 'left-boxs'">
        <img class="titleimg" src="@/assets/img/Home/homezhuce.png" @click="zhuanYeXqs()">
        <div class="arrow" @mouseenter="isShow1 = true" @mouseleave="isShow1 = false">
          <div v-if="isShow1" class="text">
            <span>咨询</span><span>热线</span>
          </div>
          <img v-else class="img1" src="~@/assets/img/xinren/7601.png">
        </div>
        <!-- <div class="arrow" @mouseenter="isShow2 = true" @mouseleave="isShow2 = false">
          <div v-if="isShow2" class="text">
            <span>在线</span><span>咨询</span>
          </div>
          <img v-else class="img1" src="~@/assets/img/xinren/7602.png">
        </div> -->
        <div class="arrow" @mouseenter="isShow3 = true" @mouseleave="isShow3 = false">
          <div v-if="isShow3" class="text3"><span>公众号</span></div>
          <img v-else class="img1" src="~@/assets/img/xinren/7603.png">
        </div>
        <!-- <div class="arrow" @mouseenter="isShow4 = true" @mouseleave="isShow4 = false">
          <div v-if="isShow4" class="text3"><span>小程序</span></div>
          <img v-else class="img1" src="~@/assets/img/xinren/7604.png">
        </div> -->
        <div class="arrow" @mouseenter="isShow = true" @mouseleave="isShow = false">
          <div v-if="isShow" class="text">
            <span>下载</span><span>APP</span>
          </div>
          <img v-else class="img5" src="~@/assets/img/xinren/7605.png">
        </div>
        <div class="arrow" @click="toTop">
          <img class="img1" src="~@/assets/img/xinren/7606.png">
        </div>
        <img class="foodimg" src="~@/assets/img/xinren/7608.png" @click="zhuanYeXqss()">
      </div>
      <div v-show="isShow1" class="right-box1">
        <div class="qrcode-box">0531-88952666</div>
        <div class="triangle" />
      </div>
      <div v-show="isShow2" class="right-box2">
        <div class="qrcode-box">
          <img src="~@/assets/img/xinren/zaixiankefu.png">
        </div>
        <div class="triangle" />
      </div>
      <div v-show="isShow3" class="right-box3">
        <div class="qrcode-box">
          <img :src="wxImg">
        </div>
        <div class="triangle" />
      </div>
      <!-- <div v-show="isShow4" class="right-box4">
        <div class="qrcode-box">
          4
        </div>
        <div class="triangle" />
      </div> -->
      <div v-show="isShow" class="right-box">
        <div class="qrcode-box">

          <img :src="appImg">
        </div>
        <div class="triangle" />
      </div>
    </div>

    <!-- 第六版 -->
    <div v-for="(item, index) in htmlVal" :key="index">
      <!-- 幻灯片 -->
      <div v-if="item.id == 21 ||
        item.id == 31 ||
        item.id == 41 ||
        item.id == 51 ||
        item.id == 61 ||
        item.id == 71 ||
        item.id == 81 ||
        item.id == 91 ||
        item.id == 101 ||
        item.id == 111 ||
        item.id == 121
      " ref="bannerImg">
        <banner-img v-if="item.id == 21 && bannerImgShow" />
        <div v-else-if="item.id == 31 && bannerImgShow">
          <banner-img-three />
          <banner-menu-three />
        </div>
        <banner-img-fourth v-else-if="item.id == 41 && bannerImgShow" />
        <banner-menu-fifth v-else-if="item.id == 51 && bannerImgShow" />
        <banner-img-sixth v-else-if="item.id == 61 && bannerImgShow" />
        <div v-else-if="item.id == 71 && bannerImgShow">
          <banner-img-seventh />
          <!-- <banner-menu-seventh /> -->
          <banner-menu-new />
          <!-- <banner-menu-seventh /> -->
          <!-- <banner-img-partner />
          <banner-menu-partner /> -->
        </div>

        <banner-img-eighth v-else-if="item.id == 81 && bannerImgShow" />
        <!-- 81 91 101 111 121 -->
        <banner-img-eighth v-else />
      </div>
      <!-- 金刚区 -->
      <div v-if="item.id == 22 || item.id == 42 || item.id == 52 || item.id == 62" ref="bannerMenu">
        <banner-menu v-if="item.id == 22 && bannerMenuShow" />
        <banner-menu-fourth v-else-if="item.id == 42 && bannerMenuShow" />
        <banner-menu-fifth v-else-if="item.id == 52 && bannerMenuShow" />
        <banner-menu-sixth v-else-if="item.id == 62 && bannerMenuShow" />
      </div>
      <!-- 直播课 -->

      <div v-if="(item.id == 23 || item.id == 33 || item.id == 43 || item.id == 63) && !userInfo" ref="live">
        <live v-if="item.id == 23 && liveShow">{{ item.name }}</live>
        <live-three v-else-if="item.id == 33 && liveShow">{{ item.name }}</live-three>
        <live-fourth v-else-if="item.id == 43 && liveShow">{{ item.name }}</live-fourth>
        <live-sixth v-else-if="item.id == 63 && liveShow">{{ item.name }}</live-sixth>
      </div>
      <!-- 精品课程 -->
      <div v-if="item.id == 24 ||
        item.id == 34 ||
        item.id == 44 ||
        item.id == 54 ||
        item.id == 74
      " ref="open">
        <open v-if="item.id == 24 && openShow">{{ item.name }}</open>
        <open-three v-else-if="item.id == 34 && openShow">{{ item.name }}</open-three>
        <open-fourth v-else-if="item.id == 44 && openShow">{{ item.name }}</open-fourth>
        <open-fifth v-else-if="item.id == 54 && openShow">{{ item.name }}</open-fifth>
        <template v-else-if="item.id == 74 && openShow">
          <open-seventh />
          <liveClass-seventh />
          <vip-seventh />
          <datepage-seventh />
          <datepaper-seventh />
          <!-- <open-partner />
          <product-partner /> -->
          <!-- <liveClass-partner />
          <vip-partner />
          <datepage-partner />
          <datepaper-partner /> -->
        </template>
      </div>
      <!-- 热门知识套餐 -->
      <div v-if="item.id == 25 ||
        item.id == 35 ||
        item.id == 45 ||
        item.id == 55 ||
        item.id == 65
      " ref="tuijian">
        <tuijian v-if="item.id == 25 && tuijianShow">{{ item.name }}</tuijian>
        <tuijian-three v-else-if="item.id == 35 && tuijianShow">{{
          item.name
        }}</tuijian-three>
        <tuijian-fourth v-else-if="item.id == 45 && tuijianShow">{{
          item.name
        }}</tuijian-fourth>
        <tuijian-fifth v-else-if="item.id == 55 && tuijianShow">{{
          item.name
        }}</tuijian-fifth>
        <tuijian-sixth v-else-if="item.id == 65 && tuijianShow">{{
          item.name
        }}</tuijian-sixth>
      </div>

      <!-- VIP专属套餐 -->
      <div v-if="item.id == 47 || item.id == 57 || item.id == 67" ref="vip">
        <vip-fourth v-if="item.id == 47 && vipShow">{{ item.name }}</vip-fourth>
        <vip-fifth v-else-if="item.id == 57 && vipShow">{{ item.name }}</vip-fifth>
        <vip-sixth v-else-if="item.id == 67 && vipShow">{{ item.name }}</vip-sixth>
      </div>
      <!-- 师资力量 -->
      <div v-else-if="item.id == 26 || item.id == 36 || item.id == 66 || item.id == 76
      " ref="teacher">
        <teacher v-if="item.id == 26 && teacherShow">{{ item.name }}</teacher>
        <teacher-three v-else-if="item.id == 36 && teacherShow">{{
          item.name
        }}</teacher-three>
        <teacher-sixth v-else-if="item.id == 66 && teacherShow">{{
          item.name
        }}</teacher-sixth>
        <teacher-seventh v-else-if="item.id == 76 && teacherShow" />
        <!-- <teacher-partner v-else-if="item.id == 76 && teacherShow" /> -->
      </div>

      <!-- 资讯 -->
      <div v-else-if="item.id == 38 && newsShow" ref="news">
        <news-three>{{ item.name }}</news-three>
      </div>
      <!-- 服务 -->
      <juxue-seventh v-else-if="item.id == 79" />
      <!-- <juxue-partner v-else-if="item.id == 79" /> -->
      <!-- 公开课 -->
      <gkclass-sixth v-else-if="item.id == 60">{{ item.name }}</gkclass-sixth>
      <!-- 一个学院 新闻 单科 -->
      <other-functions v-else-if="item.id == 28" />
    </div>
    <div v-if="infoDomain.entrantsType == 5">
      <banner-img-partner />
      <!-- <banner-img-partners v-if="university == 1" /> -->
      <!-- <banner-menu-partner v-if="university != 1" /> -->
      <teacher-partner />
      <news-partner />
      <open-partner v-if="university != 1" />


      <course-partner v-if="!isXiaoQingWang" />


      <knowledge-partner style="margin-bottom: 10px;" v-if="university != 1" />

      <knowledge-partners v-if="university == 1" />
      <datepage-partner v-if="!isXiaoQingWang" />
      <datepaper-partner v-if="!isXiaoQingWang" />
    </div>
  </div>
</template>
<script>
import bannerImg from '@/components/homeTwo/bannerImg.vue' // banner图
import bannerMenu from '@/components/homeTwo/bannerMenu.vue' // 菜单
import live from '@/components/homeTwo/live.vue' // 直播课
import news from '@/components/homeTwo/news.vue' // 资讯
import teacher from '@/components/homeTwo/teacher.vue' // 教师
import tuijian from '@/components/homeTwo/tuijian.vue' // 课程推荐
import open from '@/components/homeTwo/open.vue'
/* 第三版 */
import bannerImgThree from '@/components/homeThree/bannerImg.vue' // banner图
import bannerMenuThree from '@/components/homeThree/bannerMenu.vue' // 菜单
import liveThree from '@/components/homeThree/live.vue' // 直播课
import newsThree from '@/components/homeThree/news.vue' // 资讯
import teacherThree from '@/components/homeThree/teacher.vue' // 教师
import tuijianThree from '@/components/homeThree/tuijian.vue' // 课程推荐
import openThree from '@/components/homeThree/open.vue'
/* 第四版 */
import bannerImgFourth from '@/components/homeFourth/bannerImg.vue' // banner图
import bannerMenuFourth from '@/components/homeFourth/bannerMenu.vue' // 菜单
import liveFourth from '@/components/homeFourth/live.vue' // 直播课
import tuijianFourth from '@/components/homeFourth/tuijian.vue' // 课程推荐
import vipFourth from '@/components/homeFourth/vip.vue' // VIP专属套餐
import openFourth from '@/components/homeFourth/open.vue'
/* 第五版 */
import bannerMenuFifth from '@/components/homeFifth/bannerMenu.vue' // 菜单
import tuijianFifth from '@/components/homeFifth/tuijian.vue' // 课程推荐
import vipFifth from '@/components/homeFifth/vip.vue' // VIP专属套餐
import openFifth from '@/components/homeFifth/open.vue'
/* 第六版 */
import bannerImgSixth from '@/components/homesixth/bannerImg.vue' // banner图
import bannerMenuSixth from '@/components/homesixth/bannerMenu.vue' // 菜单
import liveSixth from '@/components/homesixth/live.vue' // 直播课
import teacherSixth from '@/components/homesixth/teacher.vue' // 教师
import tuijianSixth from '@/components/homesixth/tuijian.vue' // 课程推荐
import gkclassSixth from '@/components/homesixth/gkclass.vue' // 公开课
import vipSixth from '@/components/homesixth/vip.vue' // VIP专属套餐
/* 高校合伙人 */
import bannerImgSeventh from '@/components/homeSeventh/bannerImg.vue' // banner图
import teacherSeventh from '@/components/homeSeventh/teacher.vue' // 教师
import openSeventh from '@/components/homeSeventh/open.vue'
import juxueSeventh from '@/components/homeSeventh/juxue.vue'
import vipSeventh from '@/components/homeSeventh/vipClass.vue'
// import teaSwiperSeventh from '@/components/homeSeventh/teaSwiper.vue'
import datepageSeventh from '@/components/homeSeventh/datepage.vue'
import datepaperSeventh from '@/components/homeSeventh/datepaper.vue'
import liveClassSeventh from '@/components/homeSeventh/liveClass.vue'
import bannerMenuSeventh from '@/components/homeSeventh/bannerMenu.vue'
// import teaCarouselSeventh from "@/components/homeSeventh/teaCarousel.vue";
/* 合伙共建 */
import bannerImgPartner from '@/components/homePartner/bannerImg.vue' // banner图
import bannerImgPartners from '@/components/homePartner/bannerImgnew.vue' // banner图
import teacherPartner from '@/components/homePartner/teacherPartner.vue' // 教师
import openPartner from '@/components/homePartner/open.vue'
import coursePartner from '@/components/homePartner/course.vue' // 课程推荐
import knowledgePartner from '@/components/homePartner/knowledge.vue' // 课程推荐
import knowledgePartners from '@/components/homePartner/knowledgenew.vue' // 课程推荐
import newsPartner from '@/components/homePartner/newsView.vue'
import datepagePartner from '@/components/homePartner/datepage.vue'
import datepaperPartner from '@/components/homePartner/datepaper.vue'
import bannerMenuPartner from '@/components/homePartner/bannerMenu.vue'
import bannerMenuNew from '@/components/homeSeventh/bannerMenuNew.vue'
// import teaCarouselSeventh from "@/components/homeSeventh/teaCarousel.vue";
/* 第八版 */
import { getDnsOneApi } from '@/api/home'
import bannerImgEighth from '@/components/homeEighth/bannerImg.vue'
import otherFunctions from '@/components/homeEighth/otherFunctions.vue'

/* Client */
import { getInfo, getToken } from '@/api/cookies'
import { getImagePage } from '@/api/home'
import { getDomain } from '@/api/cookies'
import {
  getStore,
  getAndroidDownloadPackage,
  getPcCityEntrantsList,
  getSelectPcConfiguration
} from '@/api/home'
import { getPcUser } from '@/api/user'
import Vue from 'vue'
import Bus from '@/api/bus'
export default {
  components: {
    bannerImg,
    bannerMenu,
    live,
    news,
    open,
    teacher,
    tuijian,
    /* 第三版 */
    bannerImgThree,
    bannerMenuThree,
    liveThree,
    newsThree,
    teacherThree,
    tuijianThree,
    openThree,
    /* 第四版 */
    bannerImgFourth,
    bannerMenuFourth,
    liveFourth,
    tuijianFourth,
    openFourth,
    vipFourth,
    /* 第五版 */
    bannerMenuFifth,
    tuijianFifth,
    openFifth,
    vipFifth,
    /* 第六版 */
    bannerImgSixth,
    bannerMenuSixth,
    liveSixth,
    teacherSixth,
    tuijianSixth,
    gkclassSixth,
    vipSixth,
    /* 高校合伙人 */
    bannerImgSeventh,
    openSeventh,
    teacherSeventh,
    juxueSeventh,
    bannerMenuSeventh,
    // teaSwiperSeventh,
    vipSeventh,
    datepageSeventh,
    datepaperSeventh,
    liveClassSeventh,
    /* 合伙共建 */
    bannerImgPartner,
    bannerImgPartners,
    knowledgePartners,
    openPartner,
    coursePartner,
    knowledgePartner,
    teacherPartner,
    newsPartner,
    bannerMenuPartner,
    datepagePartner,
    datepaperPartner,
    // teaCarouselSeventh,
    /* 第八版 */
    bannerImgEighth,
    otherFunctions,
    bannerMenuNew
  },
  data() {
    return {
      htmlVal: [],
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow: false,
      toTopisShow: false,
      apkUrl: '',
      qrCode: 1,
      qwCode: '',
      bannerImgShow: true,
      bannerMenuShow: true,
      liveShow: true,
      openShow: true,
      tuijianShow: true,
      vipShow: true,
      teacherShow: true,
      newsShow: true,

      infoDomain: null,
      appType: '',
      wxImg: '',
      appImg: '',
      timer: null,
      userInfo: null,
      isXiaoQingWang: false,
      university: '',
    }
  },
  created() {
    this.userInfo = getInfo()
    this.infoDomain = getDomain()
    const infoDomain = getDomain()
    if (this.infoDomain.eId == process.env.VUE_APP_university) {
      this.university = 1
    }
    this.qrCode = infoDomain.qrCode
    this.isXiaoQingWang = localStorage.getItem('isXiaoQingWang') ? localStorage.getItem('isXiaoQingWang') : false

    // infoDomain.entrantsType
    getStore(infoDomain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.htmlVal = JSON.parse(res.data.json)
        // this.htmlVal = [
        //   {
        //     id: 71,
        //     noUp: true,
        //     name: '幻灯片',
        //     url: 'http://staticfile.xlcwx.com/m-images/juxueyun/collgetPc/banner7.png',
        //     idVal: 1661137530803
        //   },
        //   {
        //     id: 74,
        //     showTitle: true,
        //     name: '知识产品',
        //     url: 'http://staticfile.xlcwx.com/m-images/juxueyun/collgetPc/pcOpen7.png',
        //     idVal: 1661137533291
        //   },
        //   {
        //     id: 76,
        //     showTitle: true,
        //     name: '师资力量',
        //     url: 'http://staticfile.xlcwx.com/m-images/juxueyun/collgetPc/pcTeacher7.png',
        //     idVal: 1661137537035
        //   },
        //   {
        //     id: 79,
        //     showTitle: true,
        //     name: '聚学U服务',
        //     url: 'http://staticfile.xlcwx.com/m-images/juxueyun/collgetPc/fuwu7.png',
        //     idVal: 1661137539830
        //   }
        // ]

      }
    })
    this.loadgetPcUser() // 调用基本资料接口方法
    this.getAndroidDownloadPackage()
    this.getSelectPcConfiguration()
  },

  mounted() {
    this.timer = setInterval(() => {
      getImagePage().then((res) => {

        for (const item of res.rows) {
          if (item.name == '聚学U客户端二维码') {
            this.appImg = item.url
          }
          if (item.name == '聚学U网校微信公众号二维码') {
            this.wxImg = item.url
          }
        }
      })
      if (this.wxImg && this.appImg) {
        clearInterval(this.timer)
        this.timer = null
      }
    }, 3000)
    // 监听滚动事件
    window.addEventListener('scroll', this.scrollToTop)
  },

  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    window.removeEventListener('scroll', this.scrollToTop)
    next()
  },

  methods: {
    // 左侧内容点击跳转详情
    zhuanYeXqs() {
      const userInfo = getInfo()
      if (!userInfo) {
        // Vue.prototype.goLoginView(false)

        Bus.$emit('handleRegister', true)
        return
      }
      this.$router.push({
        path: '/newPactivity'
      })
    },
    zhuanYeXqss() {
      window.open('http://gxhh.unzs.com/')
    },
    // 调用基本资料接口方法
    loadgetPcUser() {
      // getPcUser().then((res) => {});
    },
    toTop() {
      var timer = setInterval(function () {
        const osTop =
          document.documentElement.scrollTop || document.body.scrollTop
        const ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 5)
    },
    // 获取最新安卓安装包
    getAndroidDownloadPackage() {
      const data = {
        appType: this.infoDomain.entrantsType == 3 ? '聚学U' : '聚学堂'
      }
      getAndroidDownloadPackage(data).then((res) => {
        this.apkUrl = res.data.apkUrl
        this.appType = res.data.appType
      })
    },
    getSelectPcConfiguration() {
      getSelectPcConfiguration().then((res) => {
        if (res.homeConfigurations && res.homeConfigurations.length > 0) {
          res.homeConfigurations.forEach(e => {
            if (e.type == 2) {
              this.qwCode = e.img
            }
          })
        }
      })
    },
    //获取营销广告图片
    // async selectPcConfiguration() {

    //   selectPcConfiguration().then((res) => {
    //      

    //   });
    // },
    // async getAndroidDownloadPackage() {
    //   const data = {
    //     appType: this.infoDomain.entrantsType == 3 ? "聚学U" : "聚学堂",
    //   };
    //   const res2 = await getDnsOneApi();
    //   getAndroidDownloadPackage(data).then((res) => {
    //     if (res.data.content) {
    //       this.useMDomain = res2.msg.dns.useMDomain;
    //       // if (this.infoDomain.name == element.name) {
    //       //   console.log(element.name);
    //       if (process.env.NODE_ENV == "production") {
    //         this.apkUrl = `${this.useMDomain}/pages/coupon/transfer`;
    //       } else {
    //         this.apkUrl = `http://192.168.7.26:8083/pages/coupon/transfer`;
    //       }
    //       // }
    //     } else {
    //       this.apkUrl = res.data.apkUrl;
    //     }

    //     this.appType = res.data.appType;
    //   });
    // },
    // getAndroidDownloadPackage() {
    //   const data = {
    //     appType: this.infoDomain.entrantsType == 3 ? "聚学U" : "聚学堂",
    //   };
    //   getAndroidDownloadPackage(data).then((res) => {
    //     this.apkUrl = res.data.apkUrl;
    //     this.appType = res.data.appType;
    //   });
    // },
    // 滚动事件
    scrollToTop() {
      this.whetherToDisplay()
    },

    whetherToDisplay() {
      // if (!this.isElementNotInViewport(this.$refs.teacher)) {
      //   this.teacherShow = true;
      // }
      // if (!this.isElementNotInViewport(this.$refs.bannerImg)) {
      //   this.bannerImgShow = true;
      // }
      // if (!this.isElementNotInViewport(this.$refs.bannerMenu)) {
      //   this.bannerMenuShow = true;
      // }
      // if (!this.isElementNotInViewport(this.$refs.live)) {
      //   this.liveShow = true;
      // }
      // if (!this.isElementNotInViewport(this.$refs.open)) {
      //   this.openShow = true;
      // }
      // if (!this.isElementNotInViewport(this.$refs.tuijian)) {
      //   this.tuijianShow = true;
      // }
      // if (!this.isElementNotInViewport(this.$refs.vip)) {
      //   this.vipShow = true;
      // }
      // if (!this.isElementNotInViewport(this.$refs.news)) {
      //   this.newsShow = true;
      // }
    },

    isElementNotInViewport(el) {
      if (el) {
        // console.log(el, 'el');
        const rect = el[0].getBoundingClientRect()
        return (
          rect.top >=
          (window.innerHeight || document.documentElement.clientHeight) ||
          rect.bottom <= 0
        )
      }
    }
  }
}
</script>
<style lang="less" scoped>
.clicked {
  /* 添加类名时的样式 */
  display: none;
}

.clicked2 {
  /* 添加类名时的样式 */
  display: none;
}

.clicked3 {
  /* 添加类名时的样式 */
  display: none;
}

.center_guanggao {
  width: 400px;
  height: 466px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -233px;
  margin-left: -200px;
  background-color: #797a9f;
  z-index: 9999999999;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #707070;

  .icon_cuo {
    width: 34px;
    height: 34px;
    opacity: 1;
    position: absolute;
    top: -30px;
    right: -30px;
  }
}

.footer_guanggao {
  position: fixed;
  bottom: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: 70px;
  background-color: #950e8a;
  z-index: 9999999999;

  .icon_cuo {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 0;
    margin-right: 172px;
  }
}

.header_guanggao {
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
  height: 70px;
  background-color: #3177ff;
  z-index: 9999999999;

  .icon_cuo {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    right: 0;
    margin-right: 172px;
  }
}

.home {
  width: 100%;
  height: 100%;
  position: relative;
  // padding-top: 68px;
}

//隐藏滚动条
::-webkit-scrollbar {
  display: block !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

.back-top {
  width: 218px;
  position: fixed;
  right: -5%;
  top: 20%;
  z-index: 1000;
  display: flex;

  .left-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .titleimg {
      animation: move5 2s linear infinite alternate both;
      cursor: pointer;
    }

    img {
      opacity: 1;
      margin-bottom: 20px;
    }

    .arrow {
      width: 64px;
      height: 64px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #eaeaea;
      border-radius: 40px;
      cursor: pointer;
      opacity: 1;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 20px;

      .text {
        width: 24px;
        height: 32px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        top: -15px;
      }

      .text3 {
        width: 36px;
        height: 16px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        top: -25px;
      }

      .img1 {
        width: 25px;
        height: 25px;
      }

      .img5 {
        width: 22px;
        height: 27px;
      }
    }

    .foodimg {
      width: 64px;
      height: 104px;
      animation: move5 2s linear infinite alternate both;
      cursor: pointer;
    }

    img:hover {
      opacity: 1;
      transition: 100ms;
    }

    .qr-code:hover {
      color: #3177ff;
      transition: 100ms;
    }

    .arrow:hover {
      background: linear-gradient(180deg, #00c19e 0%, #00ab8c 100%);
      border: 1px solid #00c19e;
    }
  }

  .left-boxs {
    width: 52px;
    // height: 96px !important;
    border-radius: 7px;
    // background-color: #ffffff;
    // box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.11);
    opacity: 0.59;

    .arrow {
      font-size: 24px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      cursor: pointer;
      opacity: 1;
    }

    .arrow:hover {
      color: #3177ff;
      transition: 100ms;
    }
  }

  .right-box1 {
    display: flex;
    align-items: center;
    position: relative;
    left: -250px;
    top: -150px;

    .triangle {
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-left-color: #ffffff;
    }

    .qrcode-box {
      width: 130px;
      height: 35px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #00b090;
      text-align: center;
      padding-top: 8px;
    }
  }

  .right-box2 {
    display: flex;
    align-items: center;
    position: relative;
    left: -250px;
    top: -90px;

    .triangle {
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: #ffffff;
    }

    .qrcode-box {
      width: 126px;
      min-height: 128px;
      border-radius: 8px;
      padding: 6px 5px;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.01);

      img {
        width: 116px;
        height: 128px;
      }

      .title {
        text-align: center;
        color: #444444;
        font-size: 18px;
        font-size: 600;
      }

      .qrcode {
        margin: auto;
        width: 92px;
        height: 92px;
        margin-bottom: 6px;
      }

      .text {
        color: #717171;
        font-size: 10px;
      }
    }
  }

  .right-box3 {
    display: flex;
    align-items: center;
    position: relative;
    left: -250px;
    top: -60px;

    .triangle {
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: #ffffff;
    }

    .qrcode-box {
      width: 126px;
      min-height: 128px;
      border-radius: 8px;
      padding: 6px 5px;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.01);

      img {
        width: 100%;
        // height: 128px;
      }

      .qrcode {
        margin: auto;
        width: 92px;
        height: 92px;
        margin-bottom: 6px;
      }

      .text {
        color: #717171;
        font-size: 10px;
      }
    }
  }

  .right-box4 {
    display: flex;
    align-items: center;
    position: relative;
    left: -324px;

    .triangle {
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: #ffffff;
    }

    .qrcode-box {
      width: 126px;
      min-height: 158px;
      border-radius: 8px;
      padding: 8px 11px;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.01);

      .title {
        text-align: center;
        color: #444444;
        font-size: 18px;
        font-size: 600;
      }

      .qrcode {
        margin: auto;
        width: 92px;
        height: 92px;
        margin-bottom: 6px;
      }

      .text {
        color: #717171;
        font-size: 10px;
      }
    }
  }

  .right-box {
    display: flex;
    align-items: center;
    position: relative;
    left: -250px;
    top: 20px;

    .triangle {
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: #ffffff;
    }

    .qrcode-box {
      width: 126px;
      min-height: 128px;
      border-radius: 8px;
      padding: 6px 5px;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.01);

      img {
        width: 100%;
        // height: 128px;
      }

      .qrcode {
        margin: auto;
        width: 92px;
        height: 92px;
        margin-bottom: 6px;
      }

      .text {
        color: #717171;
        font-size: 10px;
      }
    }
  }
}

@keyframes move5 {
  20% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

.back-tops {
  // width: 218px;
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 1000;
  display: flex;

  .left-box {
    width: 52px;
    height: 96px !important;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.11);
    opacity: 0.59;

    .arrow {
      font-size: 24px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      cursor: pointer;
      opacity: 1;
    }

    .qr-code {
      font-size: 36px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      cursor: pointer;
      opacity: 1;
    }

    .qr-code:hover {
      color: #3177ff;
      transition: 100ms;
    }

    .arrow:hover {
      color: #3177ff;
      transition: 100ms;
    }
  }

  .left-boxs {
    width: 52px;
    // height: 96px !important;
    border-radius: 7px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.11);
    opacity: 0.59;

    .arrow {
      font-size: 24px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      cursor: pointer;
      opacity: 1;
    }

    .qr-code {
      font-size: 36px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      cursor: pointer;
      opacity: 1;
    }

    .qr-code:hover {
      color: #3177ff;
      transition: 100ms;
    }

    .arrow:hover {
      color: #3177ff;
      transition: 100ms;
    }
  }

  .right-box {
    display: flex;
    align-items: center;

    .triangle {
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: #ffffff;
    }

    .qrcode-box {
      width: 126px;
      min-height: 158px;
      border-radius: 8px;
      padding: 8px 11px;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.01);

      .title {
        text-align: center;
        color: #444444;
        font-size: 18px;
        font-size: 600;
      }

      .qrcode {
        margin: auto;
        width: 92px;
        height: 92px;
        margin-bottom: 6px;
      }

      .text {
        color: #717171;
        font-size: 10px;
      }
    }
  }
}
</style>
