<template>
  <div v-if="list && list.length > 0" class="jingpin-box">
    <!--标题 最近直播 -->
    <div class="til">
      <div>公开课</div>
      <div @click="goss()">MORE ></div>
    </div>
    <div class="classlist">
      <div v-for="(item, index) in list" :key="index" class="classlist-item">
        <div class="itemmsg">
          <div class="itemimg">
            <img :src="item.pcPictureUrl" alt="">
          </div>
          <div class="itemtil">
            <div class="itemtil-top">{{ item.name }}</div>
            <div class="itemtil-rq"><i class="rqimg" /> {{ item.clicks ? item.clicks : 0 }}人气值</div>
          </div>
        </div>
        <div class="slott" />
        <div class="kbsj">
          <div>开播时间：{{ item.zbBeginTime }}</div>
          <div @click="gos(item)">进入直播</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { Know } from '@/api/know'
const know = new Know()
import Bus from '@/api/bus'
import Vue from 'vue'
import { getLbClassCourseById } from '@/api/home'
import { selectCoursePages } from '@/api/home'
import { getInfo } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [], // 公开课   ifHave: false,
      liveId: null,
      ifHave: false,
      liveshowName: '',
      live: null
    }
  },
  created() {
    this.userInfo = getInfo()
    this.getList()
  },
  methods: {
    // 查看更多直播课按钮
    zhibokes() {
      this.$router.push({ name: '直播课' })
    },
    gos(item) {
      // this.$router.push({ path: '/publicClass' })
      if (this.userInfo) {
        if (item.state == 2 && !item.playbackUrl) {
          this.$message({
            type: 'error',
            message: '回放生成中'
          })
        } else {
          this.addRecord(item)
          const routeUrl = this.$router.resolve({
            path: '/publicClass/publicClassDetail',
            query: {
              id: item.id
            }
          })
          window.open(routeUrl.href, '_blank')
        }
      } else {
        Bus.$emit('toLoginPage', true)
      }
      /* if (item.state == 2 && !item.playbackUrl) {
        this.$message({
          type: "error",
          message: "回放生成中",
        });
      } else {
        this.addRecord(item);
        let routeUrl = this.$router.resolve({
          path: "/publicClass/publicClassDetail",
          query: {
            id: item.id,
          },
        });

        window.open(routeUrl.href, "_blank");
      } */
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null
      }
      know.addPublicityClassStudentRecord(data).then((res) => {

      })
    },
    goss() {
      this.$router.push({ path: '/publicClasssixth' })
    },
    /* 公开课列表 */
    getList() {
      const data = {
        pageNum: 1,
        pageSize: 6,
        isShow: 1
        /* state: 3, */
      }
      know.getPublicityClassList(data).then((res) => {
        this.list = res.rows
      })
    }
  }
}
</script>
<style lang="less" scoped>
// 最近直播  内容
.jingpin-box {
  // width: 66.2%;
  width: 1220px;
  margin: auto;
  margin-top: 32px;

  .til {
    width: 100%;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;

    justify-content: space-between;
    margin-bottom: 8px;

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .classlist {
    width: 100%;
    // height: 382px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div {
      width: 392px;
      height: 181px;
      background: url('~@/assets/img/homesixth/Group 3327.png');
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin-top: 20px;
      margin-right: 22px;
    }

    >div:nth-child(3n) {
      margin-right: 0;
    }

    .classlist-item {
      padding: 16px;

      .itemmsg {
        width: 360px;
        height: 96px;
        display: flex;
        justify-content: flex-start;

        .itemimg {
          width: 144px;
          height: 81px;
          border-radius: 6px 6px 6px 6px;
          opacity: 1;

          img {
            width: 144px;
            height: 81px;
            border-radius: 6px 6px 6px 6px;
            background-size: cover;
          }
        }

        .itemtil {
          position: relative;
          margin-left: 12px;
          margin-top: 10px;

          .itemtil-top {
            width: 192px;
            max-height: 48px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            overflow: hidden;
            line-height: 24px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }

          .itemtil-rq {
            display: flex;
            height: 12px;
            line-height: 12px;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            position: absolute;
            bottom: 10px;

            .rqimg {
              display: block;
              width: 12px;
              height: 12px;
              background: url('~@/assets/img/homesixth/Frame (1).png');
              background-size: cover;
            }
          }
        }
      }

      .slott {
        width: 360px;
        height: 1px;
        background: #ececec;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-top: 16px;
      }

      .kbsj {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        height: 28px;
        line-height: 28px;

        >div:nth-child(1) {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }

        >div:nth-child(2) {
          width: 80px;
          height: 28px;
          background: #1061ff;
          border-radius: 14px 14px 14px 14px;
          opacity: 1;
          text-align: center;
          line-height: 28px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}</style>
