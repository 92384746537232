<template>
  <div>
    <div class="navs-box">
      <div class="ba"></div>
      <div class="navs">
        <div v-for="(item, index) in list" :key="index" @click.stop="gopage(item.url)" class="item">
          <img class="item-img" :src="item.image" />
          <div class="item-title">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="brace"></div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [
        {
          text: "直播课",
          image: require("@/assets/img/homeThree/home3.png"),
          url: "/zhiboclass",
        },
        {
          text: "知识套餐",
          image: require("@/assets/img/homeThree/home1.png"),
          url: "/allcurriculum/typeclass",
        },
        {
          text: "精品课程",
          image: require("@/assets/img/homeThree/home6.png"),
          url: "/jingpin/courer",
        },
        {
          text: "优惠券",
          image: require("@/assets/img/homeThree/home2.png"),
          url: "/my/mineCoupon",
        },
        {
          text: "学习资料",
          image: require("@/assets/img/homeThree/home5.png"),
          url: "/data/dataPackage",
        },
        {
          text: "题库",
          image: require("@/assets/img/homeThree/home4.png"),
          url: "/quesTion/quesTionList",
        },
      ],
    };
  },
  created() {
    this.userInfo = getInfo();
  },
  methods: {
    gopage(path) {
      let userInfo = getInfo();
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false);
        return;
      }
      /* this.$router.push(path); */
      window.open(path, '_blank')
    },
  },
};
</script>
<style lang="less" scoped>
.navs-box {
  width: 1220px;
  height: 90px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 25%);
  box-shadow: 0px 8px 16px 1px rgba(204, 204, 204, 0.10000000149011612);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  position: absolute;
  left: calc(50% - 610px);
  top: 370px;
  display: flex;
  align-items: center;
  z-index: 199;
  .ba {
    position: absolute;
    width: 1220px;
    height: 60px;
    background: linear-gradient(180deg, #fff1f0 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    top: 0px;
    z-index: 1;
  }
}
// 五图片
.navs {
  // width: 65%;
  width: 1220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto;
  padding: 0px 67px;
  z-index: 100;
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    .item-img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }
    .item-title {
      font-size: 20px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
.brace {
  height: 40px;
  width: 100%;
  margin-bottom: 60px;
}
</style>
