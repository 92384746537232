<template>
  <div class="two-box" v-if="teacherList && teacherList.length > 0">
    <!--标题 师资力量 -->
    <div class="title">
      <p><slot>师资力量</slot></p>
      <!-- <span>查看更多&nbsp;></span> -->
      <el-pagination
        class="redPage"
        :current-page="pageNum"
        @prev-click="toprev"
        @next-click="tonext"
        :page-size="4"
        layout="prev, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <div class="carousel">
      <div
        @click="goDetails(item)"
        v-for="(item, index) in teacherList"
        :key="index"
        :id="`viesww${index}`"
        class="swiperItem"
      >
        <!-- 列表 -->
        <div
          class="teachers"
          :style="`background-image:url(${item.terImg});background-size: 100% 100%;`"
        >
          <div class="te-detail">
            <div class="detail-text">
              {{ item.graduateName }}、{{ item.majorName }}
            </div>
            <div class="detail-content" v-html="item.profile"></div>
          </div>
          <div class="te-tips">
            <div class="te-name">{{ item.nickname }}</div>
            <div class="te-text ellipsis">
              {{ item.graduateName }}、{{ item.majorName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/common.css";
import { TeacherClient } from "@/api/teacherClient";
let teacherClient = new TeacherClient();
export default {
  components: {},
  data() {
    return {
      total: "",
      teacherList: [],
      pageNum: 1,
      pageSize: 4,
    };
  },
  async created() {
    // 教师
    await teacherClient
      .teacherPageList(this.pageSize, 1, undefined, 1)
      .then((res) => {
        if (res.rows) {
          this.teacherList = res.rows;
          this.total = res.total;
        } else {
          this.teacherList = [];
        }
      });
    // this.say();
  },
  methods: {
    goDetails(item) {
      let routeUrl = this.$router.resolve({
        path: `/faculty/teacherDetail`,
        query: {
          id: item.id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    tonext(page) {
      this.pageNum = page;
      teacherClient
        .teacherPageList(this.pageSize, page, undefined, 1)
        .then((res) => {
          if (res.rows) {
            this.teacherList = res.rows;
            this.total = res.total;
          } else {
            this.teacherList = [];
          }
        });
    },
    toprev(page) {
      this.pageNum = page;
      teacherClient
        .teacherPageList(this.pageSize, page, undefined, 1)
        .then((res) => {
          if (res.rows) {
            this.teacherList = res.rows;
            this.total = res.total;
          } else {
            this.teacherList = [];
          }
        });
    },
    // say() {
    //   this.$nextTick(() => {
    //     var last = document.getElementById(
    //       `viesww${this.teacherList.length - 1}`
    //     );
    //     if (last) {
    //       let star = last.style.transform.indexOf("translateX(");
    //       let end = last.style.transform.indexOf("px)");
    //       let val = last.style.transform.substring(
    //         star + "translateX(".length,
    //         end
    //       );
    //       if (Number(val) < 1200 * 3) {
    //         this.$refs.nop.prev();
    //       }
    //       for (let i in this.teacherList) {
    //         var box = document.getElementById(`viesww${i}`);
    //         let starIndex = box.style.transform.indexOf("translateX(");
    //         let endIndex = box.style.transform.indexOf("px)");
    //         let nuw = box.style.transform.substring(
    //           starIndex + "translateX(".length,
    //           endIndex
    //         );
    //         let data = nuw ? Number(nuw) / 4 : null;
    //         if (data) {
    //           box.style.transform = `translateX(${data}px) scale(1) `;
    //           box.classList.add("is-animating");
    //         }
    //       }
    //     }
    //   });
    // },
  },
};
</script>
<style>
.el-popover {
  overflow: hidden;
  padding: 0px;
}
</style>
<style lang="less" scoped>
.two-box {
  width: 100%;
  height: 588px;
  background: #ffffff;
  padding-top: 60px;
  // 标题 最新公开课
  .title {
    width: 1220px;
    margin: auto;
    height: 23px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 24px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a8abbe;
      cursor: pointer;
    }
  }
  /* 讲师列表 */
  .carousel {
    display: flex;
    width: 1220px;
    margin: auto;
    /* 讲师 */
    .teachers {
      position: relative;
      z-index: 22;
      width: 275px;
      height: 412px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      margin-right: 40px;
      &:hover {
        .te-detail {
          display: block !important;
        }
      }
      .te-tips {
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0px;
        height: 54px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 0px 0px 4px 4px;
        display: flex;
        align-items: center;
        z-index: 10;
        padding: 0px 20px;
        .te-name {
          font-size: 20px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 20px;
          flex-shrink: 0;
          margin-right: 8px;
        }
        .te-text {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
          height: 14px;
          margin-top: 5px;
        }
      }
      .te-detail {
        position: absolute;
        display: none;
        top: 0px;
        left: 0px;
        bottom: 0px;
        width: 100%;
        background: url("~@/assets/img/homeThree/teacher.png") 100% 100%;
        z-index: 100;
        padding: 48px 32px;
        overflow: hidden;
        .detail-text {
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 26px;
        }
        .detail-content {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          margin-top: 24px;
        }
      }
    }
  }
}
</style>
