<template>
  <div class="navs">
    <div v-for="(item, index) in list" :key="index" class="item" @click="gopage(item.url)">
      <!-- <img class="bg-img" src="@/assets/img/homeFourth/bgImg.png" /> -->
      <img class="item-img" :src="item.image">
      <div class="item-content">
        <div class="item-title">{{ item.text }}</div>
        <div class="item-more">查看更多<i style="marigin-rop:3px;font-size:12px" class="iconfont el-icon-arrow-right" /></div>
      </div>
      <div v-show="index < list.length-1 " class="item-line" />
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [
        {
          text: '学习资料',
          image: require('@/assets/img/homeSeventh/bannerMenu/menu1.png'),
          url: '/seventhPage/dataPackage'
        },
        {
          text: '题库',
          image: require('@/assets/img/homeSeventh/bannerMenu/menu2.png'),
          url: '/seventhPage/quesTionList'
        },
        {
          text: '精品课程',
          image: require('@/assets/img/homeSeventh/bannerMenu/menu3.png'),
          url: '/seventhPage/courer'
        },
        {
          text: '直播课',
          image: require('@/assets/img/homeSeventh/bannerMenu/menu4.png'),
          url: '/seventhPage/zhiboclass'
        },
        {
          text: 'VIP专属班',
          image: require('@/assets/img/homeSeventh/bannerMenu/menu5.png'),
          url: '/seventhPage/VIPClass'
        }
      ]
    }
  },
  created() {
    this.userInfo = getInfo()
  },
  methods: {
    gopage(path) {
      const userInfo = getInfo()
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false)
        return
      }
      /* this.$router.push(path); */
      window.open(path, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
// 五图片
.navs {
  // width: 65%;
  position: absolute;
  transform: translatex(-50%);
  left: 50%;
  width: 1200px;
  z-index: 3;
  display: flex;
  border-radius: 8px;
  background-color: #fff;
  justify-content: space-between;
  padding: 0 1px 0 9px;
  align-items: center;
  flex-wrap: wrap;
  margin: 24px auto 24px;
  .item {
    width: 17%;
    height: 90px;
    margin-left: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.10000000149011612);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
    background: #fff;
    .bg-img {
      position: absolute;
      left: 0px;
      width: 100%;
    }
    .item-content {
      margin-left:12px;
      // margin-top:8px;
      // width: 100%;
      .item-title {
        margin-bottom: 8px;
      //  width: 64px;
       height: 19px;
       font-size: 16px;
       font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
      //  font-weight: 600;
       color: #333333;
      }
      .item-more {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        margin-top: 8px;
      }
    }
    .item-img {
      margin-top: 10px;
      margin-left: 7px;
      width: 61px;
      height: 71px;
      flex-shrink: 0;
    }
    .item-line {
      position: absolute;
      right: -1%;
      width: 0px;
      height: 43px;
      opacity: 1;
      border: 1px solid #f2f2f2;
    }
  }
}
</style>
