<template>
  <div class="teacher-view">
    <!--标题 师资力量 -->
    <div class="title">
      <p>
        <slot>匠心成就</slot>
      </p>
      <span class="tips flex-c">
        <div class="tips-line" />
        全方位的服务为您的学历提升保驾护航
      </span>
    </div>
    <div ref="list" class="list">
      <div class="list-item" @click="saveImg('/juXueUView/teacher')" @mouseenter="enlarge($event)">
        <!-- <div class="hove">
          <div class="title">口碑师资</div>
          <div class="text">教学经验丰富</div>
          <div class="text">实战功底深厚</div>
          <div class="text">授课直击重点</div>
          <div class="text">深谙学员需求</div>
        </div> -->
        <div class="but">
          <span>了解更多</span><img class="arrowrightlong" src="../../assets/img/homeSeventh/arrowrightlong.png" alt="">
        </div>
        <div class="noHove bg1">
          <!-- <img
            class="noHove-img"
            src="@/assets/img/homeSeventh/juxueIcon1.png"
          > -->
          <div class="rgb">
            <div class="num">
              <el-image :src="require('@/assets/img/homeSeventh/01.png')" />
            </div>
            <div class="text">口碑师资</div>
          </div>
        </div>
      </div>
      <div class="list-item" @click="saveImg('/juXueUView/teaching')" @mouseenter="enlarge($event)">
        <!-- <div class="hove">
          <div class="title">教学模式</div>
          <div class="text">融合式教学</div>
          <div class="text">梯进式教学</div>
          <div class="text">属地化教学</div>
          <div class="text">班期制教学</div>
        </div> -->
        <div class="but">
          <span>了解更多</span><img class="arrowrightlong" src="../../assets/img/homeSeventh/arrowrightlong.png" alt="">
        </div>
        <div class="noHove bg2">
          <!-- <img
            class="noHove-img"
            src="@/assets/img/homeSeventh/juxueIcon2.png"
          > -->
          <div class="rgb">
            <div class="num">
              <el-image :src="require('@/assets/img/homeSeventh/02.png')" />
            </div>
            <div class="text">教学模式</div>
          </div>
        </div>
      </div>
      <div class="list-item" @click="saveImg('/juXueUView/classroom')" @mouseenter="enlarge($event)">
        <!-- <div class="hove">
          <div class="title">智慧课堂</div>
          <div class="text">AI智能诊断学情</div>
          <div class="text">直播视频弹题</div>
          <div class="text">人机智能答疑</div>
          <div class="text">OMO大数据教学</div>
        </div> -->
        <div class="but">
          <span>了解更多</span><img class="arrowrightlong" src="../../assets/img/homeSeventh/arrowrightlong.png" alt="">
        </div>
        <div class="noHove bg3">
          <!-- <img
            class="noHove-img"
            src="@/assets/img/homeSeventh/juxueIcon3.png"
          > -->
          <div class="rgb">
            <div class="num">
              <el-image :src="require('@/assets/img/homeSeventh/03.png')" />
            </div>
            <div class="text">智慧课堂</div>
          </div>
        </div>
      </div>
      <div class="list-item" @click="saveImg('/juXueUView/service')" @mouseenter="enlarge($event)">
        <!-- <div class="hove">
          <div class="title">深度服务</div>
          <div class="text">个性化管家服务</div>
          <div class="text">智能学习服务</div>
          <div class="text">节点提醒服务</div>
          <div class="text">其他日常服务</div>
        </div> -->
        <div class="but">
          <span>了解更多</span><img class="arrowrightlong" src="../../assets/img/homeSeventh/arrowrightlong.png" alt="">
        </div>
        <div class="noHove bg4">
          <!-- <img
            class="noHove-img"
            src="@/assets/img/homeSeventh/juxueIcon4.png"
          > -->
          <div class="rgb">
            <div class="num">
              <el-image :src="require('@/assets/img/homeSeventh/04.png')" />
            </div>
            <div class="text">深度服务</div>
          </div>
        </div>
      </div>
      <div class="list-item" @click="saveImg('/juXueUView/training')" @mouseenter="enlarge($event)">
        <!-- <div class="hove">
          <div class="title">人才培养</div>
          <div class="text">聚学U人才库</div>
          <div class="text">团企人才培训</div>
          <div class="text">跨界人才合作</div>
          <div class="text">职业技能提升行动</div>
        </div> -->
        <div class="but">
          <span>了解更多</span><img class="arrowrightlong" src="../../assets/img/homeSeventh/arrowrightlong.png" alt="">
        </div>
        <div class="noHove bg5">
          <!-- <img
            class="noHove-img"
            src="@/assets/img/homeSeventh/juxueIcon5.png"
          > -->
          <div class="rgb">
            <div class="num">
              <el-image :src="require('@/assets/img/homeSeventh/05.png')" />
            </div>
            <div class="text">人才培养</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { TeacherClient } from '@/api/teacherClient'
const teacherClient = new TeacherClient()
export default {
  components: {},
  data() {
    return {
      teacherList: []
    }
  },
  async created() { },
  methods: {
    // 放大
    enlarge(e) {
      // querySe

      this.$refs.list.childNodes.forEach((item) => {
        item.classList.remove('enlarge')
      })
      e.target.classList.add('enlarge')
    }
  }
}
</script>
<style>
.el-popover {
  overflow: hidden;
  padding: 0px;
}
</style>
<style lang="less" scoped>
.teacher-view {
  background-color: #f5f5f5;
  padding-top: 28px;
  // margin-bottom: 82px;
}

// 标题 最新公开课
.title {
  width: 1200px;
  margin: auto;
  height: 23px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 24px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 32px;
  }

  .tips {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 16px;

    .tips-line {
      width: 32px;
      height: 1px;
      background: #00AB8C;
      margin-right: 8px;
    }
  }
}

.list {
  width: 1200px;
  height: 312px;
  background: #f5f5f5;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  // padding: 32px 32px 24px 32px ;
  margin: 0px auto 30px;
  display: flex;

  .enlarge {
    width: 373px !important;
  }

  .list-item {
    position: relative;
    width: 186px;
    height: 312px;
    border-radius: 4px;
    overflow: hidden;
    opacity: 1;
    margin-right: 21px;
    background-size: 100% 100%;
    text-align: center;
    height: 312px;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    cursor: pointer;
    transition: all 0.7s;

    .but {
      position: absolute;
      z-index: 1;
      bottom: 31px;
      left: 267px;
      width: 86px;
      height: 20px;
      font-size: 15px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;

      // line-height: 0px;
      // border-color: none;
      .arrowrightlong {
        margin-left: 6px;
        display: inline-block !important;
        width: 20px;
        height: 10px;
      }
    }

    &:first-child {
      flex: 1;
      min-width: 186px;
    }

    &:last-child {
      margin-right: 0;
    }

    .bg1 {
      background: url("~@/style/image/home/juxue01.png");
      background-position: center center;
    }

    .bg2 {
      background: url("~@/style/image/home/juxue02.png");
      background-position: center;
    }

    .bg3 {
      background: url("~@/style/image/home/juxue03.png");
      background-position: center center;
    }

    .bg4 {
      background: url("~@/style/image/home/juxue04.png");
      background-position: center center;
    }

    .bg5 {
      background: url("~@/style/image/home/juxue05.png");
      background-position: center center;
    }

    .noHove {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;

      // padding-top: 156px;
      .noHove-img {
        width: 52px;
        height: 52px;
        margin: 0px auto;
      }

      .rgb {
        height: 312px;
        background-image: linear-gradient(to top, rgba(31, 31, 31, 0.9), rgba(255, 255, 255, 0.1));

        .num {
          position: absolute;
          left: 21px;
          bottom: 75px;
          width: 48px;
          height: 49px;
          font-size: 54px;
          font-family: Bahnschrift-SemiBold, Bahnschrift;
          font-weight: 600;
          color: #D5D5D5;
        }

        .text {
          position: absolute;
          left: 20px;
          bottom: 31px;
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
  }
}</style>
