<template>
  <div class="seventhOpen">

    <!-- 套餐列表 -->
    <div class="recommend">
      <div class="contentWrapLeft">
        <div class="colleges-title">
          <div class="text">
            <div>推荐课程</div>
          </div>
          <div class="kind-list">
            <div v-for="(item, index) in majorList" :key="index" class="kind-item"
              :class="search.kind == item.pKind ? 'active' : ''" @click="school(item)">
              {{ item.pName }}
              <div class="choiceLefts" :v-if="search.kind == item.pKind" />
            </div>
          </div>
          <span class="more" @click="saveImg('/seventhPage/VIPClass')">更多<i class="iconfont el-icon-d-arrow-right"
              style="font-size:12px" /></span>
        </div>
        <div v-if="loading" v-loading.lock="loading" :element-loading-text="'加载中'"
          style="width=863px;height:610px;background-color:#f5f5f5" />
        <template v-if="RecommendClassList.length > 0">
          <div v-for="(item, index) in RecommendClassList" :key="index" class="contentItem" @click="goDetails(item)">
            <div class="imgItem"><el-image :src="item.img" class="imgClass" fit="cover" /></div>
            <div class="item_content">
              <div class="titleItem twoEllipsis">{{ item.tiitle }}</div>
              <div class="tips">
                <span class="fuwu"><span style="margin-right:10px">专属服务 </span> <span
                    style="margin-right:10px">极速答疑</span> <span>督学跟踪</span></span>
                <!-- <el-divider direction="vertical" /> -->
                <!-- <span class="courseNum">{{ item.kechengNum ? item.kechengNum + '门课程' : '暂无课程' }}</span> -->
              </div>
              <div class="ter-list">
                <div v-for="(terDtoVos, terIndex) in item.teacherDtoVos" v-show="terIndex < 4" :key="terIndex"
                  class="ter-item">
                  <div class="terbox">
                    <el-image class="ter-img" :src="terDtoVos ? terDtoVos.terImg : ''" fit="cover" />
                  </div>
                  <div class="ter ellipsis">
                    {{ terDtoVos ? terDtoVos.nickname : "" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="datepage_list">
          <div class="queBox">
            <img class="que" src="@/assets/img/homeSeventh/que.png">
            <p>课程研发中~</p>
          </div>
        </div>
      </div>
      <div class="entrance">
        <div class="text">考试功能入口</div>
        <div class="entrance-box">
          <div v-for="(item, index) in exm" :key="index" class="item">
            <!-- <div class="tips-box">
              <div class="Union1Box"><i class="iconfont icon-Union1" /></div>
              <div ref="line" :class="{lineLength:index == exm.length-1&&exm[index].menuList.length == 3 , lineLengthno:index == exm.length-1&&exm[index].menuList.length < 1 }" class="line" />
            </div> -->
            <div class="dis_flex">
              <div class="item-title">
                <!-- -->
                <el-image class="item-title-img" :src="require(`../../assets/img/Home/exmIcon.png`)"
                  object-fit="contain" />
                <span class="menu-name">{{ item.menuName }}</span>
                <!-- <span class="menu-date">6月15日 - 6月30日</span> -->
              </div>
              <!-- <el-image ref="slices" src="~@/assets/img/homeSeventh/slices2.png" fit="contain"> </el-image> -->
            </div>
            <!-- <div class="Up" /> -->
            <div class="entrance-content">
              <div v-for="(chil, chilIndex) in item.menuList" :key="chilIndex" class="content-text"
                @click="goPageDetail(item, chil)" @mouseenter="activeColor(item, chil, index, $event)"
                @mouseleave="removeColor(item, chil, index, $event)">
                {{ chil.menuName }}
              </div>
            </div>
          </div>
          <div v-if="exm.length == 0 || !exm" class="noexm">暂无信息</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import '@/assets/css/common.css'
import { Know } from '@/api/know'
const know = new Know()
import { selectNewPageListToIndex, selectPageListToIndexnew, getMYTenantKindZyList, selectKindTenantIdProducts, getMYTenantKindZyListnew } from '@/api/home'
import { getClassCourseListById } from '@/api/mykecheng'
import { getInfo } from '@/api/cookies'
export default {
  components: {},
  data() {
    return {
      openClasslist: [], // 公开课
      collageList: [], // 学院
      search: {
        schoole: null,
        kindList: null,
        kind: null,
        kindName: null
      },
      loadingBtn: false,
      unPurchase: true,
      showMore: '在线题库',
      zhuanList: [],
      pageData: [],
      majorList: [],
      userInfo: null,
      classlist: [], // 知识套餐
      // exclusiveClassList: [], // VIP,
      exm: [],
      loading: false,
      RecommendClassList: [], // 推荐课程
      tableData: [],
      activeName: 'first'
    }
  },
  async created() {
    this.userInfo = await getInfo()
    await this.getKind()
    this.searchClick()
    await this.getExmInfo()
  },
  methods: {
    /* =========专业信息========== */
    school(val) {

      if (val) {
        //  
        // this.search.schoole = val.pKind
        this.search.kindList = val
        this.search.kindName = val?.pName
        this.search.kind = val?.pKind
      } else {
        this.search.kind = null
      }

      this.searchClick()
    },
    /* ======= 选择专业============*/
    searchClick() {

      this.getClassList()
      this.getExmInfo()
      // this.selectPageListToIndex()
      this.selectPageListToIndexRecommend()
    },
    /* // 考试功能信息 */
    getExmInfo() {

      const schoole = this.majorList[0]
      if (schoole) {
        know
          .informMenuPcList(
            undefined,
            this.search.kind ? this.search.kind : 1
          )
          .then((res) => {
            this.exm = res.data
            //  
          })
      }
    },
    // 考试功能信息跳转详情页
    goPageDetail(item, chil) {
      this.$router.push(
        `/seventhPage/shiyong?menuOneId=${item.id}&menuTwo=${chil.id
        }&kind=${this.search.kind ? this.search.kind : this.majorList[1].pKind
        }`
      )
    },

    /* =======知识套餐============ */
    // 获取知识套餐列表
    getClassList() {
      selectPageListToIndexnew(6, 1, undefined, this.search.kind, undefined,
        undefined, 3).then((res) => {
          for (const item of res.rows) {
            if (item.curForms) {
              item.curForms = item.curForms.split(',')
            }
          }
          this.classlist = res.rows
          for (const item of this.classlist) {
            const data =
              item.teacherDtoVos && item.teacherDtoVos.length > 0
                ? item.teacherDtoVos[0]
                : null
            item.tImg = data ? data.terImg : ''
            item.terName = data ? data.userName : ''
          }
        })
    },
    // 获取项目专业
    async getKind() {

      getMYTenantKindZyListnew().then(res => {
        if (res.code == 0) {
          this.majorList = res.data

          if (this.majorList.length > 0) {
            this.school(this.majorList[0])
          }
        } else {
          this.majorList = []
        }
      })
      // await getMYTenantKindZyList().then((res) => {
      //   if (res.code == 0) {
      //     this.zhuanList = res.data
      //     const oldList = []
      //     this.zhuanList.filter(item => {
      //       if (item.pKind == 3) {
      //         item.num = 1
      //         item.pName = '统招专升本'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 1) {
      //         item.num = 2
      //         item.pName = '自考专升本'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 9) {
      //         item.num = 3
      //         item.pName = '普通考研'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 6) {
      //         item.num = 4
      //         item.pName = '教师资格证'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 7) {
      //         item.num = 5
      //         item.pName = '计算机等级'
      //         oldList.push(item)
      //       }
      //       if (item.pKind == 5) {
      //         item.num = 6
      //         item.pName = '英语等级'
      //         oldList.push(item)
      //       }
      //     })
      //     const len = oldList.length
      //     for (let i = 0; i < len; i++) {
      //       for (let j = 0; j < i; j++) {
      //         if (oldList[j].num > oldList[i].num) {
      //           const temp = oldList[j]
      //           oldList[j] = oldList[i]
      //           oldList[i] = temp
      //         }
      //       }
      //     }
      //     this.majorList = oldList
      //   } else {
      //     this.zhuanList = []
      //   }
      // })


    },
    // 点击图片跳转到知识套餐详情页
    imgxq(index, item) {
      const routeUrl = this.$router.resolve({
        name: '知识套餐课详情',
        query: {
          id: item.id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    /* ============VIP专属班列表============ */
    selectPageListToIndexRecommend() {
      this.loading = true
      this.RecommendClassList = []
      selectPageListToIndexnew(
        6,
        1,

        undefined,
        this.search.kind,
        this.search.zyId,
        undefined,
        3, /* productLine 3专属线 */
        this.search.areaId == 1 ? undefined : this.search.areaId
      ).then((res) => {
        this.RecommendClassList = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    activeColor(item, chil, index, e) {
      e.target.classList.add('AB8C00')
      // if (index == 0) {
      //   e.target.classList.add('AB8C00')
      // }
      // if (index == 1) {
      //   e.target.classList.add('CFF7F38')
      // }
      // if (index == 2) {
      //   e.target.classList.add('CFFBA56')
      // }
      // if (index == 3) {
      //   e.target.classList.add('C55AFFF')
      // }
      // if (index == 4) {
      //   e.target.classList.add('C08B091')
      // }
    },
    removeColor(item, chil, index, e) {
      e.target.classList.remove('AB8C00')
      // if (index == 0) {
      //   e.target.classList.remove('CFF6559')
      // }
      // if (index == 1) {
      //   e.target.classList.remove('CFF7F38')
      // }
      // if (index == 2) {
      //   e.target.classList.remove('CFFBA56')
      // }
      // if (index == 3) {
      //   e.target.classList.remove('C55AFFF')
      // }
      // if (index == 4) {
      //   e.target.classList.remove('C08B091')
      // }
    },
    /* 点击进入专属班详情 */
    goDetails(item) {
      /* this.$router.push({
        path: "/VIP/VIPClassxq",
        query: {
          id: item.id,
        },
      }); */
      const routeUrl = this.$router.resolve({
        path: '/VIP/VIPClassxq',
        query: {
          id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    // 点击立即报名按钮 跳转支付页
    async zhifu(item) {

      if (this.userInfo) {
        const res = await getClassCourseListById(
          this.userInfo.id,
          3
        )

        res.rows.filter(items => {
          if (items.coursesId == item.id) {
            this.$message({
              message: '当前已拥有此课程，无法下单',
              type: 'error'
            })
            this.unPurchase = false
          }
        })
        if (this.unPurchase) {
          this.$router.push(`/orders/classOrder?classId=${item.id}`)
        } else {
          this.unPurchase = true
        }
      } else {
        Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.seventhOpen {
  /deep/ .el-loading-mask {
    background-color: #f5f5f5;
  }

  background-color: #f5f5f5;
  // background-color: #333;
  // margin-top: 48px;
  height: 704px;

  .recommend {
    width: 1200px;
    display: flex;
    margin: 0px auto 10px;
    justify-content: space-betweent;
    align-items: start;
    background-color: #f5f5f5;

    // background-color: #4a6af0;
    // 三角形
    .Up {
      width: 0;
      height: 0;
      margin-top: 8px;
      margin-left: 22px;
      border-bottom: 11px solid #f8f8f8;
      border-right: 9px solid transparent;
      border-left: 9px solid transparent;
    }

    // 推荐课程
    .contentWrapLeft {
      // margin: 0px 0 0px calc(50% - 612px) ;
      // border-radius: 8px 8px 8px 8px;
      // padding: 20px 0 0 24px;
      background-color: #f5f5f5;
      width: 894px;
      // padding: 8px;
      height: 704px;

      // margin: 0px auto 0;
      .queBox {
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .que {
          width: 112px;
        }

        p {
          // margin: auto;
          height: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #777777;
        }
      }

      .colleges-title {
        position: relative;
        margin: 30px 0 22px;
        display: flex;
        height: 26px;
        justify-content: start;
        width: 874px;

        //  background-color: red;
        .more {
          position: absolute;
          right: 15px;
          // margin-top:2px;
          margin-left: 15px;
        }

        .kind-list {
          width: 618px;
          margin-left: 70px;
          display: flex;
          height: 32px;
          overflow: hidden;

          .kind-item {
            // width: 80px;
            margin-right: 22px;
            margin-top: 5px;
            position: relative;
            cursor: pointer;
            line-height: 26px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            color: #333333;
            flex-shrink: 0;
          }

          .active {
            color: #00ab8c !important;

            .choiceLefts {
              position: absolute;
              bottom: -5px;
              left: calc(50% - 10px);
              width: 20px;
              height: 3px;
              background: #00ab8c;
              border-radius: 2px 2px 2px 2px;
            }
          }
        }

        .text {
          font-size: 24px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 0px;
        }
      }

      // display: flex;
      // justify-content: start;

      align-items: flex-start !important;
      flex-wrap: wrap;

      // padding:0 20px;
      .contentItem {
        float: left;
        width: 273px;
        height: 306px;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #eff1f4;
        cursor: pointer;
        margin: 0 22px 18px 0px;
        transition: all 500ms;

        &:hover {
          transform: translateY(-10px);

          .imgClass {
            transform: scale(1.1);
          }

          box-shadow: 0px 1px 7px 7px rgba(207, 207, 207, 0.1);
        }

        &:nth-child(3n-2) {
          margin-right: 0px;
        }

        .imgItem {
          width: 273px;
          height: 153.5px;
          border-radius: 8px 8px 0px 0px;
          overflow: hidden;
          opacity: 1;

          .imgClass {
            width: 100%;
            height: 153.5px;
            transition: all 500ms;
          }
        }

        .item_content {
          width: 273px;
          position: relative;
          display: flex;
          background-color: #fff;
          padding: 2px 0px 0px;
          flex-direction: column;
          justify-content: space-between;
          height: 156px;

          .titleItem {
            padding: 0 16px;
            max-height: 40px;
            font-size: 14px;
            margin-top: 8px;
            // margin-left: 10px;
            margin-bottom: 10px;
            // margin-right: 10px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }

          .tips {
            padding: 0 16px;
            flex: 1;
            display: flex;
            margin: auto 0;
            align-items: center;

            .fuwu {
              font-size: 12px;
              // margin-left: 9px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #cdaa8d;
              line-height: 12px;
            }

            .el-divider {
              margin: auto 8px;
              height: 8px;
              background: #999999;
            }

            .courseNum {
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 12px;
            }
          }

          .ter-list {
            display: flex;
            padding-top: 14px !important;
            height: auto !important;
            min-height: 80px;

            .ter-item {
              margin: 0 12px;
              width: 84px !important;

              .terbox {
                margin: 0 auto;
                width: 32px !important;
                height: 32px !important;
                background: #c4c4c4;
                opacity: 1;
                overflow: hidden;
                border-radius: 50%;
              }

              .ter-img {
                width: 32px;
              }

              .ter {
                margin: 0 auto;
                font-size: 12px;
                width: 36px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                line-height: 12px;
                margin-top: 8px;
                text-align: center;
              }
            }
          }

          .priceItem {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;

            .symbolOrprice {
              font-family: DIN BOLD;
              color: #ff5e51;
              font-size: 16px;
              font-weight: bold;
              line-height: 16px;

              .symbol {
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
              }
            }

            .fire {
              display: flex;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 12px;

              .fire-icon {
                width: 12px;
                height: 12px;
                flex-shrink: 0;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }

    // 考试功能入口
    .entrance {
      width: 306px;
      height: 706px;
      // background: url("~@/assets/img/homeSeventh/entranceBg.png");
      background-size: 100% 115%;
      // background-position: 5% 0;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      // margin-top: 64px;
      background-color: #f5f5f5;
      flex-shrink: 0;

      .text {
        width: 144px;
        height: 31px;
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        // line-height: 0px;
        background-color: #f5f5f5;
        margin: 30px 0px 19px 20px;
      }

      .entrance-box {
        padding: 0px 0px;
        height: 628px;
        overflow-y: scroll;
        border-radius: 8px;
        background-color: #fff;

        .item {
          position: relative;
          padding: 0px 18px 15px 18px;

          &:first-child {
            margin-top: 22px;
          }

          &:first-child {
            .tips-box {
              padding-top: 7px !important;

              .Union1Box {
                .iconfont {
                  top: 0;
                }
              }
            }
          }

          &:last-child {
            .tips-box {
              height: 100%;
              padding-bottom: 18px;
            }
          }

          .tips-box {
            position: absolute;
            top: 0px;
            left: 8px;
            bottom: 0px;
            height: 100%;
            margin-right: 32px;
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .Union1Box {
              position: relative;

              .iconfont {
                position: absolute;
                z-index: 1;
                left: -7px;
                top: 7px;
                font-size: 14px;
                height: 14px;
                background-color: #fff;
                color: #cccccc;
                font-style: 0;
              }
            }

            .line {
              width: 1px;
              height: 100%;
              border: 1px dashed #333;
              // background: #CCCCCC ;
              border-radius: 0px 0px 0px 0px;
              opacity: 0.2;
            }

            .lineLength {
              width: 1px;
              height: 100%;
              border: 1px dashed #333;
              // background: #CCCCCC ;
              border-radius: 0px 0px 0px 0px;
              opacity: 0.2;
            }

            .lineLengthno {
              width: 1px;
              height: 0%;
              border: 1px dashed #333;
              // background: #CCCCCC ;
              border-radius: 0px 0px 0px 0px;
              opacity: 0.2;
            }
          }

          .dis_flex {
            height: 30px !important;
          }

          .item-title {
            /*    background: url("~@/assets/img/homeSeventh/entranceIcon.png");
      background-size: 89px 100%; */
            // width: 83px;
            // background-image: url(~@/assets/img/homeSeventh/slices2.png);
            position: relative;
            height: 24px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            line-height: 24px;
            margin-bottom: 6px;
            // margin-left: 10px;
            cursor: pointer;

            // border-radius: 6px 0px 0px 6px;
            .menu-name {
              display: inline-block;
              font-size: 14px;
              letter-spacing: 10px;
              font-weight: bold;
              color: #00ab8c;
            }

            .menu-date {
              display: inline-block;
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }

          .item-title-img {
            width: 12px;
            height: 14px;
            vertical-align: middle;
            margin-top: -1px;
            margin-right: 5px;
          }

          .entrance-content {
            // display: flex;
            overflow: hidden;
            border-radius: 7px;
            // margin-left: 9px;
            padding: 14px 20px;
            width: 100%;
            background-color: #f8f8f8;
            overflow: hidden;

            .content-text {
              width: 67px;
              // flex: 1;
              float: left;
              background: #f8f8f8;
              overflow: hidden;
              // margin-right: 7px;
              // margin-bottom: 12px;
              /*  width: 80px; */
              // padding: 0 6px;
              // height: 26px;
              cursor: pointer;
              // border-radius: 20px 20px 20px 20px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #555555;
              line-height: 26px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-right: 14px;

              // cursor: pointer;
              &:nth-child(3n) {
                margin-right: 0px;
              }
            }

            .AB8C00 {
              color: #00ab8c;
            }

            .CFF6559 {
              color: #ff6559;
            }

            .CFF7F38 {
              color: #ff7f38;
            }

            .CFFBA56 {
              color: #ffba56;
            }

            .C55AFFF {
              color: #55afff;
            }

            .C08B091 {
              color: #08b091;
            }
          }
        }
      }

      .noexm {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 28px;
        text-align: center;
        margin-top: 200px;
      }
    }
  }

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  /deep/.el-carousel {
    .el-carousel__container {
      display: flex;
    }

    .el-carousel__item {
      width: 100px;
      flex-shrink: 0;
      padding: 0px 32px;
      height: 56px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      // position:data ;
    }
  }
}
</style>
<style lang="less">
.onClick-shiyong {
  .el-dropdown-menu__item {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pname {
    padding: 0px 16px;
  }

  .onClick {
    color: #5372f0 !important;
    background-color: #ecf5ff;
  }
}
