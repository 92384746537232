<template>
  <div class="wrap">
    <div class="top_box">
      <!-- 新闻热点 -->
      <div class="news">
        <div class="title_box">
          <span class="title">新闻热点</span>
          <span class="more" @click="goNext(`/allnews`)"><i class="iconfont icon-mulu"></i> 更多热点</span>
        </div>
        <div class="line">
          <div class="blue_line"></div>
        </div>
        <div class="main_box">
          <div class="img_box">
            <img @click="allnewxq(newsList[0])" class="img m_b_25" :src="imgList[0].img" alt="" />
            <img @click="allnewxq(newsList[1])" class="img" :src="imgList[1].img" alt="" />
          </div>
          <div class="news_list">
            <div class="news_item" v-for="(item, index) in newsList" :key="index" @click="allnewxq(item)">
              <div class="circular"></div>
              <div class="content">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 备考资料 -->
      <div class="data_box">
        <div class="title_box">
          <span class="title">备考资料</span>
        </div>
        <div class="line">
          <div class="blue_line"></div>
        </div>
        <div class="content_box">
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <div class="name_box">
              <div class="name ellipsis">{{ item.fName }}</div>
              <div class="fDesc ellipsis">{{ item.fDesc }}</div>
            </div>
            <div class="btn" @click="goMaterial(item)">查看</div>
          </div>
        </div>
      </div>
      <!-- 在线题库 -->
      <div class="data_box">
        <div class="title_box">
          <span class="title">在线题库</span>
        </div>
        <div class="line">
          <div class="blue_line"></div>
        </div>
        <div class="content_box">
          <div class="item" v-for="(item, index) in packageList" :key="index">
            <div class="name_box">
              <div class="name ellipsis">{{ item.fName }}</div>
              <div class="fDesc ellipsis">{{ item.fDesc }}</div>
            </div>
            <div class="btn" @click="goTakeAnExam(item)">做题</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_box">
      <div class="title_box">
        <div class="left_box">
          <div class="title">单科课程</div>
          <div class="kind_box">
            <div class="kind_item" :class="{ on: item.pKind == pKind }" v-for="(item, index) in collageList[0].kindList"
              :key="index" @click="changeKind(item)">
              {{ item.pName }}
            </div>
          </div>
        </div>
        <div class="more_btn">
          <span class="more" @click="goNext(`/jingpin/courer`)"><i class="iconfont icon-mulu"></i> 更多课程</span>
        </div>
      </div>
      <div class="table_box">
        <el-table class="table" :data="courseList" height="300" @selection-change="courseChange">
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="title" label="辅导课程" width="446" align="center"></el-table-column>
          <el-table-column prop="" label="授课老师" width="282" align="center">
            <template slot-scope="{ row }">
              <div class="user_box">
                <img class="img" :src="row.terImg" alt="" />
                <div class="ter_name">{{ row.terName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="课时" width="195" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.allTime }}h</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="价格" width="" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.entrantsPrice ? row.entrantsPrice : row.price }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="btn_box">
          <div class="price_box">
            <div class="tips">金额总计：</div>
            <div class="num_box">
              <div class="num">￥{{ getPrice }}</div>
              <!-- <dir class="tips_num">
                <span>优惠金额</span>
                <span class="num_two">{{ 50 }}</span>
                <span>元</span>
              </dir> -->
            </div>
          </div>
          <div class="btn" @click="addOrder">提交订单</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { queryAllArticle } from "@/api/home.js";
import { getDomain } from "@/api/cookies";
import { Classifi } from "@/api/classifi";
let classifi = new Classifi();
import Bus from "@/api/bus";
import { getInfo } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
export default {
  data() {
    return {
      collageList: null,
      time: null,
      newsList: [],
      imgList: [],
      dataList: [],
      entrantsType: null,
      packageList: [],
      courseList: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 0,
      },
      info: 0,
      pKind: null,
      currentCourse: [], // 当前已选课程,
    };
  },
  created() {
    this.userInfo = getInfo();
  },
  mounted() {
    this.entrantsType = getDomain().entrantsType;
    this.changeCollageList();
    this.queryAllArticle();
    this.getDocPackageFenlei();
    this.getPackageFenlei();
  },
  computed: {
    getPrice() {
      let totalPrice = 0;
      this.currentCourse.forEach((item) => {
        totalPrice += Number(item.price);
      });
      return totalPrice;
    },
  },
  methods: {
    queryAllArticle() {
      queryAllArticle(undefined, 11, 1).then((res) => {
        this.imgList = res.rows.slice(0, 3);
        this.newsList = res.rows.slice(2, 11);
      });
    },
    changeCollageList() {
      this.time = setInterval(() => {
        this.collageList = this.$store.state.collageList;
        this.info++;
        if (this.collageList || this.info > 5) {
          this.pKind = this.collageList[0]?.kindList[0]?.pKind;

          clearTimeout(this.time);
          this.getCourseList();
        }
      }, 1000);
    },
    // 资讯详情
    allnewxq(index) {
      let routeUrl = this.$router.resolve({
        name: "新闻资讯详情",
        params: { id: index.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 资料包分类
    getDocPackageFenlei() {
      classifi.getDocPackageFenlei().then((res) => {

        this.dataList = res.data;
      });
    },
    // 试卷包分类
    getPackageFenlei() {
      classifi.getPackageFenlei().then((res) => {

        this.packageList = res.data;
      });
    },
    // 单科课程
    getCourseList() {
      know
        .getCourseList(
          this.page.pageSize,
          this.page.pageNum,
          this.collageList[0]?.pKind,
          this.pKind
        )
        .then((res) => {

          this.courseList = res.rows;
          this.page.total = res.total;
        });
    },
    // 切换项目筛选单科课程
    changeKind(item) {
      this.pKind = item.pKind;
      this.getCourseList();
    },
    // 选择单科课程
    courseChange(val) {
      this.currentCourse = val;
    },
    addOrder() {
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (!this.userInfo) {
        Bus.$emit("toLoginPage", true);
        loading.close();
        return;
      }
      if (!this.currentCourse || this.currentCourse.length == 0) {
        this.$message({
          type: "error",
          message: "请选择课程",
        });
        loading.close();
        return;
      }
      let ids = this.currentCourse.map((item) => {
        return item.id;
      });
      let commodityName = this.currentCourse.map((item) => {
        return item.title;
      });
      let commodityType = this.currentCourse.map((item) => {
        switch (item.types) {
          case 1:
            item.type = 2;
            break;
          case 2:
            item.type = 3;
            break;
          case 3:
            item.type = 7;
            break;
          case 4:
            item.type = 8;
            break;
          case 5:
            item.type = 10;
            break;
        }
        return item.type;
      });
      orderClient
        .addOrders(
          ids,
          getInfo().id,
          commodityName,
          commodityType,
          this.currentCourse[0].title,
          Number(this.pKind)
        )
        .then((res) => {
          if (res.code == 0) {
            this.$router.push({
              name: "个人中心订单页",
              params: { orderNumber: res.msg },
            });
          } else {
            this.$message.error(res.msg);
          }
          loading.close();
        });
    },
    // 资料跳转
    goMaterial(item) {

      let routeUrl;
      switch (this.entrantsType) {
        case 1:
          routeUrl = this.$router.resolve({
            path: "/data/dataPackagesixth",
            query: {
              type: item.fId,
            },
          });
          break;
        case 2:
          routeUrl = this.$router.resolve({
            path: "/data/dataPackage",
            query: {
              type: item.fId,
            },
          });
          break;
        case 3:
          routeUrl = this.$router.resolve({
            path: "/seventhPage/dataPackage",
            query: {
              type: item.fId,
            },
          });
          break;
      }
      window.open(routeUrl.href, "_blank");
    },
    // 题库跳转
    goTakeAnExam(item) {
      let routeUrl;
      switch (this.entrantsType) {
        case 1:
          routeUrl = this.$router.resolve({
            path: "/quesTion/quesTionListsixth",
            query: {
              type: item.fId,
            },
          });
          break;
        case 2:
          routeUrl = this.$router.resolve({
            path: "/quesTion/quesTionList",
            query: {
              type: item.fId,
            },
          });
          break;
        case 3:
          routeUrl = this.$router.resolve({
            path: "/seventhPage/quesTionList",
            query: {
              type: item.fId,
            },
          });
          break;
      }
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 1200px;
  margin: auto;
}

.top_box {
  display: flex;
  padding: 30px 0;

  // 新闻热点
  .news {
    width: 598px;
    height: 420px;
    border-radius: 8px;
    background-color: #ffffff;
    margin-right: 16px;

    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px 21px 12px 32px;

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
      }

      .more {
        color: #777777;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .line {
      width: 545px;
      height: 1px;
      background-color: #e8e8e8;
      margin: 0 auto 22px auto;

      .blue_line {
        width: 42px;
        height: 2px;
        border: 1px;
        margin-left: 5px;
        background-color: #1061ff;
      }
    }

    .main_box {
      display: flex;

      .img_box {
        margin-left: 22px;
        margin-right: 34px;

        .img {
          width: 233px;
          height: 146px;
          border-radius: 8px;
        }

        .m_b_25 {
          margin-bottom: 25px;
        }
      }

      .news_list {
        height: 317px;
        width: 260px;
        padding-top: 9px;
        // overflow: auto;

        .news_item {
          margin-bottom: 15px;
          color: #333333;
          display: flex;
          align-items: center;
          cursor: pointer;

          .circular {
            width: 5px;
            height: 5px;
            border-radius: 2px;
            margin-right: 8px;
            background-color: #cecece;
          }

          .content {
            width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  // 备考资料
  .data_box {
    width: 284px;
    height: 420px;
    border-radius: 4px;
    margin-right: 16px;
    background-color: #ffffff;

    .title_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 19px 0 12px 15px;

      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 500;
      }

      .more {
        color: #777777;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .line {
      width: 268px;
      height: 1px;
      background-color: #e8e8e8;
      margin: 0 auto 17px auto;

      .blue_line {
        width: 42px;
        height: 2px;
        border: 1px;
        margin-left: 20px;
        background-color: #1061ff;
      }
    }

    .content_box {
      padding-left: 14px;
      padding-right: 11px;

      .item {
        margin-block: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name_box {
          .name {
            width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #343434;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }

          .fDesc {
            color: #666666;
            font-size: 12px;
            line-height: 17px;
          }

          .ellipsis {
            width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .btn {
          width: 64px;
          height: 27px;
          border: 1px solid #1061ff;
          border-radius: 18px;
          color: #1061ff;
          font-size: 14px;
          text-align: center;
          line-height: 27px;
          cursor: pointer;
        }
      }
    }
  }
}

.bottom_box {
  margin-bottom: 20px;

  .title_box {
    margin-block: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left_box {
      display: flex;
      align-items: center;

      .title {
        color: #333333;
        font-size: 22px;
        font-weight: 600;
        margin-right: 82px;
      }

      .kind_box {
        width: 900px;
        overflow: auto;
        display: flex;

        .kind_item {
          margin-right: 40px;
          flex-shrink: 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;
        }

        .on {
          color: #1061ff;
          font-weight: 600;
        }

        .on::after {
          width: 42px;
          height: 2px;
          border-radius: 1px;
          margin-left: 10px;
          margin-top: 8px;
          background-color: #1061ff;
          content: "";
        }
      }
    }

    .more_btn {
      color: #777777;
      font-size: 14px;
      font-weight: 500;

      .more {
        cursor: pointer;
      }
    }
  }

  .table_box {
    width: 1200px;
    height: 396px;
    border-radius: 12px;
    background-color: #ffffff;

    .table {
      margin-bottom: 21px;

      .user_box {
        display: flex;

        .img {
          width: 24px;
          height: 24px;
          border-radius: 12px;
          margin-right: 10px;
        }

        .ter_name {
          color: #555555;
          font-size: 12px;
        }
      }
    }

    .btn_box {
      display: flex;
      justify-content: flex-end;
      padding-right: 28px;

      .price_box {
        display: flex;
        color: #777777;
        font-size: 14px;
        margin-right: 17px;

        .tips {
          line-height: 30px;
        }

        .num_box {
          width: 100px;

          .num {
            color: #ff5e51;
            font-size: 22px;
            font-weight: 600;
          }

          .tips_num {
            font-size: 12px;

            .num_two {
              font-weight: normal;
              color: #ff5e51;
              font-size: 16px;
            }
          }
        }
      }
    }

    .btn {
      width: 140px;
      height: 50px;
      border-radius: 25px;
      background-color: #ff5e51;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
  }
}

::v-deep .el-table {
  border-radius: 12px;

  .el-table__header-wrapper th {
    background-color: #dee8f8;
    color: #444444;
    font-weight: 600;
  }
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
</style>