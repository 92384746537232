<template>
  <div class="bannerImg">
    <el-carousel height="420px" class="swImg" indicator-position="none">
      <el-carousel-item v-for="(item, index) in imgList" :key="index" class="pointer">
        <img :src="item.img" @click="nav(item)">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import swipers from '@/components/swipers.vue'
import { selectKindAllCollage, selectAcademyKindzyListnew, getSurfaceList } from '@/api/home'
import { getDomain } from '@/api/cookies'
export default {
  components: { swipers },
  data() {
    return {
      imgList: [],
      kindIndex: null,
      collageList: {},
      domain: null
    }
  },
  created() {
    this.domain = getDomain()
    getSurfaceList(1, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = res.msg
      }
    })
    this.loadgetTenantidNavigation() // 调用轮播图导航接口方法
  },
  methods: {
    // 轮播左侧内容点击跳转详情
    zhuanYeXqs(kind, zId, schoole) {
      /* this.$router.push({
        path: `/allcurriculum/typeclass?kind=${kind ? kind : ""}&zId=${zId ? zId : ""}&schoole=${
          schoole ? schoole : ""
        }`,
      }); */
      const routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?kind=${kind || ''}&zId=${zId || ''}&schoole=${schoole || ''
          }`
      })
      window.open(routeUrl.href, '_blank')
    },
    nav(item) {
      window.open(item.skipImg, '_blank')
    },
    // 调用轮播图导航接口方法
    loadgetTenantidNavigation() {
      const productLine = 4
      selectAcademyKindzyListnew(productLine).then((res) => {
        if (res.code == 0) {
          this.collageList = res.data
        } else {
          this.collageList = []
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.bannerImg {
  width: 100%;
  height: 420px;

  /* 轮播图 */
  /deep/.swImg {
    width: 100%;
    height: 420px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    .el-carousel__item {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    img {
      margin: auto;
      /* max-width: 100%; */
      height: 420px;
    }
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
