<template>
  <div class="two-box">
    <div class="teacher-view" v-if="teacherList && teacherList.length > 0">
      <!--标题 师资力量 -->
      <div class="til">
        <div>师资力量</div>
        <div @click="gos()">MORE ></div>
      </div>

      <div class="terbox">
        <div class="terf">
          <div></div>
          <el-image class="terImg" :src="teamsg.terImg" fit="cover"></el-image>
        </div>
        <div class="reamsg">
          <div>{{ teamsg.nickname }}</div>
          <div>{{ teamsg.jobTitle }}</div>
          <div></div>
          <div v-html="teamsg.profile"></div>
          <div @click="goDetails()" class="look">查看详情 ></div>
        </div>
        <div class="teapic">
          <div
            @click="goDet(item)"
            v-for="(item, index) in teacherList"
            :key="index"
            :class="item.isClick ? 'bor' : ''"
          >
            <img :src="item.terImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { TeacherClient } from '@/api/teacherClient'
let teacherClient = new TeacherClient()
export default {
  components: {},
  data() {
    return {
      total: '',
      teacherList: [],
      pageNum: 1,
      teamsg: {},
      uid: '',
    }
  },
  async created() {
    // 教师
    await teacherClient.teacherPageList(12, 1,undefined,1).then((res) => {
      if (res.rows) {
        for (let items of res.rows) {
          items.isClick = false
        }
        res.rows[0].isClick = true
        this.teamsg = res.rows[0]
        this.teacherList = res.rows
        this.total = res.total
      } else {
        this.teacherList = []
      }
    })
    // this.say();
  },
  methods: {
    gos() {
      this.$router.push(`/facultysixth`)
    },
    async getlist() {
      await teacherClient.teacherPageList(12, 1,undefined,1).then((res) => {
        if (res.rows) {
          for (let items of res.rows) {
            if (items.id == this.uid) {
              items.isClick = true
            } else {
              items.isClick = false
            }
          }
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    },
    goDet(item) {
      this.teamsg = item
      this.uid = item.id
      this.getlist()
    },
    goDetails() {
      let routeUrl = this.$router.resolve({
        path: `/faculty/teacherDetail`,
        query: {
          id: this.teamsg.id,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
    tonext(page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page,undefined,1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    },
    toprev(page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page,undefined,1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    },
    // say() {
    //   this.$nextTick(() => {
    //     var last = document.getElementById(
    //       `viesww${this.teacherList.length - 1}`
    //     );
    //     if (last) {
    //       let star = last.style.transform.indexOf("translateX(");
    //       let end = last.style.transform.indexOf("px)");
    //       let val = last.style.transform.substring(
    //         star + "translateX(".length,
    //         end
    //       );
    //       if (Number(val) < 1200 * 3) {
    //         this.$refs.nop.prev();
    //       }
    //       for (let i in this.teacherList) {
    //         var box = document.getElementById(`viesww${i}`);
    //         let starIndex = box.style.transform.indexOf("translateX(");
    //         let endIndex = box.style.transform.indexOf("px)");
    //         let nuw = box.style.transform.substring(
    //           starIndex + "translateX(".length,
    //           endIndex
    //         );
    //         let data = nuw ? Number(nuw) / 4 : null;
    //         if (data) {
    //           box.style.transform = `translateX(${data}px) scale(1) `;
    //           box.classList.add("is-animating");
    //         }
    //       }
    //     }
    //   });
    // },
  },
}
</script>
<style>
.el-popover {
  overflow: hidden;
  padding: 0px;
}
</style>
<style lang="less" scoped>
.two-box {
  width: 100%;
  background: #fff;
}
.teacher-view {
  width: 1220px;
  margin: 0 auto;
  padding-top: 32px;
  .til {
    width: 100%;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;

    justify-content: space-between;
    margin-bottom: 8px;
    > div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }
  .terbox {
    width: 100%;
    height: 440px;
    background: url('~@/assets/img/homesixth/Group 4043.png');
    margin-top: 28px;
    display: flex;
    justify-content: start;
    .terf {
      position: relative;
      width: 246px;
      height: 360px;
      // background: url('~@/assets/img/homesixth/Group 4041.png');
      opacity: 1;
      margin-top: 40px;
      margin-left: 60px;

      div {
        position: absolute;
        width: 246px;
        height: 364px;
        background: url('~@/assets/img/homesixth/Group 4041.png');
        top: -2px;
        left: -3px;
      }
      .terImg {
        width: 240px;
        height: 360px;
        border-radius: 8px;
      }
    }
    .reamsg {
      div {
        margin-left: 20px;
      }
      > div:nth-child(1) {
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-top: 80px;
      }
      > div:nth-child(2) {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin-top: 20px;
      }
      > div:nth-child(3) {
        width: 20px;
        height: 3px;
        background: #ffbe5e;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-top: 40px;
      }
      > div:nth-child(4) {
        width: 400px;
        height: 130px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 26px;
        overflow: hidden;
        margin-top: 20px;
      }
      > div:nth-child(5) {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffbe5e;
        margin-top: 16px;
        cursor: pointer;
      }
    }
    .teapic {
      width: 400px;
      // height: 280px;
      margin-top: 104px;
      height: fit-content;
      margin-left: 56px;
      display: flex;
      justify-content: flex-start;
      align-items: start;
      flex-wrap: wrap;
      div {
        position: relative;
        width: 80px;
        border-radius: 4px 4px 4px 4px;
        height: 80px;
        margin-bottom: 20px;
        margin-right: 20px;
        cursor: pointer;
        overflow: hidden;
        img {
          width: 76px;
          // height: 76px;
          border-radius: 4px 4px 4px 4px;
        }
      }
      .bor {
        border: 2px solid #ffb242;
      }
    }
  }
}
</style>
