<!--
 * @Description:
 * @Version: 2.0
 * @Author: 隐世浮华
 * @Date: 2023-08-14 10:28:58
 * @LastEditors: 隐世浮华
 * @LastEditTime: 2023-08-18 09:03:53
 * @FilePath: \edub_tob_pc\src\components\homeSeventh\bannerMenuNew.vue
-->
<template>
  <div class="navs">
    <template v-for="(item, index) in list">
      <div v-if="index <= 3" :key="index" class="item" @click="gopage(item.link)">
        <img class="item-img" :src="item.img">
      </div>
    </template>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import { getSelectPcConfiguration } from '@/api/home'
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      await getSelectPcConfiguration().then((res) => {

        this.list = res.jinGangDistricts
      })
    },
    gopage(path) {
      const userInfo = getInfo()
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false)
        return
      }
      /* this.$router.push(path); */
      window.open(path, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
// 五图片
.navs {
  // width: 65%;
  position: relative;
  transform: translatex(-50%);
  left: 50%;
  width: 1200px;
  z-index: 3;
  overflow: hidden;
  margin: 30px auto 34px 0 !important;

  .item {
    float: left;
    width: 285px;
    height: 100px;
    margin-right: 19px;

    &-img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.item-img {
  cursor: pointer;
}</style>
