<template>
  <div class="navs">
    <div v-for="(item, index) in list" :key="index" @click="gopage(item.url)" class="item">
      <!-- <img class="bg-img" src="@/assets/img/homeFourth/bgImg.png" /> -->
      <div class="item-content">
        <div class="item-title">{{ item.text }}</div>
        <div class="item-more">查看更多&nbsp;></div>
      </div>
      <img class="item-img" :src="item.image" />
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [
        {
          text: '直播课',
          image: require('@/assets/img/homeFourth/menu1.png'),
          url: '/zhiboclass',
        },
        {
          text: '知识套餐',
          image: require('@/assets/img/homeFourth/menu2.png'),
          url: '/allcurriculum/typeclass',
        },
        {
          text: '精品课程',
          image: require('@/assets/img/homeFourth/menu6.png'),
          url: '/jingpin/courer',
        },
        {
          text: 'VIP专属',
          image: require('@/assets/img/homeFourth/menu5.png'),
          url: '/VIP/VIPClass',
        },
        {
          text: '学习资料',
          image: require('@/assets/img/homeFourth/menu4.png'),
          url: '/data/dataPackage',
        },
        {
          text: '题库',
          image: require('@/assets/img/homeFourth/menu3.png'),
          url: '/quesTion/quesTionList',
        },
      ],
    }
  },
  created() {
    this.userInfo = getInfo()
  },
  methods: {
    gopage(path) {
      let userInfo = getInfo()
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false)
        return
      }
      /* this.$router.push(path); */
      window.open(path, '_blank')
    },
  },
}
</script>
<style lang="less" scoped>
// 五图片
.navs {
  // width: 65%;
  width: 1220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto 40px;
  .item {
    width: 180px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.10000000149011612);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    cursor: pointer;
    background: linear-gradient(90deg, #ffffff 0%, #fff2f2 100%);
    .bg-img {
      position: absolute;
      left: 0px;
      width: 100%;
    }
    .item-content {
      width: 100%;
      .item-title {
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
      }
      .item-more {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-top: 14px;
      }
    }
    .item-img {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
    }
  }
}
</style>
