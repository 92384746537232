<template>
  <div class="navs-box">
    <div class="navs">
      <div
        v-for="(item, index) in list"
        :key="index"
        @click="gopage(item.url)"
        class="item"
      >
        <img class="item-img" :src="item.image" />
        <div class="item-title">
          <p class="p_txt">{{ item.text }}</p>
          <p class="p_til">{{ item.til }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [
        // {
        //   text: "训练营",
        //   image: require("@/assets/img/camp/camp.png"),
        //   url: "/camp",
        //   til: "训练营",
        // },
        {
          text: "知识套餐",
          image: require("@/assets/img/homesixth/Frame 6.png"),
          url: "/allcurriculum/typeclasssixth",
          til: "五大辅导类型课",
        },
        {
          text: "直播课",
          image: require("@/assets/img/homesixth/Frame 7.png"),
          url: "/zhiboclasssixth",
          til: "在线互动及答疑",
        },
        {
          text: "VIP专属",
          image: require("@/assets/img/homesixth/Frame 8.png"),
          url: "/VIP/VIPClasssixth",
          til: "享五大专属服务",
        },
        {
          text: "学习资料",
          image: require("@/assets/img/homesixth/Frame 9.png"),
          url: "/data/dataPackagesixth",
          til: "各讲师精心编制",
        },

        {
          text: "题库",
          image: require("@/assets/img/homesixth/Frame 10.png"),
          url: "/quesTion/quesTionListsixth",
          til: "历年的真题考题",
        },
        {
          text: "优惠券",
          image: require("@/assets/img/homesixth/Frame 11.png"),
          url: "/mysixth/mineCoupon",
          til: "购课立享大折扣",
        },
        {
          text: "精品课程",
          image: require("@/assets/img/homesixth/Frame 12.png"),
          url: "/jingpin/courersixth",
          til: "优质的辅导课程",
        },
      ],
    };
  },
  created() {
    this.userInfo = getInfo();
  },
  methods: {
    gopage(path) {
      let userInfo = getInfo();
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false);
        return;
      }
      /* this.$router.push(path); */
      window.open(path, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.navs-box {
  // background: #fff;
  width: 100%;
  // margin: 20px 0;
}
// 五图片
.navs {
  // width: 65%;
  width: 1220px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 157px;
    height: 80px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;

    .item-img {
      width: 44px;
      height: 44px;
      margin-right: 4px;
    }
    .item-title {
      font-size: 20px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      .p_txt {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .p_til {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 8px;
      }
    }
  }
}
</style>
