<template>
  <div class="two-box">
    <div v-if="teacherList && teacherList.length > 0" class="teacher-view">
      <!--标题 师资力量 -->
      <div class="title">
        <p><slot>师资力量</slot></p>
        <!-- <span>查看更多&nbsp;></span> -->
        <el-pagination
          class="redPage"
          :current-page="pageNum"
          :page-size="6"
          layout="prev, next"
          :total="total"
          @prev-click="toprev"
          @next-click="tonext"
        />
      </div>
      <div class="carousel">
        <!-- <el-carousel
        class="carousel-item"
        height="158px"
        loopedSlides="4"
        :autoplay="false"
        :loop="false"
        indicator-position="none"
        @change="say()"
        ref="nop"
        arrow="always"
      > -->
        <div v-for="(item, index) in teacherList" :id="`viesww${index}`" :key="index" class="swiperItem" @click="goDetails(item)">
          <!-- hover -->
          <!-- 列表 -->
          <div class="teachers">
            <el-image class="terImg" :src="item.terImg" fit="cover" />
            <div class="teachersRight">
              <div class="itemBox">
                <div class="teacher-info">
                  <!-- <img src="@/assets/img/Home/2824.png" alt="" /> -->
                  <span class="tName elp">{{ item.nickname }}</span>
                </div>
                <div class="teachersRightb">
                  <span /><!-- <span>IT大神</span><span>实力爆棚</span> -->
                </div>
                <div class="desc_box">
                  <div class="teacher-tips-desc">{{ item.graduateName }}</div>
                </div>
                <div class="teacher-tips" :title="item.tDetail">
                  <div v-html="item.profile" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </el-carousel> -->
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { TeacherClient } from '@/api/teacherClient'
const teacherClient = new TeacherClient()
export default {
  components: {},
  data() {
    return {
      total: '',
      teacherList: [],
      pageNum: 1
    }
  },
  async created() {
    // 教师
    await teacherClient.teacherPageList(6, 1, undefined, 1).then((res) => {
      if (res.rows) {
        this.teacherList = res.rows
        this.total = res.total
      } else {
        this.teacherList = []
      }
    })
    // this.say();
  },
  methods: {
    goDetails(item) {
      const routeUrl = this.$router.resolve({
        path: `/faculty/teacherDetail`,
        query: {
          id: item.id
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    tonext(page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page, undefined, 1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    },
    toprev(page) {
      this.pageNum = page
      teacherClient.teacherPageList(6, page, undefined, 1).then((res) => {
        if (res.rows) {
          this.teacherList = res.rows
          this.total = res.total
        } else {
          this.teacherList = []
        }
      })
    }
    // say() {
    //   this.$nextTick(() => {
    //     var last = document.getElementById(
    //       `viesww${this.teacherList.length - 1}`
    //     );
    //     if (last) {
    //       let star = last.style.transform.indexOf("translateX(");
    //       let end = last.style.transform.indexOf("px)");
    //       let val = last.style.transform.substring(
    //         star + "translateX(".length,
    //         end
    //       );
    //       if (Number(val) < 1200 * 3) {
    //         this.$refs.nop.prev();
    //       }
    //       for (let i in this.teacherList) {
    //         var box = document.getElementById(`viesww${i}`);
    //         let starIndex = box.style.transform.indexOf("translateX(");
    //         let endIndex = box.style.transform.indexOf("px)");
    //         let nuw = box.style.transform.substring(
    //           starIndex + "translateX(".length,
    //           endIndex
    //         );
    //         let data = nuw ? Number(nuw) / 4 : null;
    //         if (data) {
    //           box.style.transform = `translateX(${data}px) scale(1) `;
    //           box.classList.add("is-animating");
    //         }
    //       }
    //     }
    //   });
    // },
  }
}
</script>
<style>
.el-popover {
  overflow: hidden;
  padding: 0px;
}
</style>
<style lang="less" scoped>
.two-box {
  width: 100%;
  background: #fff;
}
.teacher-view {
  padding-top: 32px;
}
.nickname {
  padding: 20px 0px;
}
// 标题 最新公开课
.title {
  // width: 65%;
  width: 1200px;
  margin: auto;
  height: 23px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 24px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  span {
    width: auto;
    height: auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a8abbe;
    cursor: pointer;
  }
}
// 师资力量  内容
.teacher {
  // width: 65%;
  width: 1200px;
  margin: auto;
  height: 158px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 8px;
}
.carousel {
  display: flex;
  flex-wrap: wrap;
  width: calc(1220px + 25px);
  margin: auto;
  margin-left: calc(50% - 610px);
  margin-bottom: 30px;
  overflow: hidden;
  // justify-content: space-between;
  .swiperItem{
    margin-right: 25px;
    width: 390px;
  }
  .carousel-item {
    /deep/.el-carousel__container {
      .el-carousel__item {
        width: 25%;
      }
    }
  }
}
/* 讲师列表 */
.teachers {
  position: relative;
  z-index: 22;
  // width: 300px;
  // height: 158px;
  // background: linear-gradient(90deg, #f1f3f2 39%, #fffeff 100%);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  .terImg {
    position: relative;
    z-index: 22;
    flex-shrink: 0;
    width: 150px;
    height: 225px;
    border-radius: 4px 4px 4px 4px;
  }
  .teachersRight {
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 11;
    width: 358px;
    height: 225px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eeeeee;
    .teacher-info {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;
      img {
        width: 4px;
        height: 15px;
        flex-shrink: 0;
      }
      .tName {
        width: 100%;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #27323f;
        text-align: left;
      }
    }
    .teachersRightb {
      width: 120px;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      margin-left: 18px;
      span {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #27323f;
      }
    }
    .teacher-tips {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      width: auto;
      height: 61px;
      margin-top: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000;
    }
    .teacher-tips-desc {
      max-width: 196px;
      /* width: auto; */
      height: auto;
      margin-top: 4px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff5d51;
      padding: 4px 8px;
      border-radius: 4px;
      background: #fff0ef;
      margin-right: 20px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    .teachersRightd {
      width: 100px;
      height: 26px;
      background: #ffffff;
      border: 1px solid #d0baa9;
      border-radius: 13px;
      margin-top: 10px;
      margin-left: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #896952;
      }
      img {
        width: 15px;
        height: 11px;
        margin-left: 5px;
      }
    }
  }
}

/* 弹窗 */
.popover {
  width: 100%;
  height: 438px;
  background: #f2f3f3;
  box-shadow: -2px 6px 49px 0px rgba(155, 155, 155, 0.31);
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0px 41px 0px 16px;
  position: relative;
  overflow: hidden;
  .icon {
    width: 100px;
    height: 100px;
    background: #a68362;
    transform: rotate(38deg);
    position: absolute;
    top: -59px;
    right: -53px;
  }
  .terImg {
    width: 304px;
    height: 382px;
    margin-right: 43px;
    flex-shrink: 0;
  }
  .teachersRight {
    overflow: hidden;
    height: 382px;
    width: 100%;
    padding-top: 54px;
    overflow-y: scroll;
    .teacher-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .info-icon {
        flex-shrink: 0;
        width: 6px;
        height: 26px;
        background: linear-gradient(0deg, #a5815f 0%, #eceae5 100%);
        border-radius: 3px;
        margin-right: 18px;
      }
      .tName {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #27323f;
      }
      .tAge {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #525962;
        flex-shrink: 0;
      }
    }
    .teach-kc {
      margin-top: 33px;
      line-height: 28px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #27323f;
    }
    .teach-content {
      margin-top: 56px;
      width: 550px;
      font-size: 20px;
      line-height: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
.swiperItem {
  width: 32%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  // margin-right: 10px;
}
.itemBox {
  margin-left: 138px;
}
.desc_box {
  margin-bottom: 16px;
  display: flex;
}
</style>
