<template>
  <!--  课程推荐-->
  <div class="recommend">
    <!--标题  新闻资讯  -->
    <div
      class="title"
      style="margin-top: 20px"
      v-if="newsList && newsList.length > 0"
    >
      <p><slot>新闻资讯</slot></p>
      <span @click="allnews">查看更多&nbsp;></span>
    </div>
    <!--新闻资讯  内容  -->
    <div class="Journalism" v-if="newsList && newsList.length > 0">
      <!-- 模块 1 -->
      <div class="Journalisms" v-for="(item, index) in newsList" :key="index">
        <!-- 图片 -->
        <div class="JournalismsImg" @click="allnewxq(item)">
          <img :src="item.img" />
          <!-- <router-link :to="'/newsxq?id=' + item.id"></router-link> -->
        </div>
        <div class="Journalismsa">
          <div class="JournalismsaLeft"></div>
          <div class="JournalismsaRight">
            <div v-html="item.title"></div>
          </div>
        </div>
        <div class="Journalismsb">
          <div v-html="ImgSizeLimit(item.content)"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/common.css";
import { queryAllArticle } from "@/api/home";
export default {
  components: {},
  data() {
    return {
      newsList: "", //新闻
    };
  },
  created() {
    this.loadqueryAllArticle(); // 调用新闻资讯接口方法
  },
  methods: {
    // 调用新闻资讯接口方法
    loadqueryAllArticle() {
      queryAllArticle("", 4, 1).then((res) => {
        this.newsList = res.rows;
      });
    },
    // 查看更多新闻资讯按钮
    allnews() {
      /* this.$router.push({ path: "/allnews" }); */
      let routeUrl = this.$router.resolve({
        path: "/allnews"
      })
      window.open(routeUrl.href, '_blank')
    },
    // 查看单个新闻资讯详情按钮
    allnewxq(index) {
      /* this.$router.push({ name: "新闻资讯详情", params: { id: index.id } }); */
      let routeUrl = this.$router.resolve({
        name: "新闻资讯详情", 
        params: { id: index.id }
      })
      window.open(routeUrl.href, '_blank')
    },
  },
};
</script>
<style lang="less" scoped>
// 标题 最新公开课
.title {
  // width: 65%;
  width: 1200px;
  margin: auto;
  height: 23px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    width: auto;
    height: auto;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #27323f;
    cursor: pointer;
  }
  span {
    width: auto;
    height: auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #a8abbe;
    cursor: pointer;
  }
}

// 课程推荐
.recommend {
  width: 100%;
  // height: 1450px;
  height: auto;
  background: #f9f9f9;
  overflow: hidden;

  // 新闻资讯  内容
  .Journalism {
    // width: 65%;
    width: 1200px;
    margin: auto;
    margin-bottom: 60px;
    height: 260px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // 模块
    .Journalisms {
      width: 24%;
      height: 270px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(237, 239, 242, 0.7);
      margin-right: 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0px;
      }
      // 图片
      .JournalismsImg {
        width: 100%;
        height: 140px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .Journalismsa {
        width: 240px;
        /*  height: 40px; */
        margin: auto;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .JournalismsaLeft {
          width: 2px;
          height: 39px;
          background: linear-gradient(0deg, #ff4027 0%, #fd5d0f 100%);
          margin-right: 10px;
        }
        .JournalismsaRight {
          width: 200px;
          /*  height: 40px; */
          p {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #27323f;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      .Journalismsb {
        width: 240px;
        // height: 38px;
        margin: auto;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #7b8196;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
