<template>
  <div class="product">
    <!-- 公开课 -->
    <div class="til">
      <div class="title">
        试卷题库
        <img src="@/assets/img/partner/corner.png" alt="" />
      </div>
      <div @click="saveImg('/partnerPage/quesTionList')">
        查看更多
        <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
      </div>
    </div>
    <div class="product_content">
      <div class="product_content_imgBox">
        <img src="@/assets/img/partner/product4.png" alt="" />
      </div>
      <div @click="goPage(item)" class="product_content_item" v-for="(item, index) in list" :key="index">
        <div class="product_content_item_img">
          <img src="@/assets/img/partner/paper.png" alt="" />
          <div class="title twoEllipsis">{{ item.name }}</div>
          <div class="kindName">
            {{ item.kindName }}
            <!-- 丨{{ item.courseName }} -->
          </div>
        </div>
        <div class="product_content_item_title">
          <div v-for="(row, ind) in item.paperNameList" :key="ind" class="dis_flex_start" v-show="ind < 2">
            <img src="@/assets/img/partner/doc2.png" alt="" />
            <div style="width: 210px" class="elp">
              {{ row.name.substring(0, 19) }}
            </div>
          </div>
        </div>
        <div class="product_content_item_price">
          <div class="count">
            共<span style="color: #1d81f5">{{ item.paperNameList ? item.paperNameList.length : 0 }}套</span>试卷
          </div>
          <el-button class="btn" @click="goPage(item)">去购买</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import Vue from 'vue'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import { getInfo } from '@/api/cookies'
export default {
  components: {},
  data() {
    return {
      list: [], // 公开课
      userInfo: null
    }
  },
  async created() {
    this.userInfo = await getInfo()
    this.getList()
  },
  methods: {

    async getList() {
      try {
        const res = await answerClient.getNewTenantPackageListToPc(6, 1)
        this.list = res.rows
      } catch (error) {

      }
    },
    getPackageDetails(val) {
      const that = this
      if (this.userInfo) {
        dataClient
          .getPackageDetails(10000, 1, val.id, this.userInfo.id)
          .then((res) => {

            this.$set(val, 'packageList', res.rows)
          })
      }
    },
    goPage(row, column, cell, event) {
      if (this.userInfo) {
        this.$router.push(`/all/examPackPartner?packId=${row.id}`)
      } else {
        this.$notify({
          title: '错误',
          message: '未登录，请去登录',
          type: 'error'
        })
        // Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.product {
  margin-bottom: 15px;
  width: 1200px;
  margin: 0 auto 30px;
  background-color: #f5f5f5;

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  /deep/.el-carousel {
    .el-carousel__container {
      display: flex;
    }

    .el-carousel__item {
      width: 100px;
      flex-shrink: 0;
      padding: 0px 32px;
      height: 56px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      // position:data ;
    }
  }

  .til {
    width: 1200px;
    margin: 30px auto 22px;
    height: 42px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    display: flex;
    justify-content: space-between;

    .title {
      position: relative;

      img {
        width: 10px;
        position: absolute;
        right: -8px;
        bottom: 0;
      }
    }

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .product_content {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;

    .product_content_imgBox {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      width: 586px;
      height: 300px;
      overflow: hidden;
      margin-right: 26px;

      img {
        width: 107%;
        height: 106%;
      }
    }

    .product_content_item {
      width: 280px;
      height: 300px;
      padding: 10px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-right: 26px;
      margin-top: 24px;
      cursor: pointer;

      &:nth-child(4n-1) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: 0;
      }

      .product_content_item_img {
        position: relative;
        width: 260px;
        height: 146px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .title {
          position: absolute;
          z-index: 9;
          left: 21px;
          top: 28px;
          width: 203px;
          height: 44px;
          line-height: 22px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        .kindName {
          position: absolute;
          z-index: 9;
          left: 21px;
          top: 82px;
          height: 17px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #90939a;
        }
      }

      .product_content_item_title {
        width: 250px;
        height: 48px;
        margin: 24px auto;

        div {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin: 0 0 14px;
          height: 17px;

          img {
            display: inline-block;
            width: 11.5px;
            margin-bottom: 2px;
            vertical-align: middle;
            margin-right: 4px;
          }
        }

        :last-child {
          margin: 0px;
        }
      }

      .product_content_item_tips {
        margin-bottom: 15px;

        .types {
          display: inline-block;
          // width: 24px;
          // height: 17px;
          padding: 1px 8px;
          margin-right: 10px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1c81f5;
        }

        span {
          // height: 17px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #777a82;
        }
      }

      .product_content_item_price {
        margin: 0 auto;
        // width: 244px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .count {
          height: 16px;
          line-height: 16px;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 84px;
          height: 30px;
          background: #ffffff;
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          border: 1px solid #1d81f5;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1d81f5;

          &:hover {
            width: 84px;
            height: 30px;
            background: linear-gradient(180deg, #6cadf8 0%, #1c81f5 100%);
            box-shadow: 0px 3px 12px 1px rgba(28, 129, 245, 0.34);
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            color: #ffffff;
            border: 0;

          }
        }
      }
    }
  }
}
</style>
