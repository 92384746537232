<template>
  <div class="navs">
    <div
      v-for="(item, index) in list"
      :key="index"
      @click="gopage(item.url)"
      class="item"
    >
      <img class="bg-img" src="@/assets/img/homeFifth/bgImg.png" />
      <div class="detail">
        <img class="item-img" :src="item.image" />
        <div class="item-content">
          <div class="item-title">{{ item.text }}</div>
          <div class="item-more">查看更多&nbsp;></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [
        // {
        //   text: "训练营",
        //   image: require("@/assets/img/camp/camp.png"),
        //   url: "/camp",
        // },
        {
          text: "直播课",
          image: require("@/assets/img/homeFifth/menu1.png"),
          url: "/zhiboclass",
        },
        {
          text: "知识套餐",
          image: require("@/assets/img/homeFifth/menu2.png"),
          url: "/allcurriculum/typeclass",
        },
        {
          text: "精品课程",
          image: require("@/assets/img/homeFifth/menu6.png"),
          url: "/jingpin/courer",
        },
        {
          text: "VIP专属",
          image: require("@/assets/img/homeFifth/menu3.png"),
          url: "/VIP/VIPClass",
        },
        {
          text: "学习资料",
          image: require("@/assets/img/homeFifth/menu4.png"),
          url: "/data/dataPackage",
        },
        {
          text: "题库",
          image: require("@/assets/img/homeFifth/menu5.png"),
          url: "/quesTion/quesTionList",
        },
      ],
    };
  },
  created() {
    this.userInfo = getInfo();
  },
  methods: {
    gopage(path) {
      let userInfo = getInfo();
      if (!userInfo && path == `/my/mineCoupon`) {
        Vue.prototype.goLoginView(false);
        return;
      }
      /* this.$router.push(path); */
      window.open(path, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
// 五图片
.navs {
  // width: 65%;
  width: 1220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto 30px;
  .item {
    width: 180px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.10000000149011612);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    .bg-img {
      position: absolute;
      left: 0px;
      width: 100%;
      z-index: 1;
    }
    .detail {
      z-index: 10;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      .item-content {
        width: 100%;
        .item-title {
          font-size: 20px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 20px;
        }
        .item-more {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 14px;
          margin-top: 14px;
        }
      }
      .item-img {
        width: 56px;
        height: 56px;
        flex-shrink: 0;
        margin-right: 20px;
      }
    }
  }
}
</style>
