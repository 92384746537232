<template>
  <div class="bannerImg">
    <!-- <div style="height: 0.1px;"></div> -->
    <!-- <div class="bgimg">
      <img :src="imgUrl" alt="">
    </div> -->

    <!--  <div class="swipersRight"><swipers :imgList="imgList"></swipers></div> -->
    <div class="banner_main">
      <div class="banner_main_left">
        <el-carousel height="380px" class="swImg" arrow="never">
          <el-carousel-item v-for="(item, index) in imgList" :key="index" class="pointer">
            <img :src="item.img" @click="nav(item)">
          </el-carousel-item>
        </el-carousel>
        <div class="jg"
          v-if="collageList.length == 1 && ((htmlVal[0].id == 5002 && ZyLists.length > 2) || (htmlVal[0].id == 5001 && ZyLists.length > 2))">
          <div @click="goNext('/partnerPage/courer')" class="jg_item">
            <div class="jg_item_left">
              <img src="~@/assets/img/TechnicalCollege/105.png" alt="">
            </div>
            <div class="jg_item_right">
              <div>精品课程</div>
              <div>优质的辅导课程</div>
            </div>
          </div>
          <div @click="goNext('/partnerPage/VIPClass')" class="jg_item">
            <div class="jg_item_left">
              <img src="~@/assets/img/TechnicalCollege/104.png" alt="">

            </div>
            <div class="jg_item_right">
              <div>知识套餐</div>
              <div>五大辅导类型课</div>
            </div>
          </div>
          <div @click="goNext('/partnerPage/dataPackage')" class="jg_item">
            <div class="jg_item_left">
              <img src="~@/assets/img/TechnicalCollege/103.png" alt="">

            </div>
            <div class="jg_item_right">
              <div>学习资料</div>
              <div>各讲师精心编制</div>
            </div>
          </div>
          <div @click="goNext('/partnerPage/quesTionList')" class="jg_item">
            <div class="jg_item_left">
              <img src="~@/assets/img/TechnicalCollege/102.png" alt="">

            </div>
            <div class="jg_item_right">
              <div>试卷题库</div>
              <div>历年考试真题</div>
            </div>
          </div>
        </div>
        <!-- 二、三个大类 -->
        <div @mouseleave="leaveCollage()" v-if="(collageList.length > 1 && collageList.length < 4)" class="swipers">
          <div class="list">
            <div v-for="(item, index) in collageList" :key="index" class="college_name" :class="{
              on: currentCollage.pKind && currentCollage.pKind == item.pKind,
            }" @mouseenter="changeCollege(item)" @click="zhuanYeXqs(undefined, undefined, item.pKind)">
              {{ item.pName }}
            </div>
          </div>
          <div v-show="mainBoxShow" class="content_box">
            <div v-for="(item, index) in currentCollage ? currentCollage.kindList : []" :key="index"
              class="project_item" :class="{ m_t_13: index != 0 }">
              <div v-if="item.pKind != 1" class="project_title" @click="projectDetail(item)">
                {{ item.pName }}
              </div>
              <template v-if="item.pKind != 1">
                <div class="zy_box">
                  <div v-for="(zy, i) in item.zyList" :key="i" class="zy_item"
                    @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                    {{ zy.zName == '成人高考' ? zy.zStage : zy.zName }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="item.pKind == 1" class="project_title" @click="projectDetail(item)">
                  自学考试辅导（专科）
                </div>
                <div class="zy_box">
                  <div v-for="(zy, i) in item.zyList" v-show="zy.zStage == '高起专'" :key="i" class="zy_item"
                    @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                    {{ zy.zName == '成人高考' ? zy.zStage : zy.zName }}
                  </div>
                </div>
                <div v-if="item.pKind == 1" class="project_title" @click="projectDetail(item)">
                  自学考试辅导（本科）
                </div>
                <div class="zy_box">
                  <div v-for="(zy, i) in item.zyList" v-show="zy.zStage == '专升本'" :key="i" class="zy_item"
                    @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                    {{ zy.zName == '成人高考' ? zy.zStage : zy.zName }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- 四到七个大类 -->
        <div v-if="collageList.length > 3" class="fourSwipres" @mouseenter="mouseenter(false)"
          @mouseleave="mouseleave(false)">
          <div class="list" :class="{
            p_t_18: collageList.length > 3,
          }">
            <div v-for="(item, index) in collageList" :key="index" class="college_box" @mouseenter="changeCollege(item)"
              @click="zhuanYeXqs(undefined, undefined, item.pKind)">
              <div class="college_name">
                <span>{{ item.pName }}</span>
                <div class="triangle" />
              </div>
              <div class="course_box" :class="{
                h_52: collageList.length == 4,
                h_26: collageList.length > 4,
              }">
                <div v-for="(course, i) in item.courseList" :key="i" class="course_item"
                  @click="zhuanYeXqs(course.zKind, course.zId, currentCollage.pKind)">
                  {{ course.zName }}
                </div>
              </div>
            </div>
          </div>
          <div v-show="mainBoxShow" ref="mainBox" class="main_box">
            <div v-for="(item, index) in currentCollage.kindList" :key="index" class="project_item"
              :class="{ m_t_10: index != 0 }">
              <div v-if="item.pKind != 1" class="project_name" @click="projectDetail(item)">
                {{ item.pName }}
              </div>
              <template v-if="item.pKind != 1">
                <div class="zy_box">
                  <div v-for="zy in item.zyList" :key="zy.zId" class="zy_item"
                    @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                    {{ zy.zName }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-if="item.pKind == 1" class="project_name" @click="projectDetail(item)">
                  自学考试辅导（专科）
                </div>
                <div class="zy_box">
                  <div v-for="zy in item.zyList" v-show="zy.zStage == '高起专'" :key="zy.zId" class="zy_item"
                    @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                    {{ zy.zName }}
                  </div>
                </div>
                <div v-if="item.pKind == 1" class="project_name" @click="projectDetail(item)">
                  自学考试辅导（本科）
                </div>
                <div class="zy_box">
                  <div v-for="zy in item.zyList" v-show="zy.zStage == '专升本'" :key="zy.zId" class="zy_item"
                    @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                    {{ zy.zName }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>


      </div>
      <div class="banner_main_right">
        <div v-if="isLoginPC == 1" class="login">
          <div class="line"></div>
          <p>欢迎来到</p>
          <p>{{ domain.name }}</p>
          <div class="l_img">
            <img src="~@/assets/img/TechnicalCollege/99.png" alt="">

          </div>
          <div @click="isLoginPC = 2" class="contentTopBtn">去登录</div>

        </div>
        <div v-if="isLoginPC == 2" class="login">
          <div class="line"></div>
          <p>欢迎登录</p>
          <p>{{ domain.name }}</p>
          <el-form style="margin-top: 32px;" :model="ruleForma" :rules="rules" ref="passwordLogin">
            <el-form-item prop="username">
              <el-input placeholder="账号" prefix-icon="el-icon-user" v-model.trim="ruleForma.username" />
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="密码" prefix-icon="el-icon-lock" v-model.trim="ruleForma.password" show-password
                @keyup.enter.native="submit" />
            </el-form-item>
          </el-form>
          <div @click="submit" class="contentTopBtn">登录</div>
        </div>
        <div v-if="isLoginPC == 3" class="login">
          <div class="line"></div>
          <p>我的学习</p>
          <div style="margin-top: 70px;" class="l_img">
            <img style="width: 120px;" src="~@/assets/img/TechnicalCollege/100.png" alt="">
          </div>
          <div class="l_til">您还未购买课程，请购买</div>
          <div @click="goNext('/partnerPage/VIPClass')" class="contentTopBtn">立即购课</div>

        </div>
        <div v-if="isLoginPC == 4" class="login">
          <div class="line"></div>
          <p>我的学习</p>
          <div style="margin-top: 32px;" class="l_img">
            <img style="width: 145px;" src="~@/assets/img/TechnicalCollege/101.png" alt="">
          </div>
          <div class="l_til">点击 <span>去学习</span> 可立即学习</div>
          <div @click="goNext('/partnerPage/mycurriculum')" class="contentTopBtn">去学习</div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import '@/assets/css/common.css'
import { liveEnum } from '@/api/emun'
import swipers from '@/components/swipers.vue'
import { selectKindAllCollage, selectAcademyKindzyListnew, getMYTenantKindZyList, getSurfaceList, selectPcList, getStore } from '@/api/home'
import { loginPcUser, getMClassCourseListNum, loginPcUserSms } from "@/api/login";
import { getInfo } from '@/api/cookies'

import { getDomain } from '@/api/cookies'
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: { swipers },
  data() {
    return {
      liveEnum: liveEnum,
      imgList: [],
      htmlVal: '',
      kindIndex: null,
      collageList: {},
      domain: null,
      imgUrl: '',
      collageId: null,
      // kindList: [],
      currentCollage: {},
      currentKind: {},
      ZyLists: [],
      mainBoxShow: false,

      firstMouseEnterKind: true,
      userSource: "PC端",
      isLoginPC: "1",
      recommendList: [],
      menuList: [],
      twoMenuList: {},
      ruleForma: {},
      rules: {
        username: [
          { required: true, message: "账号不能为空", trigger: "blur" },
        ],

        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 6, message: "密码不能长度小于6个字符", trigger: "blur" },
        ],

      },

    }
  },
  async created() {
    this.domain = getDomain()
    this.getMYTenantKindZyList()
    getStore(this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.htmlVal = JSON.parse(res.data.json)

      }
    })
    this.userInfo = await getInfo()
    if (this.userInfo) {
      getMClassCourseListNum().then((res) => {

        if (res.code == 0) {
          if (res.data > 0) {
            this.isLoginPC = 4
          } else {
            this.isLoginPC = 3
          }
        } else {
          this.isLoginPC = 3
        }
      })
    }
    getSurfaceList(1, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = JSON.parse(JSON.stringify(res.msg))
        this.imgList.shift()
        this.imgUrl = res.msg[0].img
        const bannerImg = document.getElementsByClassName('bannerImg');
        bannerImg[0].style.background = `url(${res.msg[0].img}) `;
        bannerImg[0].style.backgroundSize = "100% 100%"; // 可选：设置背景图片大小适应
        bannerImg[0].style.backgroundRepeat = "no-repeat"; // 可选：设置背景图片不重复

        // const overlay = document.createElement('div');
        // overlay.style.position = 'absolute';
        // overlay.style.top = '0';
        // overlay.style.left = '0';
        // overlay.style.width = '100%';
        // overlay.style.height = '100%';
        // overlay.style.background = `linear-gradient( rgba(255, 255, 255, 0),rgba(255, 255, 255, 1))`;
        // bannerImg[0].appendChild(overlay);

      }
    })
    this.loadgetTenantidNavigation() // 调用轮播图导航接口方法
  },
  methods: {
    // 项目专业
    getMYTenantKindZyList() {
      getMYTenantKindZyList().then(res => {
        if (res.data) {
          this.ZyLists = res.data
        } else {
          this.ZyLists = []
        }

      })

    },
    // 轮播左侧内容点击跳转详情
    zhuanYeXqs(kind, zId, schoole) {
      /* this.$router.push({
        path: `/allcurriculum/typeclass?kind=${kind ? kind : ""}&zId=${zId ? zId : ""}&schoole=${
          schoole ? schoole : ""
        }`,
      }); */
      const routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?kind=${kind || ''}&zId=${zId || ''}&schoole=${schoole || ''
          }`
      })
      window.open(routeUrl.href, '_blank')
    },
    // 用户名密码登录
    submit() {
      this.$refs.passwordLogin.validate(async (valid) => {
        if (valid) {
          loginPcUser(
            this.ruleForma.username,
            this.ruleForma.password,
            this.userSource
          ).then((res) => {
            if (res.code == 0) {
              getMClassCourseListNum().then((res) => {
                if (res.code == 0) {
                  if (res.data > 0) {
                    this.isLoginPC = 4
                  } else {
                    this.isLoginPC = 3
                  }
                } else {
                  this.isLoginPC = 3
                }
                // window.location.reload();
              })
            }
            else {
              this.$notify({
                title: '错误',
                message: res.msg,
                type: 'error'
              })
              //  this.$message.error(res.msg);
            }
          });
        }
      });
    },
    projectDetail(item) {
      this.$router.push({
        path: `/projectChannelPage`,
        query: {
          id: item.pKind,
          schoolId: item.pParentid
        }
      })
    },

    // 调用轮播图导航接口方法
    loadgetTenantidNavigation() {
      const productLine = 4
      selectAcademyKindzyListnew().then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            if (!item.kindList) return
            item.courseList = []
            item.kindList.forEach((i) => {
              if (!i.zyList) return
              item.courseList.push(...i.zyList)
            })
          })

          this.collageList = res.data

          // 只有一个学院时 数据存到vuex中
          if (res.data.length == 1) {
            this.$store.commit('SET_COLLAGELIST', this.collageList)
          }

          this.collageId = res.data[0].pKind
          this.currentCollage = res.data[0]

          if (res.data.length == 1 && res.data[0].kindList) {
            switch (res.data[0].kindList.length) {
              case 4:
                this.getCourseList(1)
                break

              case 3:
                this.getCourseList(3)
                break

              case 2:
                this.getCourseList(4)
                break

              case 1:
                if (this.currentCollage.pKind == 7196) {
                  this.getCourseList(3)
                  this.selectPcList()
                } else {
                  this.getCourseList(6)
                }
                break

              default:
                break
            }
          }
        } else {
          this.collageList = []
        }
      })
    },
    getCourseList(num) {
      know.getCourseListnew(num, 1, undefined, this.currentCollage.pKind).then((res) => {

        this.recommendList = res.rows
      })
    },

    // 项目报考信息列表
    selectPcList() {
      const data = {
        kind: this.currentCollage?.kindList[0]?.pKind
      }
      selectPcList(data).then((res) => {
        if (res.data.length > 5) {
          res.data = res.data.slice(0, 5)
        }

        this.menuList = res.data
      })
    },
    nav(item) {
      window.open(item.skipImg, '_blank')
    },

    // 鼠标移入项目（一个大类）
    changeKind(item) {

      this.currentKind = item
    },

    // 鼠标移入 （公考学院）
    menuChange(item) {
      this.twoMenuList = item
    },
    // 点击菜单跳转
    goPracticalInformation(i, item) {
      const routeUrl = this.$router.resolve({
        path: '/projectChannelPage/menuContent',
        query: {
          kind: this.pKind,
          menuOneId: i ? i.id : item.parentId,
          menuTwoId: item ? item.id : null
        }
      })
      window.open(routeUrl.href, '_blank')
    },

    // 鼠标移入学院 (二个大类以上)
    changeCollege(item) {
      /* this.collageId = item.pKind;
      this.kindList = item.kindList */
      this.currentCollage = item
      this.mainBoxShow = true
    },
    leaveCollage() {
      this.currentCollage = {}
      this.mainBoxShow = false
    },
    oneMouseenter() {
      this.mainBoxShow = true
      if (!this.firstMouseEnterKind) return
      this.$nextTick(() => {
        const kindBox = this.$refs.kindBox
        const one = document.querySelector('.one')
        one.style.minHeight = '292px'
        one.style.height = `${kindBox.offsetHeight}px`
        if (kindBox.offsetHeight == one.offsetHeight) {
          this.firstMouseEnterKind = false
        }
      })
    },

    oneMouseleave() {
      this.mainBoxShow = false
    },

    // 八个以上大类移入
    mouseenter(info) {
      this.mainBoxShow = true
      if (!info) return
      this.$nextTick(() => {
        const moreSwipres = document.querySelector('.moreSwipres')
        const list = document.querySelector('.list')
        const college_box = list.querySelectorAll('.college_box')
        const dis_none = list.querySelectorAll('.dis_none')

        // college_box.forEach((item) => {
        //   item.style.flex = "auto";
        //   item.style.height = "57.41px";
        // });
        dis_none.forEach((item) => {
          item.style.display = 'block'
        })
        // moreSwipres.style.width = '660px'

        const mainBox = this.$refs.mainBox
        const listDom = this.$refs.listDom
        mainBox.style.height = `${listDom.offsetHeight}px`
      })
    },
    // 八个以上大类移出
    mouseleave(info) {
      this.mainBoxShow = false
      if (!info) return
      const mainBox = this.$refs.mainBox
      const list = document.querySelector('.list')
      const dis_none = list.querySelectorAll('.dis_none')
      dis_none.forEach((item) => {
        item.style.display = 'block'
      })
      mainBox.style.height = '100%'
    },

    // 一个大类四个以上项目移入
    moveInKind(info) {
      this.mainBoxShow = true
      if (!info) return
      this.$nextTick(() => {
        const moreSwipres = document.querySelector('.one-swipers_more_kind')
        const list = document.querySelector('.left_box')
        const college_box = list.querySelectorAll('.kind_item')
        const dis_none = list.querySelectorAll('.dis_none')

        /* college_box.forEach((item) => {
          item.style.flex = "auto";
          item.style.height = "57.41px";
        }); */
        dis_none.forEach((item) => {
          item.style.display = 'flex'
        })
        // moreSwipres.style.width = '660px'

        const mainBox = this.$refs.mainBox
        const listDom = this.$refs.listDom
        mainBox.style.height = `${listDom.offsetHeight}px`
      })
    },
    // 一个大类四个以上项目移出
    moveOutKind(info) {
      this.mainBoxShow = false
      if (!info) return
      const mainBox = this.$refs.mainBox
      const list = document.querySelector('.left_box')
      const dis_none = list.querySelectorAll('.dis_none')
      const moreSwipres = document.querySelector('.one-swipers_more_kind')

      dis_none.forEach((item) => {
        item.style.display = 'none'
      })
      mainBox.style.height = '380px'
      // moreSwipres.style.width = '320px'
    },

    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    toNext(url) {
      const routeUrl = this.$router.resolve({
        path: url
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.bannerImg {
  width: 100%;
  height: 450px;
  position: relative;
  padding-top: 20px;

  .bgimg {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;

      transition: opacity 0.5s;

      // &:hover {
      //   opacity: 0.5;
      //   /* 鼠标悬停时改变透明度 */
      // }
    }


  }

  // 盒子右
  .swipersRight {
    width: 100%;
    height: 380px;
    cursor: pointer;
  }
}

.banner_main {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  // margin-top: 20px;

  .banner_main_left {
    width: 920px;
    height: 380px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;


    .jg {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 80px;
      background: #FFFFFF;
      border-radius: 0px 0px 10px 10px;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 52px;

      .jg_item {
        display: flex;
        align-items: center;
        cursor: pointer;

        .jg_item_left {
          width: 40px;

          img {
            width: 40px;
          }
        }

        .jg_item_right {
          margin-left: 8px;

          >div:nth-child(1) {

            font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }

          >div:nth-child(2) {

            font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            // margin-top: 5px;
          }
        }
      }
    }
  }

  .banner_main_right {
    width: 270px;
    height: 380px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    position: relative;

    .login {
      // width: 170px;
      position: relative;

      .line {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 17px;
        background: linear-gradient(76deg, #0270E8 0%, rgba(33, 180, 245, 0) 100%);
        border-radius: 9px 9px 9px 9px;
        opacity: 0.74;
      }

      p {
        position: relative;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        padding-left: 10px;

      }

      .contentTopBtn {
        width: 230px;
        height: 44px;
        background: #1C81F5;
        box-shadow: 0px 3px 6px 1px rgba(28, 129, 245, 0.5);
        border-radius: 24px 24px 24px 24px;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
      }

      .l_img {
        margin-top: 50px;

        img {
          display: block;
          margin: 0 auto;
          width: 84px;
        }
      }

      .l_til {

        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        text-align: center;
        margin-top: 15px;

        span {
          color: #1C81F5;
        }
      }
    }
  }
}

/* 轮播图 */
/deep/.swImg {
  width: 100%;
  height: 380px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  .el-carousel__item {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .el-carousel__arrow--left {
    left: calc(50% - 610px + 240px + 40px);
  }

  .el-carousel__arrow--right {
    right: 350px;
  }

  .el-carousel__arrow {
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.4);

    i {
      font-size: 30px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  img {
    /* max-width: 100%; */
    height: 380px;
    margin: auto;
  }
}

// 一个大类四个项目以下
.oneSwipers {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  // left: calc(50% - 610px);
  z-index: 1000;

  .left_box {
    position: absolute;
    top: 0px;
    bottom: 0px;
    height: 100%;
    left: 0;
    width: 240px;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;

    .college_name {
      height: 57px;
      // height: 140px;
      padding: 11px 0 0 11px;
      border-left: 4px solid #ffffff;
      position: relative;
      margin-bottom: 10px;
      cursor: pointer;

      .pName {
        color: #444444;
        font-weight: 500;
        line-height: 22px;
        // margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 190px;
        font-family: PINGFANG BOLD;
      }

      .kind_box {
        display: flex;
        color: #777777;
        font-size: 14px;
        line-height: 20px;
        font-family: PINGFANG REGULAR;

        .kind_item {
          display: flex;
          align-items: center;
        }
      }

      .triangle {
        margin-top: 4px;
        width: 0;
        height: 0;
        border-left: 8px solid #8ac1f8;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        right: 15px;
        top: 24px;
      }
    }

    .border_bto {
      padding-bottom: 18px;
      margin-bottom: 0;
    }

    .line {
      background-color: #f2f2f2;
      height: 1px;
      margin-top: 5px;
      margin-bottom: 17px;
    }

    .college_name:hover {
      color: #1061ff;
      background-color: #f3f7ff;
      border-left: 4px solid #1061ff;
    }

    .entrance_box {
      padding-left: 15px;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 15px;
      padding-bottom: 17px;

      .top {
        margin-bottom: 17px;
      }

      .top,
      .bottom {
        display: flex;
        justify-content: space-between;

        .entrance_item {
          display: flex;
          width: 50%;
          font-family: PINGFANG REGULAR;
          cursor: pointer;

          .icon {
            margin-right: 6px;
            width: 24px;
            height: 24px;
          }

          .title {
            color: #555555;
            // line-height: 21px;
          }
        }
      }
    }

    .recommend {
      padding-left: 10px;

      .recommend_item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .tag {
          width: 32px;
          height: 22px;
          background-color: #ff5e51;
          color: #ffffff;
          border-radius: 12px;
          text-align: center;
          line-height: 22px;
          font-size: 14px;
          margin-right: 9px;
        }

        .title {
          width: 170px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #555555;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .right_box {
    .one {
      width: 444px;
      padding-bottom: 20px;

      .title {
        color: #444444;
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        margin-bottom: 13px;
      }

      .title_box {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .title_block {
          width: 6px;
          height: 20px;
          background: linear-gradient(191deg, #88c8ff 0%, #1061ff 100%);
          margin-right: 8px;
        }

        .menu_title {
          color: #444444;
          font-size: 18px;
          line-height: 25px;
          font-weight: 600;
          font-family: PINGFANG BOLD;
        }
      }

      // 公考学院
      .menu_box {
        margin-left: 14px !important;
        flex-wrap: wrap;

        .recommend {
          .recommend_item {
            display: flex;
            justify-content: space-between;

            .name_box {
              display: flex;

              .tag {
                background-color: #ff5e51;
                color: #ffffff;
                border-radius: 12px;
                font-size: 14px;
                width: 32px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                margin-right: 8px;
                font-family: PINGFANG BOLD;
              }

              .title {
                width: 280px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #555555;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                font-family: PINGFANG MEDIUM;
              }
            }

            .tags_box {
              height: 20px;
              padding: 0 9px;
              border-radius: 11px;
              border: 1px #b4ccfc solid;
              font-size: 12px;
              line-height: 20px;
              color: #1061ff;
              font-family: PINGFANG REGULAR;
            }
          }
        }

        ::v-deep .el-divider {
          margin: 0;
          margin-bottom: 22px;
          width: 90%;
        }
      }

      .zy_box {
        display: flex;
        flex-wrap: wrap;
        color: #666666;
        line-height: 22px;
        margin-left: 22px;

        .zy_item {
          padding: 10px 15px;
          flex-shrink: 0;
          color: #444444;
          font-size: 14px;
          box-sizing: border-box;
          border: #ffffff 1px solid;
        }

        .zy_item:hover {
          color: #1061ff;
          border: #1061ff 1px solid;
          background-color: #e2ecff;
          border-radius: 3px;
        }

        .menu_item {
          border: 1px solid #1061ff;
          border-radius: 15px;
          padding: 5px 12px;
          flex-shrink: 0;
          color: #1061ff;
          font-size: 14px;
          line-height: 20px;
          box-sizing: border-box;
          flex-shrink: 0;
          margin-right: 30px;
          margin-bottom: 22px;
          font-family: PINGFANG REGULAR;
          // border: #ffffff 1px solid;
        }
      }
    }
  }
}

// 一个大类多个项目
.one-swipers_more_kind {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  // left: calc(50% - 610px);
  z-index: 1000;

  .left_box {
    width: 320px;
    min-height: 380px;
    background-color: rgba(0, 0, 0, 0.65);

    .kind_item {
      color: #ffffff;
      font-weight: 500;
      // margin-bottom: 22px;
      height: 46px;
      padding-left: 16px;
      line-height: 46px;
      display: flex;
      position: relative;

      .kind_name,
      .dis_none {
        max-width: 144px;
        flex-shrink: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        cursor: pointer;
      }

      .major_name {
        width: 128px;
        flex-shrink: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #cfcfcf;
      }

      .triangle {
        margin-top: 4px;
        width: 0;
        height: 0;
        border-left: 8px solid #8ac1f8;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }

    .kind_item:hover {
      background-color: rgba(255, 255, 255, 0.28);
    }

    .dis_none {
      display: none;
    }
  }

  .main_box {
    left: 320px;

    .kind_name {
      color: #333333;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .zy_box {
      display: flex;

      .zy_item {
        padding: 10px 15px;
        flex-shrink: 0;
        color: #444444;
        font-size: 14px;
        box-sizing: border-box;
        border: #ffffff 1px solid;
      }

      .zy_item:hover {
        color: #1061ff;
        border: #1061ff 1px solid;
        background-color: #e2ecff;
        border-radius: 3px;
      }
    }
  }
}

// 二、三个大类
.swipers {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 380px;
  // left: calc(50% - 610px);
  z-index: 1000;
  cursor: pointer;
  display: flex;

  .list {
    height: 100%;
    width: 66px;
    display: flex;
    flex-direction: column;
    // margin-right: 1px;

    .college_name {
      font-weight: bold;
      color: #ffffff;
      padding-left: 23px;
      height: auto;
      // margin-bottom: 1px;
      background-color: rgba(58, 58, 58, 0.5);
      flex: 1;
      writing-mode: vertical-lr;
      text-align: center;
      font-family: PINGFANG BOLD;
    }

    .on {
      background-color: #3662ec;
    }
  }

  .content_box {
    width: 620px;
    height: 100%;
    padding-top: 23px;
    padding-left: 14px;
    background: #FFFFFF;
    overflow: auto;
    border-radius: 0 10px 10px 0;

    .project_item {
      .project_title {
        margin-bottom: 9px;
        color: #333333;
        font-weight: bold;
        font-family: PINGFANG BOLD;
      }

      .zy_box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;

        .zy_item {
          flex-shrink: 0;
          color: #666666;
          font-size: 14px;
          padding: 7px 11px;
          margin-bottom: 5px;
          box-sizing: border-box;
          // border: solid 1px #ffffff;
          padding: 6px 10px;
          font-family: PINGFANG;
          // font-family: PINGFANG BOLD;
        }

        .zy_item:hover {
          color: #1061ff;
          // background-color: #e2ecff;
          // border: solid 1px #1061ff;
          // border-radius: 3px;
        }
      }
    }

    .m_t_13 {
      margin-top: 13px;
    }
  }
}

// 四到七个大类
.fourSwipres {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  // left: calc(50% - 610px);
  z-index: 1000;
  cursor: pointer;

  .list {
    width: 186px;
    height: 100%;
    background-color: rgba(58, 58, 58, 0.5);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    &:hover {
      background-color: rgba(58, 58, 58, 0.5);
    }

    .college_box {
      padding: 18px;
      // padding: 10px 19px 9px 18px;
      flex: 1;

      .college_name {
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .triangle {
          margin-top: 11px;
          width: 0;
          height: 0;
          border-left: 8px solid #dcdcdc;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }

      .course_box {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        .course_item {
          flex-shrink: 0;
          font-size: 12px;
          color: #dcdcdc;
          margin-right: 1em;
          margin-bottom: 10px;
        }
      }

      .h_26 {
        height: 28px;
      }

      .h_52 {
        height: 28px;
      }

      &:hover {
        background: #1C81F5;
      }
    }
  }

  .p_t_28 {
    // padding: 28px 19px 0 24px;
  }

  // .p_t_18 {
  //   padding: 18px 19px 0 18px !important;
  // }
}



.main_box {
  position: absolute;
  top: 0px;
  left: 186px;
  bottom: 0px;
  height: 100%;
  z-index: 1000;
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  width: 669px;
  padding: 25px 0 0 30px;
  overflow: auto;
  border-radius: 0 10px 10px 0;

  .project_item {
    .project_name {
      color: #333333;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .zy_box {
      display: flex;
      flex-wrap: wrap;

      .zy_item {
        padding: 10px 15px;
        flex-shrink: 0;
        color: #444444;
        font-size: 14px;
        box-sizing: border-box;
        // border: #ffffff 1px solid;
      }

      .zy_item:hover {
        color: #1061ff;
        // border: #1061ff 1px solid;
        // background-color: #e2ecff;
        // border-radius: 3px;
      }
    }
  }

  .m_t_10 {
    margin-top: 10px !important;
  }
}

.el-input {
  width: 230px;
  height: 44px;
  background: #F8F8F8 !important;
  border-radius: 23px 23px 23px 23px;
}

::v-deep .el-input__inner {
  background: #F8F8F8 !important;
  border-radius: 23px 23px 23px 23px;

}

::v-deep .el-form-item__content {
  background: #F8F8F8 !important;
  border-radius: 23px 23px 23px 23px;


}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
