<template>
  <div class="tuijian">
    <!--  课程推荐-->
    <div class="flex-between-c schoole-box">
      <div class="recommend">
        <div class="schoole" :class="college.pKind == nav.pKind ? 'onCol' : ''" @click="collegeClick(nav)"
          v-for="(nav, index) in collegeList" :key="index">
          {{ nav.pName }}
        </div>
      </div>
      <span class="more shrink0" @click="goCourseList">查看更多&nbsp;></span>
    </div>

    <!--标题 课程推荐 -->
    <div class="title">
      <div class="choiceLeft" v-for="(kind, index2) in college.kindList" :key="index2" v-if="index2 < 5">
        <div class="choiceLeftText" @click="handleNav(kind)" :class="kindId == kind.pKind ? 'on' : ''">
          {{ kind.pName }}
        </div>
        <div class="choiceLefts" v-show="kindId == kind.pKind"></div>
      </div>
    </div>
    <!-- 课程推荐  内容 -->
    <div class="collegeBWrap">
      <div class="firstImgBox">
        <img v-if="college.pKind == 4" src="@/assets/img/Home/zhuan-ben.png" alt="" />
        <img v-else-if="college.pKind == 16" src="@/assets/img/Home/jian-zhu.png" alt="" />
        <img v-else-if="college.pKind == 13" src="@/assets/img/Home/yi-yao.png" alt="" />
        <img v-else-if="college.pKind == 18" src="@/assets/img/Home/zhi-ye.png" alt="" />
        <img v-else-if="college.pKind == 17" src="@/assets/img/Home/cai-hui.png" alt="" />
        <img v-else-if="college.pKind == 55" src="@/assets/img/Home/kao_yan.png" alt="" />
        <img v-else-if="college.pKind == 11" src="@/assets/img/Home/jin_rong.png" alt="" />
        <img v-else-if="college.pKind == 7196" src="@/assets/img/Home/gong_kao.png" alt="" />
        <img v-else src="@/assets/img/Home/other.png" alt="" />
        <span :style="dealColor(college.pKind)" @click="banxingkes(college.pKind, kindId)">查看更多套餐</span>
      </div>
      <div class="recommendes" v-for="(item, index) in list" :key="index" @click.stop="banxingke(item)">
        <img :src="item.img" alt="" />
        <div class="allTime">共{{ item.num }}门课程</div>
        <div class="recommendesa twoEllipsis">{{ item.tiitle }}</div>
        <div class="recommendesb" v-if="item.curForms && item.curForms.length > 0">
          <div class="contetn" v-for="(curForms, curindex) in item.curForms" :key="curindex">
            <div class="data-icon" v-if="curindex != 0"></div>
            {{ curForms }}
          </div>
        </div>
        <div class="recommendesc">
          <span>人气值{{ item.clicks ? item.clicks : 0 }}</span>
          <div>
            <span class="lastPrice">
              <span style="font-weight: 400; color: red; font-size: 14px">￥</span>{{ item.price }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { selectPageListToIndex, selectPageListToIndexnew, selectAcademyKindzyList, selectAcademyKindzyListnew } from '@/api/home'
import { selectCoursePages } from '@/api/shaixuan'
export default {
  components: {},
  data() {
    return {
      list: [], //课程推荐
      collegeList: [],
      college: {}, //当前选择
      kindId: {},
    }
  },
  created() {
    this.getSchool() // 调用所有学院分类
  },
  methods: {
    goCourseList() {
      let routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass`,
      })
      window.open(routeUrl.href, '_blank')
    },
    // 调用所有学院分类
    async getSchool() {
      await selectAcademyKindzyListnew().then((res) => {
        this.collegeList = res.data
        this.collegeClick(res.data[0])
      })

      this.$forceUpdate()
    },
    /* 选择学yuan */
    collegeClick(college) {
      this.college = college
      this.handleNav(college.kindList[0])
      this.$forceUpdate()
    },
    async handleNav(kind) {
      this.kindId = kind.pKind
      this.getList()
      this.$forceUpdate()
    },
    // 调用课程推荐接口方法
    getList() {
      selectPageListToIndexnew(11, 1, undefined, this.kindId).then((res) => {
        this.list = res.rows
        for (let cur of this.list) {
          if (cur.curForms) {
            cur.curForms = cur.curForms.split(',')
          } else {
            cur.curForms = []
          }
        }
      })
    },

    dealColor(item) {
      if (item == 4) {
        return 'color: #1e78ff'
      } else if (item == 13) {
        return 'color: #369F5A'
      } else if (item == 16) {
        return 'color: #346CFF'
      } else if (item == 17) {
        return 'color: #E38800'
      } else if (item == 18) {
        return 'color: #FF657C'
      } else if (item == 11) {
        return 'color: #FF6B00'
      } else if (item == 55) {
        return 'color: #5445FF'
      } else {
        return 'color: #DE4A4A'
      }
    },
    // 查看更多知识套餐课按钮
    banxingkes(schoole, kind) {
      // this.$router.push(`/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`);
      let routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?schoole=${schoole}&kind=${kind}`,
      })
      window.open(routeUrl.href, '_blank')
    },

    // 知识套餐课详情
    banxingke(item) {
      /* this.$router.push({
        path: "/typeclassxq",
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      }); */
      let routeUrl = this.$router.resolve({
        path: '/typeclassxq',
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.price,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>
<style lang="less" scoped>
.tuijian {
  width: 1220px;
  margin-left: calc(50% - 610px);
}

.more {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  cursor: pointer;
}

// 课程推荐
.schoole-box {
  width: 100%;

  .recommend {
    width: 100%;
    height: auto;
    margin: auto;
    overflow-x: scroll;

    .schoole {
      display: inline-block;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 40px;
      padding: 0px 18px;
      cursor: pointer;
      background: none;
    }

    .onCol {
      background: #ff5e51;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
    }
  }
}

// 标题 最新公开课
.title {
  width: 100%;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 32px;

  .choiceLeft {
    position: relative;
    padding: 0px 16px 11px;
    margin-left: 8px;
    cursor: pointer;
    font-size: 14px;

    .on {
      color: #ff5e51;
    }

    .choiceLefts {
      position: absolute;
      bottom: 0px;
      left: calc(50% - 15px);
      width: 30px;
      height: 3px;
      background: linear-gradient(90deg, #ff5e51 0%, #ff5400 100%);
      border-radius: 2px;
    }
  }
}

.collegeBWrap {
  width: calc(1220px + 24px);
  display: flex;
  flex-wrap: wrap;

  // 单个模块
  .recommendes {
    width: 287px;
    height: 319px;
    background: #ffffff;
    margin-bottom: 24px;
    margin-right: 24px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;

    .allTime {
      position: absolute;
      height: 30px;
      line-height: 30px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px 4px 4px 4px;
      padding: 0px 12px;
      right: 10px;
      top: 121px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }

    // 图片
    img {
      width: 100%;
      height: 161px;
      border-radius: 8px 8px 0px 0px;
    }

    .recommendesa {
      height: 48px;
      margin: 12px 16px;
      font-size: 16px;
      line-height: 24px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
    }

    .recommendesb {
      height: 20px;
      background: #fff2e9;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      margin: 0px 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ff7b1c;
      line-height: 20px;
      padding: 0px 8px;
      display: inline-flex;

      .contetn {
        display: flex;
        align-items: center;
      }

      .data-icon {
        background: #ff7b1c;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        margin: 0px 4px;
      }
    }

    .recommendesc {
      width: 100%;
      margin: 27px 0px 0px;
      padding: 0px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;

      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .lastPrice {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ff4027;
      }
    }
  }

  .firstImgBox {
    position: relative;
    height: 319px;
    width: 287px;
    margin-bottom: 24px;
    margin-right: 24px;

    img {
      height: 319px;
      width: 287px;
    }

    span {
      width: 160px;
      height: 40px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 0.6;
      // color: #1e78ff;
      cursor: pointer;
      position: absolute;
      bottom: 35px;
      left: 20%;
      text-align: center;
      line-height: 40px;
    }
  }
}

.minW80 {
  min-width: 80px;
}

.moreInfomation {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  border-top: 1px solid #eee;
  padding: 16px 0;
  margin-top: 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: #fff;
}

.cacelPrice {
  font-size: 16px !important;
  text-decoration: line-through;
}

.foxRightCen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 130px;

  width: 100%;
  // min-height: 580px;
  // padding: 180px 0px 20px;
  // background: #ffffff;
  margin: 50px 0;

  .foxRightCenImg {
    // margin: auto;
    text-align: center;

    // margin-bottom: 54px;
    img {
      width: 92px;
      height: 91px;
    }
  }

  p {
    // margin: auto;
    text-align: center;
    width: auto;
    height: auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;
  }
}

.point {
  width: 5px;
  height: 5px;
  background-color: #ff7b1c;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
}

.ml5 {
  margin-left: 5px;
}

.icon {
  position: absolute;
  bottom: 0px;
  left: 23px;
  width: 18px;
  height: 6px;
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
