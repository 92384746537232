<template>
  <div class="bannerImg">
    <!--  <div class="swipersRight"><swipers :imgList="imgList"></swipers></div> -->
    <el-carousel height="480px" class="swImg" arrow="never">
      <el-carousel-item v-for="(item, index) in imgList" :key="index" class="pointer">
        <img :src="item.img" @click="nav(item)">
      </el-carousel-item>
    </el-carousel>
    <!---->

    <!-- 一个大类(其他学院和公考学院 四个项目以下) -->
    <div v-if="collageList.length == 1 && collageList[0].kindList.length < 5" class="oneSwipers"
      @mouseenter="oneMouseenter(false)" @mouseleave="oneMouseleave(false)">
      <div class="left_box">
        <!-- 其他大类 -->
        <div v-if="currentCollage.pKind && currentCollage.pKind != 7196" ref="kindBox">
          <div v-for="(item, index) in currentCollage && currentCollage.kindList ? currentCollage.kindList : []"
            :key="index" class="college_name" :class="{ border_bto: index == currentCollage.kindList.length - 1 }"
            @mouseenter="changeKind(item)">
            <div class="pName" @click.stop="projectDetail(item)">
              {{ item.pName }}
            </div>
            <div class="kind_box">
              <div v-for="(kind, kindIndex) in item.zyList ? item.zyList : []" :key="kindIndex" class="kind_item"
                @click="zhuanYeXqs(course.zKind, course.zId, currentCollage.pKind)">
                <div v-if="kindIndex < 2" class="kind_name">
                  {{ kind.zName }}
                </div>
                <el-divider v-if="kindIndex == 0 && item.zyList.length > 1" direction="vertical" />
              </div>
            </div>
            <div class="triangle" />
          </div>
          <div class="line" />
        </div>
        <!-- ------------------------------- -->
        <!-- 公考学院 -->
        <div v-else ref="kindBox" class="kindBox">
          <div v-for="(item, index) in menuList" :key="index" class="college_name"
            :class="{ border_bto: index == menuList.length - 1 }" @mouseenter="menuChange(item)">
            <div class="pName" @click.stop="goPracticalInformation(item, null)">
              {{ item.menuName }}
            </div>
            <div class="kind_box">
              <div v-for="(kind, kindIndex) in item.menuList" :key="kindIndex" class="kind_item"
                @click="goPracticalInformation(item, kind)">
                <div v-if="kindIndex < 2" class="kind_name">
                  {{ kind.menuName }}
                </div>
                <el-divider v-if="kindIndex == 0 && item.menuList.length > 1" direction="vertical" />
              </div>
            </div>
            <div class="triangle" />
          </div>
          <div class="line" />
        </div>
        <div class="entrance_box">
          <div class="top">
            <div class="entrance_item" @click="toNext(`/quesTion/quesTionList`)">
              <img class="icon" src="@/assets/img/Home/entranceIcon_4.png" alt="">
              <div class="title">题库</div>
            </div>
            <div class="entrance_item" @click="toNext(`/data/dataPackage`)">
              <img class="icon" src="@/assets/img/Home/entranceIcon_3.png" alt="">
              <div class="title">学习资料</div>
            </div>
          </div>
          <div class="bottom">
            <div class="entrance_item" @click="toNext(`/my/mineCoupon`)">
              <img class="icon" src="@/assets/img/Home/entranceIcon_1.png" alt="">
              <div class="title">优惠券</div>
            </div>
            <div class="entrance_item" @click="toNext(`/VIP/VIPClass`)">
              <img class="icon" src="@/assets/img/Home/entranceIcon_2.png" alt="">
              <div class="title">VIP专属</div>
            </div>
          </div>
        </div>
        <!-- ------------------------------- -->

        <div v-if="currentCollage.pKind && currentCollage.pKind != 7196" class="recommend">
          <div v-for="(item, index) in recommendList" :key="index" class="recommend_item" @click="goPage(item)">
            <div class="tag">荐</div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="right_box">
        <!-- 其他学院 -->
        <div v-if="currentCollage.pKind && currentCollage.pKind != 7196" v-show="mainBoxShow" ref="mainBox"
          class="main_box one">
          <div v-if="currentKind.pKind != 1" class="title" @click="projectDetail(currentKind)">
            {{ currentKind ? currentKind.pName : '' }}
          </div>
          <template v-if="currentKind.pKind != 1">
            <div class="zy_box">
              <div v-for="(item, index) in currentKind && currentKind.zyList ? currentKind.zyList : []" :key="index"
                class="zy_item" @click="zhuanYeXqs(item.zKind, item.zId, currentCollage.pKind)">
                {{ item.zName }}
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="currentKind.pKind == 1" class="title" @click="projectDetail(currentKind)">
              自学考试辅导（专科）
            </div>
            <div class="zy_box">
              <div v-for="(item, index) in currentKind && currentKind.zyList ? currentKind.zyList : []"
                v-show="item.zStage == '高起专'" :key="index" class="zy_item"
                @click="zhuanYeXqs(item.zKind, item.zId, currentCollage.pKind)">
                {{ item.zName }}
              </div>
            </div>
            <div v-if="currentKind.pKind == 1" class="title" @click="projectDetail(currentKind)">
              自学考试辅导（本科）
            </div>
            <div class="zy_box">
              <div v-for="(item, index) in currentKind && currentKind.zyList ? currentKind.zyList : []"
                v-show="item.zStage == '专升本'" :key="index" class="zy_item"
                @click="zhuanYeXqs(item.zKind, item.zId, currentCollage.pKind)">
                {{ item.zName }}
              </div>
            </div>
          </template>
        </div>
        <!-- -------------------------- -->
        <!-- 公考学院 -->
        <div v-else v-show="mainBoxShow" ref="mainBox" class="main_box one">
          <div class="title_box">
            <div class="title_block" />
            <div class="menu_title" @click="goPracticalInformation(twoMenuList, null)">
              {{ twoMenuList ? twoMenuList.menuName : '' }}
            </div>
          </div>
          <div class="zy_box menu_box">
            <div v-for="(item, index) in twoMenuList.menuList" :key="index" class="menu_item"
              @click="goPracticalInformation(twoMenuList, item)">
              {{ item.menuName }}
            </div>
            <el-divider />
            <div class="recommend">
              <div v-for="(item, index) in recommendList" :key="index" class="recommend_item" @click="goPage(item)">
                <div class="name_box">
                  <div class="tag">荐</div>
                  <div class="title">{{ item.title }}</div>
                </div>
                <div class="tags_box">{{ liveEnum[item.types] }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------- -->
      </div>
    </div>
    <!-- 一个大类(职业培训学院 四个项目以上) -->
    <div v-if="collageList.length == 1 && collageList[0].kindList.length > 4" class="one-swipers_more_kind"
      @mouseenter="moveInKind(true)" @mouseleave="moveOutKind(true)">
      <div ref="listDom" class="left_box">
        <div v-for="(item, index) in collageList[0].kindList" :key="index" class="kind_item"
          :class="{ dis_none: index > 8 }" @mouseenter="changeKind(item)">
          <div class="kind_name" @click.stop="projectDetail(item)">{{ item.pName }}：</div>
          <div class="major_name">
            <span v-for="(zy, zyIndex) in item.zyList" :key="zyIndex">{{ zy.zName }}</span>
          </div>
          <div class="triangle" />
        </div>
      </div>
      <div v-show="mainBoxShow" ref="mainBox" class="main_box">
        <div class="kind_name" @click.stop="projectDetail(currentKind)">
          {{ currentKind.pName }}
        </div>
        <div class="zy_box">
          <div v-for="(item, index) in currentKind.zyList" :key="index" class="zy_item"
            @click="zhuanYeXqs(item.zKind, item.zId, currentCollage.pKind)">
            <div class="project_name">{{ item.zName }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 二、三个大类 -->
    <div v-if="collageList.length > 1 && collageList.length < 4" class="swipers">
      <div class="list">
        <div v-for="(item, index) in collageList" :key="index" class="college_name" :class="{
          on: currentCollage.pKind && currentCollage.pKind == item.pKind,
        }" @mouseenter="changeCollege(item)" @click="zhuanYeXqs(undefined, undefined, item.pKind)">
          {{ item.pName }}
        </div>
      </div>
      <div class="content_box">
        <div v-for="(item, index) in currentCollage ? currentCollage.kindList : []" :key="index" class="project_item"
          :class="{ m_t_13: index != 0 }">
          <div v-if="item.pKind != 1" class="project_title" @click="projectDetail(item)">
            {{ item.pName }}
          </div>
          <template v-if="item.pKind != 1">
            <div class="zy_box">
              <div v-for="(zy, i) in item.zyList" :key="i" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName == '成人高考' ? zy.zStage : zy.zName }}
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="item.pKind == 1" class="project_title" @click="projectDetail(item)">
              自学考试辅导（专科）
            </div>
            <div class="zy_box">
              <div v-for="(zy, i) in item.zyList" v-show="zy.zStage == '高起专'" :key="i" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName == '成人高考' ? zy.zStage : zy.zName }}
              </div>
            </div>
            <div v-if="item.pKind == 1" class="project_title" @click="projectDetail(item)">
              自学考试辅导（本科）
            </div>
            <div class="zy_box">
              <div v-for="(zy, i) in item.zyList" v-show="zy.zStage == '专升本'" :key="i" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName == '成人高考' ? zy.zStage : zy.zName }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- 四到七个大类 -->
    <div v-if="collageList.length > 3 && collageList.length < 8" class="fourSwipres" @mouseenter="mouseenter(false)"
      @mouseleave="mouseleave(false)">
      <div class="list" :class="{
        p_t_28: collageList.length > 3 && collageList.length < 7,
        p_t_18: collageList.length == 7,
      }">
        <div v-for="(item, index) in collageList" :key="index" class="college_box" @mouseenter="changeCollege(item)"
          @click="zhuanYeXqs(undefined, undefined, item.pKind)">
          <div class="college_name">
            <span>{{ item.pName }}</span>
            <div class="triangle" />
          </div>
          <div class="course_box" :class="{
            h_52: collageList.length == 4,
            h_26: collageList.length == 5 || collageList.length == 6 || collageList.length == 7,
          }">
            <div v-for="(course, i) in item.courseList" :key="i" class="course_item"
              @click="zhuanYeXqs(course.zKind, course.zId, currentCollage.pKind)">
              {{ course.zName }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="mainBoxShow" ref="mainBox" class="main_box">
        <div v-for="(item, index) in currentCollage.kindList" :key="index" class="project_item"
          :class="{ m_t_10: index != 0 }">
          <div v-if="item.pKind != 1" class="project_name" @click="projectDetail(item)">
            {{ item.pName }}
          </div>
          <template v-if="item.pKind != 1">
            <div class="zy_box">
              <div v-for="zy in item.zyList" :key="zy.zId" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName }}
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="item.pKind == 1" class="project_name" @click="projectDetail(item)">
              自学考试辅导（专科）
            </div>
            <div class="zy_box">
              <div v-for="zy in item.zyList" v-show="zy.zStage == '高起专'" :key="zy.zId" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName }}
              </div>
            </div>
            <div v-if="item.pKind == 1" class="project_name" @click="projectDetail(item)">
              自学考试辅导（本科）
            </div>
            <div class="zy_box">
              <div v-for="zy in item.zyList" v-show="zy.zStage == '专升本'" :key="zy.zId" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- 八个以上大类 -->
    <div v-if="collageList.length > 7" class="moreSwipres" @mouseenter="mouseenter(true)" @mouseleave="mouseleave(true)">
      <div ref="listDom" class="list">
        <div v-for="(item, index) in collageList" :key="index" class="college_box" :class="{ dis_none: index > 6 }"
          @mouseenter="changeCollege(item)" @click="zhuanYeXqs(undefined, undefined, item.pKind)">
          <!--  -->
          <div class="college_name">
            <span>{{ item.pName }}</span>
            <div class="triangle" />
          </div>
          <div class="course_box">
            <div v-for="(course, i) in item.courseList" :key="i" class="course_item"
              @click="zhuanYeXqs(course.zKind, course.zId, currentCollage.pKind)">
              {{ course.zName }}
            </div>
          </div>
        </div>
      </div>
      <div v-show="mainBoxShow" ref="mainBox" class="main_box">
        <div v-for="(item, index) in currentCollage.kindList" :key="index" class="project_item"
          :class="{ m_t_10: index != 0 }">
          <div v-if="item.pKind != 1" class="project_name" @click="projectDetail(item)">
            {{ item.pName }}
          </div>
          <template v-if="item.pKind != 1">
            <div class="zy_box">
              <div v-for="zy in item.zyList" :key="zy.zId" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName }}
              </div>
            </div>
          </template>
          <template v-else>
            <div v-if="item.pKind == 1" class="project_name" @click="projectDetail(item)">
              自学考试辅导（专科）
            </div>
            <div class="zy_box">
              <div v-for="zy in item.zyList" v-show="zy.zStage == '高起专'" :key="zy.zId" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName }}
              </div>
            </div>
            <div v-if="item.pKind == 1" class="project_name" @click="projectDetail(item)">
              自学考试辅导（本科）
            </div>
            <div class="zy_box">
              <div v-for="zy in item.zyList" v-show="zy.zStage == '专升本'" :key="zy.zId" class="zy_item"
                @click="zhuanYeXqs(zy.zKind, zy.zId, currentCollage.pKind)">
                {{ zy.zName }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import { liveEnum } from '@/api/emun'
import swipers from '@/components/swipers.vue'
import { selectKindAllCollage,selectAcademyKindzyListnew, getSurfaceList, selectPcList } from '@/api/home'
import { getDomain } from '@/api/cookies'
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: { swipers },
  data() {
    return {
      liveEnum: liveEnum,
      imgList: [],
      kindIndex: null,
      collageList: {},
      domain: null,

      collageId: null,
      // kindList: [],
      currentCollage: {},
      currentKind: {},

      mainBoxShow: false,

      firstMouseEnterKind: true,

      recommendList: [],
      menuList: [],
      twoMenuList: {}
    }
  },
  created() {
    this.domain = getDomain()
    getSurfaceList(1, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        this.imgList = res.msg
      }
    })
    this.loadgetTenantidNavigation() // 调用轮播图导航接口方法
  },
  methods: {
    // 轮播左侧内容点击跳转详情
    zhuanYeXqs(kind, zId, schoole) {
      /* this.$router.push({
        path: `/allcurriculum/typeclass?kind=${kind ? kind : ""}&zId=${zId ? zId : ""}&schoole=${
          schoole ? schoole : ""
        }`,
      }); */
      const routeUrl = this.$router.resolve({
        path: `/allcurriculum/typeclass?kind=${kind || ''}&zId=${zId || ''}&schoole=${schoole || ''
          }`
      })
      window.open(routeUrl.href, '_blank')
    },

    projectDetail(item) {
      this.$router.push({
        path: `/projectChannelPage`,
        query: {
          id: item.pKind,
          schoolId: item.pParentid
        }
      })
    },

    // 调用轮播图导航接口方法
    loadgetTenantidNavigation() {
      const productLine = 4
      selectAcademyKindzyListnew().then((res) => {
        if (res.code == 0) {
          res.data.forEach((item) => {
            if (!item.kindList) return
            item.courseList = []
            item.kindList.forEach((i) => {
              if (!i.zyList) return
              item.courseList.push(...i.zyList)
            })
          })

          this.collageList = res.data

          // 只有一个学院时 数据存到vuex中
          if (res.data.length == 1) {
            this.$store.commit('SET_COLLAGELIST', this.collageList)
          }

          this.collageId = res.data[0].pKind
          this.currentCollage = res.data[0]

          if (res.data.length == 1 && res.data[0].kindList) {
            switch (res.data[0].kindList.length) {
              case 4:
                this.getCourseList(1)
                break

              case 3:
                this.getCourseList(3)
                break

              case 2:
                this.getCourseList(4)
                break

              case 1:
                if (this.currentCollage.pKind == 7196) {
                  this.getCourseList(3)
                  this.selectPcList()
                } else {
                  this.getCourseList(6)
                }
                break

              default:
                break
            }
          }
        } else {
          this.collageList = []
        }
      })
    },
    getCourseList(num) {
      know.getCourseListnew(num, 1, undefined, this.currentCollage.pKind).then((res) => {
     
        this.recommendList = res.rows
      })
    },

    // 项目报考信息列表
    selectPcList() {
      const data = {
        kind: this.currentCollage?.kindList[0]?.pKind
      }
      selectPcList(data).then((res) => {
        if (res.data.length > 5) {
          res.data = res.data.slice(0, 5)
        }
       
        this.menuList = res.data
      })
    },
    nav(item) {
      window.open(item.skipImg, '_blank')
    },

    // 鼠标移入项目（一个大类）
    changeKind(item) {
   
      this.currentKind = item
    },

    // 鼠标移入 （公考学院）
    menuChange(item) {
      this.twoMenuList = item
    },
    // 点击菜单跳转
    goPracticalInformation(i, item) {
      const routeUrl = this.$router.resolve({
        path: '/projectChannelPage/menuContent',
        query: {
          kind: this.pKind,
          menuOneId: i ? i.id : item.parentId,
          menuTwoId: item ? item.id : null
        }
      })
      window.open(routeUrl.href, '_blank')
    },

    // 鼠标移入学院 (二个大类以上)
    changeCollege(item) {
      /* this.collageId = item.pKind;
      this.kindList = item.kindList */
      this.currentCollage = item
    },

    oneMouseenter() {
      this.mainBoxShow = true
      if (!this.firstMouseEnterKind) return
      this.$nextTick(() => {
        const kindBox = this.$refs.kindBox
        const one = document.querySelector('.one')
        one.style.minHeight = '292px'
        one.style.height = `${kindBox.offsetHeight}px`
        if (kindBox.offsetHeight == one.offsetHeight) {
          this.firstMouseEnterKind = false
        }
      })
    },

    oneMouseleave() {
      this.mainBoxShow = false
    },

    // 八个以上大类移入
    mouseenter(info) {
      this.mainBoxShow = true
      if (!info) return
      this.$nextTick(() => {
        const moreSwipres = document.querySelector('.moreSwipres')
        const list = document.querySelector('.list')
        const college_box = list.querySelectorAll('.college_box')
        const dis_none = list.querySelectorAll('.dis_none')

        // college_box.forEach((item) => {
        //   item.style.flex = "auto";
        //   item.style.height = "57.41px";
        // });
        dis_none.forEach((item) => {
          item.style.display = 'block'
        })
        // moreSwipres.style.width = '660px'

        const mainBox = this.$refs.mainBox
        const listDom = this.$refs.listDom
        mainBox.style.height = `${listDom.offsetHeight}px`
      })
    },
    // 八个以上大类移出
    mouseleave(info) {
      this.mainBoxShow = false
      if (!info) return
      const mainBox = this.$refs.mainBox
      const list = document.querySelector('.list')
      const dis_none = list.querySelectorAll('.dis_none')
      dis_none.forEach((item) => {
        item.style.display = 'block'
      })
      mainBox.style.height = '100%'
    },

    // 一个大类四个以上项目移入
    moveInKind(info) {
      this.mainBoxShow = true
      if (!info) return
      this.$nextTick(() => {
        const moreSwipres = document.querySelector('.one-swipers_more_kind')
        const list = document.querySelector('.left_box')
        const college_box = list.querySelectorAll('.kind_item')
        const dis_none = list.querySelectorAll('.dis_none')

        /* college_box.forEach((item) => {
          item.style.flex = "auto";
          item.style.height = "57.41px";
        }); */
        dis_none.forEach((item) => {
          item.style.display = 'flex'
        })
        // moreSwipres.style.width = '660px'

        const mainBox = this.$refs.mainBox
        const listDom = this.$refs.listDom
        mainBox.style.height = `${listDom.offsetHeight}px`
      })
    },
    // 一个大类四个以上项目移出
    moveOutKind(info) {
      this.mainBoxShow = false
      if (!info) return
      const mainBox = this.$refs.mainBox
      const list = document.querySelector('.left_box')
      const dis_none = list.querySelectorAll('.dis_none')
      const moreSwipres = document.querySelector('.one-swipers_more_kind')
     
      dis_none.forEach((item) => {
        item.style.display = 'none'
      })
      mainBox.style.height = '480px'
      // moreSwipres.style.width = '320px'
    },

    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    toNext(url) {
      const routeUrl = this.$router.resolve({
        path: url
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.bannerImg {
  width: 100%;
  height: 480px;
  position: relative;

  // 盒子右
  .swipersRight {
    width: 100%;
    height: 480px;
    cursor: pointer;
  }
}

/* 轮播图 */
/deep/.swImg {
  width: 100%;
  height: 480px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;

  .el-carousel__item {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .el-carousel__arrow--left {
    left: calc(50% - 610px + 240px + 40px);
  }

  .el-carousel__arrow--right {
    right: 350px;
  }

  .el-carousel__arrow {
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.4);

    i {
      font-size: 30px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  img {
    /* max-width: 100%; */
    height: 480px;
    margin: auto;
  }
}

// 一个大类四个项目以下
.oneSwipers {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: calc(50% - 610px);
  z-index: 1000;

  .left_box {
    position: absolute;
    top: 0px;
    bottom: 0px;
    height: 100%;
    left: 0;
    width: 240px;
    height: 100%;
    background-color: #ffffff;
    overflow: hidden;

    .college_name {
      height: 57px;
      // height: 140px;
      padding: 11px 0 0 11px;
      border-left: 4px solid #ffffff;
      position: relative;
      margin-bottom: 10px;
      cursor: pointer;

      .pName {
        color: #444444;
        font-weight: 500;
        line-height: 22px;
        // margin-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 190px;
        font-family: PINGFANG BOLD;
      }

      .kind_box {
        display: flex;
        color: #777777;
        font-size: 14px;
        line-height: 20px;
        font-family: PINGFANG REGULAR;

        .kind_item {
          display: flex;
          align-items: center;
        }
      }

      .triangle {
        margin-top: 4px;
        width: 0;
        height: 0;
        border-left: 8px solid #8ac1f8;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        right: 15px;
        top: 24px;
      }
    }

    .border_bto {
      padding-bottom: 18px;
      margin-bottom: 0;
    }

    .line {
      background-color: #f2f2f2;
      height: 1px;
      margin-top: 5px;
      margin-bottom: 17px;
    }

    .college_name:hover {
      color: #1061ff;
      background-color: #f3f7ff;
      border-left: 4px solid #1061ff;
    }

    .entrance_box {
      padding-left: 15px;
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 15px;
      padding-bottom: 17px;

      .top {
        margin-bottom: 17px;
      }

      .top,
      .bottom {
        display: flex;
        justify-content: space-between;

        .entrance_item {
          display: flex;
          width: 50%;
          font-family: PINGFANG REGULAR;
          cursor: pointer;

          .icon {
            margin-right: 6px;
            width: 24px;
            height: 24px;
          }

          .title {
            color: #555555;
            // line-height: 21px;
          }
        }
      }
    }

    .recommend {
      padding-left: 10px;

      .recommend_item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .tag {
          width: 32px;
          height: 22px;
          background-color: #ff5e51;
          color: #ffffff;
          border-radius: 12px;
          text-align: center;
          line-height: 22px;
          font-size: 14px;
          margin-right: 9px;
        }

        .title {
          width: 170px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #555555;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .right_box {
    .one {
      width: 444px;
      padding-bottom: 20px;

      .title {
        color: #444444;
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        margin-bottom: 13px;
      }

      .title_box {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .title_block {
          width: 6px;
          height: 20px;
          background: linear-gradient(191deg, #88c8ff 0%, #1061ff 100%);
          margin-right: 8px;
        }

        .menu_title {
          color: #444444;
          font-size: 18px;
          line-height: 25px;
          font-weight: 600;
          font-family: PINGFANG BOLD;
        }
      }

      // 公考学院
      .menu_box {
        margin-left: 14px !important;
        flex-wrap: wrap;

        .recommend {
          .recommend_item {
            display: flex;
            justify-content: space-between;

            .name_box {
              display: flex;

              .tag {
                background-color: #ff5e51;
                color: #ffffff;
                border-radius: 12px;
                font-size: 14px;
                width: 32px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                margin-right: 8px;
                font-family: PINGFANG BOLD;
              }

              .title {
                width: 280px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #555555;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                font-family: PINGFANG MEDIUM;
              }
            }

            .tags_box {
              height: 20px;
              padding: 0 9px;
              border-radius: 11px;
              border: 1px #b4ccfc solid;
              font-size: 12px;
              line-height: 20px;
              color: #1061ff;
              font-family: PINGFANG REGULAR;
            }
          }
        }

        ::v-deep .el-divider {
          margin: 0;
          margin-bottom: 22px;
          width: 90%;
        }
      }

      .zy_box {
        display: flex;
        flex-wrap: wrap;
        color: #666666;
        line-height: 22px;
        margin-left: 22px;

        .zy_item {
          padding: 10px 15px;
          flex-shrink: 0;
          color: #444444;
          font-size: 14px;
          box-sizing: border-box;
          border: #ffffff 1px solid;
        }

        .zy_item:hover {
          color: #1061ff;
          border: #1061ff 1px solid;
          background-color: #e2ecff;
          border-radius: 3px;
        }

        .menu_item {
          border: 1px solid #1061ff;
          border-radius: 15px;
          padding: 5px 12px;
          flex-shrink: 0;
          color: #1061ff;
          font-size: 14px;
          line-height: 20px;
          box-sizing: border-box;
          flex-shrink: 0;
          margin-right: 30px;
          margin-bottom: 22px;
          font-family: PINGFANG REGULAR;
          // border: #ffffff 1px solid;
        }
      }
    }
  }
}

// 一个大类多个项目
.one-swipers_more_kind {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: calc(50% - 610px);
  z-index: 1000;

  .left_box {
    width: 320px;
    min-height: 480px;
    background-color: rgba(0, 0, 0, 0.65);

    .kind_item {
      color: #ffffff;
      font-weight: 500;
      // margin-bottom: 22px;
      height: 46px;
      padding-left: 16px;
      line-height: 46px;
      display: flex;
      position: relative;

      .kind_name,
      .dis_none {
        max-width: 144px;
        flex-shrink: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        cursor: pointer;
      }

      .major_name {
        width: 128px;
        flex-shrink: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #cfcfcf;
      }

      .triangle {
        margin-top: 4px;
        width: 0;
        height: 0;
        border-left: 8px solid #8ac1f8;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }

    .kind_item:hover {
      background-color: rgba(255, 255, 255, 0.28);
    }

    .dis_none {
      display: none;
    }
  }

  .main_box {
    left: 320px;

    .kind_name {
      color: #333333;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .zy_box {
      display: flex;

      .zy_item {
        padding: 10px 15px;
        flex-shrink: 0;
        color: #444444;
        font-size: 14px;
        box-sizing: border-box;
        border: #ffffff 1px solid;
      }

      .zy_item:hover {
        color: #1061ff;
        border: #1061ff 1px solid;
        background-color: #e2ecff;
        border-radius: 3px;
      }
    }
  }
}

// 二、三个大类
.swipers {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: calc(50% - 610px);
  z-index: 1000;
  cursor: pointer;
  display: flex;

  .list {
    height: 100%;
    width: 66px;
    display: flex;
    flex-direction: column;
    // margin-right: 1px;

    .college_name {
      font-weight: bold;
      color: #ffffff;
      padding-left: 23px;
      height: auto;
      // margin-bottom: 1px;
      background-color: rgba(58, 58, 58, 0.95);
      flex: 1;
      writing-mode: vertical-lr;
      text-align: center;
      font-family: PINGFANG BOLD;
    }

    .on {
      background-color: #3662ec;
    }
  }

  .content_box {
    width: 268px;
    height: 100%;
    padding-top: 23px;
    padding-left: 14px;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: auto;

    .project_item {
      .project_title {
        margin-bottom: 9px;
        color: #ffffff;
        font-weight: bold;
        font-family: PINGFANG BOLD;
      }

      .zy_box {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 8px;

        .zy_item {
          flex-shrink: 0;
          color: rgba(255, 255, 255, 0.9);
          font-size: 14px;
          padding: 7px 11px;
          margin-bottom: 5px;
          box-sizing: border-box;
          // border: solid 1px #ffffff;
          padding: 6px 10px;
          font-family: PINGFANG;
          // font-family: PINGFANG BOLD;
        }

        .zy_item:hover {
          color: #1061ff;
          background-color: #e2ecff;
          border: solid 1px #1061ff;
          border-radius: 3px;
        }
      }
    }

    .m_t_13 {
      margin-top: 13px;
    }
  }
}

// 四到七个大类
.fourSwipres {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: calc(50% - 610px);
  z-index: 1000;
  cursor: pointer;

  .list {
    width: 240px;
    height: 100%;
    background-color: rgba(58, 58, 58, 0.3);
    display: flex;
    flex-direction: column;

    &:hover {
      background-color: rgba(58, 58, 58, 0.95);
    }

    .college_box {
      // padding: 10px 19px 9px 18px;
      flex: 1;

      .college_name {
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .triangle {
          margin-top: 11px;
          width: 0;
          height: 0;
          border-left: 8px solid #dcdcdc;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }

      .course_box {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        .course_item {
          flex-shrink: 0;
          font-size: 12px;
          color: #dcdcdc;
          margin-right: 1em;
          margin-bottom: 10px;
        }
      }

      .h_26 {
        height: 26px;
      }

      .h_52 {
        height: 52px;
      }
    }
  }

  .p_t_28 {
    padding: 28px 19px 0 24px;
  }

  .p_t_18 {
    padding: 18px 19px 0 18px !important;
  }
}

// 七个以上大类
.moreSwipres {
  position: absolute;
  top: 0px;
  bottom: 0px;
  height: 100%;
  left: calc(50% - 610px);
  z-index: 1000;
  cursor: pointer;

  .list {
    width: 240px;
    min-height: 100%;
    background-color: rgba(58, 58, 58, 0.3);
    // padding: 18px 18px 0 24px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:hover {
      background-color: rgba(58, 58, 58, 0.95);
    }

    .college_box {
      height: 60px;
      // flex: 1;
      padding: 8px 18px 0 16px;

      .college_name {
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 6px;

        .triangle {
          margin-top: 11px;
          width: 0;
          height: 0;
          border-left: 8px solid #dcdcdc;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }

      .course_box {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        height: 26px;

        .course_item {
          flex-shrink: 0;
          font-size: 12px;
          color: #dcdcdc;
          margin-right: 1em;
          margin-bottom: 10px;
        }
      }
    }

    .college_box:hover {
      background-color: rgba(255, 255, 255, 0.28);
    }

    .dis_none {
      display: block;
    }
  }

  /* .list:hover .college_box {
    height: 57.41px !important;
    flex: auto;
  }
  .list:hover .dis_none {
    display: block;
  } */
}

.main_box {
  position: absolute;
  top: 0px;
  left: 240px;
  bottom: 0px;
  height: 100%;
  z-index: 1000;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.95);
  width: 669px;
  padding: 25px 0 0 30px;
  overflow: auto;

  .project_item {
    .project_name {
      color: #333333;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .zy_box {
      display: flex;
      flex-wrap: wrap;

      .zy_item {
        padding: 10px 15px;
        flex-shrink: 0;
        color: #444444;
        font-size: 14px;
        box-sizing: border-box;
        // border: #ffffff 1px solid;
      }

      .zy_item:hover {
        color: #1061ff;
        border: #1061ff 1px solid;
        background-color: #e2ecff;
        border-radius: 3px;
      }
    }
  }

  .m_t_10 {
    margin-top: 10px !important;
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
</style>
