<template>
  <!-- 精品课程-->
  <div v-if="list && list.length > 0" class="p-t-20">
    <div v-if="list && list.length > 0" class="home-three-title">
      <div class="text fourth-text">
        <img class="fourth-img" src="@/assets/img/homeFourth/open.png">
        <div class="text-slot">
          <slot>精品课程</slot>
        </div>
      </div>
      <span class="more" @click="zhibokes">查看更多&nbsp;></span>
    </div>

    <!-- 精品课程  内容 -->
    <div v-if="list && list.length > 0" class="live">
      <div v-for="(item, index) in list" :key="index" class="item" @click="goPage(item)">
        <!-- 活动详情 -->
        <div class="content">
          <img class="item-img" :src="item.img">
          <div class="detail">
            <div class="detail-title twoEllipsis">{{ item.title }}</div>

            <div class="item-tips elp">{{ item.pName }} · {{ item.kindName }}</div>
          </div>
        </div>
        <!-- 价格 -->
        <div class="flex-between-c price-box">
          <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
          <div class="tips">人气值 {{ item.clicks ? item.clicks : 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import '@/assets/css/common.css'
import { Know } from '@/api/know'
const know = new Know()
export default {
  components: {},
  data() {
    return {
      list: [] // 公开课
    }
  },
  created() {
    know.getCourseListnew(6, 1).then((res) => {
      this.list = res.rows
    })
  },
  methods: {
    // 查看更多直播课按钮
    zhibokes() {
      const routeUrl = this.$router.resolve({
        path: '/jingpin/courer'
      })
      window.open(routeUrl.href, '_blank')
    },
    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      /*  if (item.types == 1) {
        this.$router.push(`/zhiboclassxq?id=${item.id}`);
      } else if (item.types == 2) {
        this.$router.push(`/videoxq?id=${item.id}`);
      } */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home-three-title {
  padding-bottom: 30px;
  width: 1220px !important;
}

// 精品课程  内容
.live {
  width: 1220px;
  margin: auto;
  height: 348px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  margin-bottom: 60px;

  .item {
    width: 380px;
    height: 144px;
    background: linear-gradient(180deg, #fffcf1 0%, #fffdf8 100%);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 20px;
    padding: 20px 16px 16px;

    .content {
      display: flex;

      .item-img {
        margin-top: 5px;
        width: 112px;
        height: 63px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;

        flex-shrink: 0;
        margin-right: 12px;
      }

      .detail {
        padding: 4px 0px 0px;

        .detail-title {
          height: 44px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
        }

        .item-tips {
          height: 12px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ff7b1c;
          line-height: 12px;
          margin-top: 8px;
        }
      }
    }

    .tips {
      height: 12px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
    }

    .price-box {
      margin-top: 16px;

      .price {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: Bold;
        color: #ff5e51;
        line-height: 18px;

        .price-icon {
          font-weight: 400;
        }
      }
    }
  }
}
</style>
