<template>
  <!-- 精品课程-->
  <div v-if="list && list.length > 0" class="p-t-20">
    <!--标题 最近直播 -->
    <div class="home-two-title">
      <div class="text">
        <slot>精品课程</slot>
        <!-- <div class="icon"></div> -->
        <!-- <img class="icon" src="@/assets/img/Home/tips.png" /> -->
      </div>
      <span class="more" @click="zhibokes">查看更多&nbsp;></span>
    </div>
    <!-- 最近直播  内容 -->
    <div v-if="list && list.length > 0" class="live">
      <div v-for="(item, index) in list" :key="index" class="item" @click="goPage(item)">
        <!-- 活动详情 -->
        <img class="item-img" :src="item.img">
        <div class="detail">
          <div class="detail-title twoEllipsis">{{ item.title }}</div>
          <div class="flex-c m-t-12">
            <div v-if="item.pName" class="item-tips elp">{{ item.pName }}</div>
            <div v-if="item.kindName" class="item-tips elp">{{ item.kindName }}</div>
            <!--   <div class="item-tips elp">{{ dealType(item.types) }}</div>
            <div class="item-tips elp">{{ Math.floor(item.allTime) }}课时</div> -->
          </div>

          <!-- 价格 -->
          <div class="flex-between-c price-box">
            <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
            <div class="tips">人气值 {{ item.clicks ? item.clicks : 0 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import '@/assets/css/common.css'
import { Know } from '@/api/know'
const know = new Know()
import { selectCoursePages } from '@/api/home'
export default {
  components: {},
  data() {
    return {
      list: [] // 公开课
    }
  },
  created() {
    know.getCourseListnew(5, 1).then((res) => {
      this.list = res.rows
    })
  },
  methods: {
    dealType(type) {
      let temp = null
      switch (type) {
        case 1:
          temp = '直播'
          break
        case 2:
          temp = '录播'
          break
        case 3:
          temp = '音频'
          break
        case 4:
          temp = '图文'
          break
        case 5:
          temp = '面授'
          break
        default:
          ''
      }
      return temp
    },
    // 查看更多直播课按钮
    zhibokes() {
      const routeUrl = this.$router.resolve({
        path: '/jingpin/courer'
      })
      window.open(routeUrl.href, '_blank')
    },
    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}`
        })
        window.open(routeUrl.href, '_blank')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.home-two-title {
  width: 1220px !important;
  padding-bottom: 4px;
  padding-top: 0px;
}

// 最近直播  内容
.live {
  width: 1244px;
  margin-left: calc(50% - 610px);
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item {
    margin-bottom: 24px;
    width: 224px;
    height: 305px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    margin-right: 24px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 8px 1px rgba(153, 153, 153, 0.20000000298023224);
    }

    .item-img {
      width: 224px;
      height: 126px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }

    .detail {
      padding: 12px 16px;

      .detail-title {
        height: 48px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        font-size: 16px;
        line-height: 24px;
      }

      .item-tips {
        height: 20px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ff7b1c;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ff7b1c;
        line-height: 18px;
        padding: 0px 8px;
      }

      .item-tips+.item-tips {
        margin-left: 8px;
      }

      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }

      .price-box {
        margin-top: 24px;

        .price {
          font-family: Microsoft YaHei-Regular;
          color: #ff5d51;
          font-size: 24px;
          line-height: 24px;
          font-weight: Bold;

          .price-icon {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
