<template>
  <div class="product">
    <!-- 公开课 -->
    <div class="til">
      <div class="title">
        学习资料
        <img src="@/assets/img/partner/corner.png" alt="" />
      </div>
      <div @click="saveImg('/partnerPage/dataPackage')">
        查看更多
        <i class="iconfont el-icon-arrow-right" style="font-size: 12px" />
      </div>
    </div>
    <div class="product_content">
      <div class="product_content_imgBox">
        <img src="@/assets/img/partner/product3.png" alt="" />
      </div>
      <div @click="goPage(item)" class="product_content_item" v-for="(item, index) in list" :key="index">
        <div class="product_content_item_img">
          <img src="@/assets/img/partner/page.png" alt="" />
          <div class="title twoEllipsis">
            {{ item.title.substring(0, 19) }}
            <span v-if="item.title.length > 20">...</span>
          </div>
          <div class="kindName">
            {{ item.kindName }}
            <!-- 丨{{ item.kcName }} -->
          </div>
        </div>
        <div class="product_content_item_title">
          <div v-for="(row, ind) in item.packageList" :key="ind" v-show="ind < 2" @click="toView(val, item)">
            <img src="@/assets/img/partner/doc.png" alt="" />
            <span style="width: 210px; display: inline-block;" class="elp">{{ row.title }}</span>
          </div>
        </div>
        <div class="product_content_item_price">
          <div class="count">
            共<span style="color: #ff7b1c">{{ item.packageList ? item.packageList.length : 0 }}份</span>资料
          </div>
          <el-button class="btn" @click="goPage(item)">去购买</el-button>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="kowledgePointsVisible" width="50%" :before-close="kowledgePointsClose"
      :show-close="false">
      <div class="kowledgePoints_wrap">
        <kowledgePoints ref="kowledgePoints" type="4" />
        <div class="determine_btn" @click="kowledgePointsClose">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import '@/assets/css/common.css'
import Vue from 'vue'
import { DataClient } from '@/api/dataClient'
const dataClient = new DataClient()
import { getInfo } from '@/api/cookies'
import kowledgePoints from '@/components/kowledgePoints.vue'
export default {
  components: { kowledgePoints },
  data() {
    return {
      list: [], // 公开课
      userInfo: null,
      kowledgePointsVisible: false
    }
  },
  async created() {
    this.userInfo = await getInfo()
    this.getList()
  },
  methods: {
    // 项目专业
    async getList() {
      this.loading = true
      try {
        const res = await dataClient.getNewPackageList(6, 1, undefined, undefined, undefined, undefined, this.userInfo ? this.userInfo.id : undefined)
        this.list = res.rows
        this.list.forEach(async (e) => {
          await this.getPackageDetails(e)
        })
        this.loading = false
      } catch (error) {
        
      }
    },
    getPackageDetails(val) {
      const that = this
      dataClient.getPackageDetails(10000, 1, val.id).then((res) => {

        // val.packageList = res.rows;

        this.$set(val, 'packageList', res.rows)
      })
    },
    goPage(item) {
      if (this.userInfo) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.id,
            title: item.title,
            schoolId: item.schoolId,
            isPurchase: item.isPurchase,
            num: item.price
          }
        })
      } else {
        this.$notify({
          title: '错误',
          message: '未登录，请去登录',
          type: 'error'
        })
      }
    },
    openKowledge(item) {
      this.kowledgePointsVisible = true
      this.$nextTick(() => {
        this.$refs.kowledgePoints.getData(item.mid)
      })
    },
    toView(val, item) {
      if (item.IsPurchase) {
        this.goTry(val, item)
      } else {
        this.openKowledge(val)
      }
    },
    kowledgePointsClose() {
      this.kowledgePointsVisible = false
    },
    goTry(val, item) {
      this.$router.push({
        path: '/VIP/sourcePage',
        query: {
          seeNum: val.seeNum,
          names: val.names,
          id: item.id,
          title: item.title,
          schoolId: item.schoolId,
          type: 2,
          IsPurchase: item.IsPurchase != 1
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.product {
  margin-top: 138px;
  margin-bottom: 15px;
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f5f5;

  //隐藏滚动条
  ::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none;
  }

  /deep/.el-carousel {
    .el-carousel__container {
      display: flex;
    }

    .el-carousel__item {
      width: 100px;
      flex-shrink: 0;
      padding: 0px 32px;
      height: 56px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 56px;
      // position:data ;
    }
  }

  .til {
    width: 1200px;
    margin: 30px auto 22px;
    height: 42px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    display: flex;
    justify-content: space-between;

    .title {
      position: relative;

      img {
        width: 10px;
        position: absolute;
        right: -8px;
        bottom: 0;
      }
    }

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .product_content {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;

    .product_content_imgBox {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      width: 586px;
      height: 300px;
      overflow: hidden;
      margin-right: 26px;

      img {
        width: 107%;
        height: 106%;
      }
    }

    .product_content_item {
      width: 280px;
      height: 300px;
      padding: 10px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      margin-right: 26px;
      margin-top: 24px;
      cursor: pointer;

      &:nth-child(4n-1) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: 0;
      }

      .product_content_item_img {
        position: relative;
        width: 260px;
        height: 146px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .title {
          position: absolute;
          z-index: 9;
          left: 21px;
          top: 28px;
          width: 203px;
          height: 44px;
          line-height: 22px;
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        .kindName {
          position: absolute;
          z-index: 9;
          left: 21px;
          top: 82px;
          height: 17px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #90939a;
        }
      }

      .product_content_item_title {
        width: 250px;
        height: 48px;
        margin: 24px auto;

        div {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin: 0 0 14px;
          height: 17px;

          img {
            display: inline-block;
            width: 11.5px;
            margin-bottom: 2px;
            vertical-align: middle;
            margin-right: 4px;
            margin-top: -5px;
          }
        }

        :last-child {
          margin: 0px;
        }
      }

      .product_content_item_tips {
        margin-bottom: 15px;

        .types {
          display: inline-block;
          // width: 24px;
          // height: 17px;
          padding: 1px 8px;
          margin-right: 10px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1c81f5;
        }

        span {
          // height: 17px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #777a82;
        }
      }

      .product_content_item_price {
        margin: 0 auto;
        // width: 244px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .count {
          height: 16px;
          line-height: 16px;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 84px;
          height: 30px;
          background: #ffffff;
          border-radius: 16px 16px 16px 16px;
          opacity: 1;
          border: 1px solid #1d81f5;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #1d81f5;

          &:hover {
            width: 84px;
            height: 30px;
            background: linear-gradient(180deg, #6cadf8 0%, #1c81f5 100%);
            box-shadow: 0px 3px 12px 1px rgba(28, 129, 245, 0.34);
            border-radius: 16px 16px 16px 16px;
            opacity: 1;
            color: #ffffff;
            border: 0;

          }
        }
      }
    }
  }
}

.kowledgePoints_wrap {
  padding-bottom: 20px;

  .determine_btn {
    width: 40%;
    height: 40px;
    margin: auto;
    margin-top: 20px;
    background-color: #ff5e51;
    color: #ffffff;
    font-weight: 500;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
}
</style>
