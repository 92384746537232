import { render, staticRenderFns } from "./tuijian.vue?vue&type=template&id=d0efa796&scoped=true&"
import script from "./tuijian.vue?vue&type=script&lang=js&"
export * from "./tuijian.vue?vue&type=script&lang=js&"
import style0 from "./tuijian.vue?vue&type=style&index=0&id=d0efa796&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0efa796",
  null
  
)

export default component.exports