<template>
  <div class="vip-box" v-if="list && list.length > 0">
    <!--标题 最近直播 -->
    <div class="til">
      <div>VIP专属班</div>
      <div @click="goVipList">MORE ></div>
    </div>

    <!-- 最近直播  内容 -->
    <div class="vip">
      <div v-for="(item, index) in list" :key="index" class="item" @click="banxingke(item)">
        <!-- <img class="item-bg" src="@/assets/img/homesixth/Group 4103.png" /> -->
        <img class="item-icon" src="@/assets/img/homeFourth/vipIcon.png" />
        <div class="item-title twoEllipsis">{{ item.tiitle }}</div>
        <div class="classfi">
          {{ item.kindName }} | <span>{{ item.num }} </span>门课程
        </div>
        <div class="item-tips">
          <span>#专属服务 · 极速答疑 · 督学跟踪</span>
          <!-- <span class="last-tip">#3大专属服务</span> -->
        </div>
        <div class="ter">
          <div v-if="item.teacherDtoVos && item.teacherDtoVos.length > 0" class="terimg">
            <el-image class="terImg" :src="item.teacherDtoVos[0].terImg" fit="cover"></el-image>
          </div>
          <div v-if="item.teacherDtoVos && item.teacherDtoVos.length > 0" class="tername">
            讲师: {{ item.teacherDtoVos[0].nickname }}
          </div>
        </div>

        <div class="item-price flex-between-c">
          <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>

          <div class="tips">
            <img src="@/assets/img/homesixth/Frame (1).png" alt="" /> 人气值{{
              item.clicks ? item.clicks : 0
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { selectPageListToIndex,selectPageListToIndexnew, selectAcademyKindzyList } from '@/api/home'

export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [], //公开课   ifHave: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    goVipList() {
      let routeUrl = this.$router.resolve({
        path: `/VIP/VIPClasssixth`,
      })
      window.open(routeUrl.href, '_blank')
    },
    getList() {
      selectPageListToIndexnew(4, 1, undefined, undefined, undefined, undefined, 3).then((res) => {
        this.list = res.rows
        for (let cur of this.list) {
          if (cur.jfType) {
            cur.jfType = cur.jfType.split(',')
          } else {
            cur.jfType = []
          }
        }
      })
    },
    // 知识套餐课详情
    banxingke(item) {
      /* this.$router.push({
        path: "VIP/VIPClassxq",
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      }); */
      let routeUrl = this.$router.resolve({
        path: 'VIP/VIPClassxq',
        query: {
          id: item.id,
          type: 1,
          entrantsPrice: item.entrantsPrice,
          jgPrice: item.jgPrice,
        },
      })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>
<style lang="less" scoped>
.vip-box {
  width: 1220px;
  margin: auto;
  margin-top: 32px;
  .til {
    width: 100%;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    > div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }
}
// 最近直播  内容
.vip {
  width: calc(1220px + 24px);
  margin-left: calc(50% - 610px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 36px;
  .vip-img {
    width: 598px;
    height: 263px;
    margin-right: 24px;
  }
  .item {
    margin-bottom: 24px;
    margin-right: 24px;
    width: 287px;
    height: 270px;
    // background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    position: relative;
    padding: 32px 16px 0px;
    cursor: pointer;
    background: url('~@/assets/img/homesixth/Group 4103.png');
    .item-bg {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 287px;
      height: 263px;
    }
    .item-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 80px;
      height: 26px;
    }
    .item-title {
      height: 48px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
    .classfi {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-top: 16px;

      span {
        color: #1061ff;
      }
    }
    .item-tips {
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;

      color: #b8681e;
      line-height: 14px;
      margin-top: 12px;
      .last-tip {
        margin-left: 12px;
      }
    }
    .ter {
      height: 36px;
      line-height: 36px;
      opacity: 1;
      display: flex;
      justify-content: start;

      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 28px;
      .terimg {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        overflow: hidden;

        .terImg {
          width: 36px;
        }
      }
      .tername {
        margin-left: 12px;
      }
    }
    .jfType-list {
      display: flex;
      margin-top: 16px;
      .jfType {
        height: 24px;
        line-height: 24px;
        background: #f7f8fc;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        padding: 0px 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #9098ab;
      }
      .jfType + .jfType {
        margin-left: 8px;
      }
    }
    .item-price {
      height: 50px;
      margin-top: 18px;
      border-top: 1px solid #eeeeee;
      line-height: 50px;
      .price {
        font-family: Microsoft YaHei-Regular;
        color: #ff5d51;
        font-size: 16px;
        line-height: 18px;
        font-weight: Bold;
        .price-icon {
          font-size: 14px;
          line-height: 14px;
          font-weight: 400;
        }
      }
      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        display: flex;
        justify-content: start;
        img {
          margin-right: 4px;
        }
      }
    }
  }
}
.home-three-title {
  width: 1220px;
  padding-bottom: 30px;
}
</style>
