<template>
  <div v-if="list && list.length > 0" class="jingpin-box">
    <!--标题 最近直播 -->
    <div class="til">
      <div>最近直播</div>
      <div @click="zhibokes">MORE ></div>
    </div>
    <div class="timeline">
      <div class="line"></div>
      <div class="timem">
        <div class="timemsg" v-for="(item, index) in list" :key="index">
          <div></div>
          <span> {{ item.startTime | format("MM-dd HH:mm") }}</span>
        </div>
      </div>
    </div>
    <!-- 最近直播  内容 -->
    <div class="zblist">
      <div v-for="(item, index) in list" :key="index">
        <div class="t_img">
          <img :src="item.img" alt="" />
          <div class="rq">{{ item.clicks ? item.clicks : 0 }}人气值</div>
          <div class="relogo"></div>
        </div>
        <div class="t_til">
          <div class="timg">
            <el-image class="terImg" :src="item.tImg" fit="cover"></el-image>
          </div>
          <div class="tname">{{ item.terName }}</div>
          <div class="ttil">{{ item.title }}</div>
          <div @click="godetail(item)" class="zbbtn" v-if="item.isLive == 3">
            观看回放
          </div>
          <div @click="godetail(item)" class="zbbtn" v-else>进入直播</div>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="ifHave" width="30%" center>
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div class="lookBg" v-if="showLookDetail">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName :
            "" }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/common.css";
import { Know, courseCheckPlay } from "@/api/know";
let know = new Know();
import Bus from "@/api/bus";
import Vue from "vue";
import { getLbClassCourseById } from "@/api/home";
import { selectCoursePages } from "@/api/home";
import { getInfo } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
export default {
  components: {},
  data() {
    return {
      userInfo: null,
      list: [], //公开课   ifHave: false,
      liveId: null,
      ifHave: false,
      liveshowName: "",
      live: null,
      lookDeatil: null,
      showLookDetail: false,
    };
  },
  created() {
    this.userInfo = getInfo();
    know.getLiveshowplaybackByTypesnew(5, 1).then((res) => {
      this.list = res.rows;
    });
  },
  methods: {
    // 查看更多直播课按钮
    zhibokes() {
      this.$router.push({ path: "/zhiboclasssixth" });
    },

    async godetail(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(true);
        return;
      }
      this.liveId = item.liveId;
      this.liveshowName = item.liveshowName;
      await getLbClassCourseById(item.liveId).then((res) => {
        this.ifHave = !res.data.IsPurchase;
        this.live = res.data.details;
      });
      if (this.ifHave) {
        return;
      }
      if (item.isLive == 0) {
        this.$message.error("课程还未到开播时间");
        return;
      }
      if (item.isLive == 2) {
        this.$message.error("课程直播结束");
        return;
      }

      let sub = {
        id: item.id,
        classId: this.classId,
        startTime: item.startTime,
        chapterName: item.title,
      };
      for (let key in sub) {
        if (!sub[key] && sub[key] !== 0) {
          delete sub[key];
        }
      }
      let data = Vue.prototype.export(sub);
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // });
      courseCheckPlay({
        courseType: "1",
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1,
      }).then((res) => {
        if (res.code == 0) {
          let routeUrl = this.$router.resolve({
            path: `/livebofang?${data}`,
            query: {
              id: item.id,
              startTime: item.startTime,
            },
          });
          if (
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent)
          ) {
            window.location.href = routeUrl.href;
          } else {
            window.open(routeUrl.href, "_blank");
          }
        } else {
          this.lookDeatil = res.msg;
          this.showLookDetail = true;
        }
      });

      /* this.$router.push({
        path: "/livebofang",
        query: {
          id: item.id,
        },
      }); */
    },
    closeLook() {
      this.showLookDetail = false;
    },

    // 立即购买
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(
            this.liveId,
            this.userInfo.id,
            this.live.title,
            2,
            this.live.title,
            this.live.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.jingpin-box {
  width: 1220px;
  margin: auto;
  margin-top: 32px;

  .til {
    width: 100%;
    height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    >div:nth-child(2) {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }

  .timeline {
    width: 100%;
    height: 40px;
    position: relative;
    margin-top: 28px;

    .line {
      width: 1220px;
      height: 2px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      top: 19px;
      z-index: 0;
    }

    .timem {
      display: flex;
      justify-content: start;

      >div {
        width: 140px;
        height: 40px;
        background: #f0f0f0;
        border-radius: 20px 20px 20px 20px;
        opacity: 1;
        line-height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        z-index: 9;
        margin-right: 100px;
        // text-align: center;
      }

      >div:nth-child(1) {
        margin-left: 70px;
      }

      >div:nth-child(5n) {
        margin-right: 70px;
      }

      .timemsg {
        display: flex;
        justify-content: flex-start;

        >div {
          width: 14px;
          height: 14px;
          background-size: cover;
          background: url("~@/assets/img/homesixth/Union.png");
          margin-top: 13px;
          margin-left: 20px;
          margin-right: 5px;
        }

        span {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }

  .zblist {
    width: 1220px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 40px;

    >div {
      width: 228px;
      height: 312px;
      position: relative;
      margin-right: 20px;

      .t_img {
        width: 228px;
        height: 128.25px;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        position: relative;

        img {
          width: 228px;
          height: 128.25px;
          object-fit: cover;
          border-radius: 8px 8px 0px 0px;
          background-size: cover;
        }

        .rq {
          // width: 87px;
          height: 22px;
          background: #000000;
          border-radius: 4px 4px 4px 4px;
          background-color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 8px;
          left: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          padding-left: 18px;
          padding-right: 4px;
          overflow: hidden;
        }

        .relogo {
          width: 22px;
          height: 22px;
          background: url("~@/assets/img/homesixth/Group 3261.png");
          opacity: 1;
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }

      .t_til {
        width: 228px;
        height: 160px;
        background: #ffffff;
        box-shadow: 0px 8px 16px 1px rgba(204, 204, 204, 0.2);
        border-radius: 0px 0px 8px 8px;
        opacity: 1;
        position: relative;

        .tname {
          height: 24px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          padding-top: 8px;
          margin-left: 56px;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .ttil {
          height: 44px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          padding-left: 12px;
          padding-right: 20px;
          margin-top: 20px;

          line-height: 22px;
          text-overflow: ellipsis;

          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .zbbtn {
          width: 120px;
          height: 36px;
          background: #1061ff;
          box-shadow: 0px 4px 8px 1px rgba(16, 89, 197, 0.3);
          border-radius: 18px 18px 18px 18px;
          opacity: 1;
          cursor: pointer;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          text-align: center;
          margin: 20px auto;
        }
      }

      .timg {
        width: 36px;
        height: 36px;
        background: #c4c4c4;
        opacity: 1;
        position: absolute;
        top: -18px;
        left: 12px;
        border-radius: 50%;
        overflow: hidden;

        .terImg {
          width: 36px;
        }
      }
    }

    >div:nth-child(5n) {
      margin-right: 0;
    }
  }
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
